import warehouseServices from '@/services/warehouse.service'
import dictionaryServices from '@/services/dictionary.service'

export default {
  incomingOrderItems: {
    name: 'warehouse_inc_order_items',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },

      {
        name: 'Product',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchProducts,
        templates: {
          singleLabel: `
            <div>
              <span v-if="option && option.id">{{ option.label }}</span>
            </div>
          `,
          option: `
            <div>
           
              <hr style="margin-top:0px;padding-top:0px;" />
              <span v-if="option.item['GS1 UPC']">
              
                <b>{{ option.label }}</b>
              </span>
              <span v-else>
                {{ option.label }}
              </span>
              <b-table-simple
                v-if="option.item['GS1 UPC']"
                small
                bordered
                responsive
                class="table table-striped"
                style="margin-top: 0.5em;font-size: 0.9rem;"
              >
                <b-tbody>
                  <tr>
                    <td>Product Type: {{ option.item['Product Type'] }}</td>
                    <td>Base: {{ option.item['Base/Finished Product'] }}</td>
                    <td>ABV: {{ option.item['ABV'] }}</td>
                    <td>UPC: {{ option.item['GS1 UPC'] }}</td>
                  </tr>
                  <tr>
                    <td>Bottle Size: {{ option.item['Bottle Size'] }}ML</td>
                    <td>Bottle Dimensions: {{ option.item['Bottle Height'] }}x{{ option.item['Bottle Width'] }}</td>
                    <td>Bottle Weight: {{ option.item['Bottle Weight'] }}</td>
                    <td>Cases per Layer: {{ option.item['Cases per Layer'] }}</td>
                  </tr>
                  <tr>
                    <td>Case Size:{{ option.item['Case Size'] }}</td>
                    <td>Case Dimensions: {{ option.item['Case Height'] }} x {{ option.item['Case Dimensions'] }}</td>
                    <td>Case Weight: {{ option.item['Case Weight'] }}</td>
                    <td>Layers per Pallet: {{ option.item['Layers per Pallet'] }}</td>
                  </tr>
                </b-tbody>
              </b-table-simple>
            </div>
          `
        }
      },

      {
        name: 'Finished Product',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchFinishedProductsBottling
      },
      {
        name: 'Bottle Size',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchBottleSizes
      },
      {
        name: 'Bottles',
        type: 'number',
        readonly: false,
        required: true,
        defaultValue: null
      },
      {
        name: 'Bottles WH',
        type: 'number',
        readonly: true
      },
      {
        name: 'Bottles Diff',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases WH',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases per Pallet',
        type: 'number',
        readonly: false
      },
      {
        name: 'Cases Diff',
        type: 'number',
        readonly: true
      },

      {
        name: 'Cost per Case',
        type: 'number',
        readonly: false,
        required: false,
        defaultValue: 0
      },
      {
        name: 'Organic Status',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchOrganicStatuses,
        defaultValue: {id: 1, label: 'ORGANIC'}
      },

      {
        name: 'Line Cost',
        type: 'number',
        readonly: true
      },
      {
        name: 'Item Status',
        type: 'string',
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchIncomingOrderItems
      //child table will send event to parent order and save method will be called there
      //saveRecord: warehouseServices.saveIncomingOrderItem
    },
    //actions: ["Create", "Delete"],
    actions: {
      Create: {
        validator: row => !(row['Invoice Number'] > 0)
      },
      Delete: {
        validator: row => !(row['Invoice Number'] > 0)
      }
    }
  }
}
