<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      name="ct_inventory_items_list"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button class="btn btn-primary btn-sm" @click="viewItemModal(props)">
            <font-awesome-icon icon="eye" />
          </button>

          <button
            :class="['btn btn-outline-secondary btn-sm']"
            @click="toggleUsage(props.row['HID'])"
          >
            <font-awesome-icon icon="tasks" />
          </button>

          <button
            :class="['btn btn-outline-secondary btn-sm']"
            @click="toggleLotDetails(props.row['Lot Number'])"
          >
            <font-awesome-icon icon="arrow-up" />
          </button>
        </div>
      </div>
      <!--      <span slot-scope="props" slot="child_row">
        <div style="padding: 1em">
          Mashings related to purchase #{{ props.row['Order Number'] }}
          <mashing-table
            :purchaseOrderId="props.row['Order Number']"
            :show-add-button="false"
          ></mashing-table>

          BarrelFilling related to purchase #{{ props.row['Order Number'] }}
          <barrel-filling-table
            :purchaseOrderId="props.row['Order Number']"
            :show-add-button="false"
            :title="`Barrel fillings related to purchase #${props.row['Order Number']}`"
          ></barrel-filling-table>
        </div>
      </span>

    -->
      <span slot-scope="props" slot="child_row">
        <div style="padding: 1em">
          Mashes related to Lot #{{ props.row['Lot Number'] }}
          <mashing-table
            :lotNumber="props.row['Lot Number']"
            :show-add-button="false"
          ></mashing-table>

          Barrels fillings related to Lot #{{ props.row['Lot Number'] }}
          <barrel-filling-table
            :lotNumber="props.row['Lot Number']"
            :show-add-button="false"
          ></barrel-filling-table>
        </div>
      </span>
    </table-custom>
    <inventory-item-modal ref="inventoryItemModal"></inventory-item-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import InventoryItemModal from './InventoryItemModal'
import MashingTable from '@/views/Production/Mashing/MashingTable.vue'
import BarrelFillingTable from '@/views/Production/BarrelFilling/BarrelFillingTable.vue'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@ckeditor/ckeditor5-build-inline'
Vue.use(CKEditor)

import TableCustom from '@/components/TableCustom'

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: ''
    },
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  name: 'InventoryListTable',

  components: {
    InventoryItemModal,
    TableCustom,
    MashingTable,
    BarrelFillingTable
  },
  data: function () {
    return {
      rawData: {},
      appliedFilters: [],
      editor: {
        mode: InlineEditor,
        data: '',
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'HID',
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            'HID',
            'Order Number',
            'Order Date',
            'Est Arrival Date',
            'Date Received',
            'Delivery Status',
            'Storage Location',
            'Item ID',
            'Item Name',
            'Ordered Amount',
            'Total Received',
            'Orig Lot Amount',
            'Amount Remaining',
            'Amount Remaining %',
            'Amount Adjusted Loss',
            'Lot Number',
            'Status',
            'Total Price',
            'Ordered By',
            'Actions'
          ],
          /*
          editableColumns: [
            "Lot Number",
            "Storage Location",
            "Date Received",
            "Amount Adjusted Loss"
          ],
*/
          dropdownColumns: [
            {
              name: 'Order Number',
              options: []
            },
            {
              name: 'Storage Location',
              options: []
            }
          ],
          perPage: 50,
          showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      }
    }
  },
  computed: {},
  mounted () {
    this.getData()
  },
  methods: {
    async getData (payload) {
      let self = this

      this.dataTable.isLoading = true

      this.dataTable.dataSet = []

      this.appliedFilters = payload

      let method = '',
        url = ''

      method = 'post'
      url = 'inventory/items/history'

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false

          self.$emit('loaded', response.length)

          self.dataTable.dataSet = response

          if (response.length === 0) return
        })
        .catch(error => {
          console.log(error)
          self.dataTable.isLoading = false
          self.$form.msgBoxOk('Error occured')
        })
    },
    toggleUsage (id) {
      this.$refs.dataTable.toggleChildRow(id)
    },
    toggleLotDetails (lotNumber) {
      //this.$refs.dataTable.toggleChildRow(lotNumber)
      this.$router.push({
        path: `inventory-details/${lotNumber}`
      })
    },
    onDescriptionBlur (row) {
      this.onInlineUpdate({
        id: row['Order Item ID'],
        column: 'Notes',
        value: row['Notes']
      })
    },
    onInlineUpdate (e) {
      let row = this.dataTable.dataSet.find(i => i['HID'] === e.id)

      /*
      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }
*/
      if (!e.value.label) row[e.column] = e.value
      else row[e.column] = e.value.label

      this.$api
        .put(`inventory/item/${row['ID']}/history`, row)
        .then(response => {
          this.$form.makeToastInfo(response.message)

          this.getData()
        })
        .catch(function (error) {
          this.$form.makeToastError(error.message)
          this.getData()
        })
    },
    onInlineDropdownChange (e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i['ID'] === e.row['ID']
      )
      let row = this.dataTable.dataSet[rowIndex]

      if (e.column === 'Storage Location') {
        row['storage_location_id'] = e.value.id
      }

      if (e.column === 'Order Number') {
        let order = this.dropdowns.activeOrders.find(
          i => i['Order Number'] === e.value.id
        )
        row['item_order_id'] = order['Item Order ID']
        row['order_id'] = order['Order Number']
        row['Order Date'] = order['Order Date']
        row['Ordered By'] = order['Ordered By']
        row['Total Price'] = order['Total Price']
        row['Ordered Amount'] = order['Ordered Amount']
        row['Delivery Status'] = order['Delivery Status']
        row['Est Arrival Date'] = order['Est Arrival Date']
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row)
    },
    addItemInline () {
      let newItem = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        order_id: '',
        'Order Number': '',
        'Order Date': '',
        'Ordered By': '',
        'Total Price': '',
        'Ordered Amount': '',
        'Delivery Status': '',
        'Est Arrival Date': '',
        'Date Received': moment
          .utc()

          .format('YYYY-MM-DD'),
        'Lot Number': '',
        'Storage Location': '',
        'Amount Received': '',
        'Amount Remaining': '',
        'Amount Adjusted Loss': '',
        Status: ''
      }

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem)

      // this.dataTable.dataSet.push(newItem);

      this.dataTable.isInserting = true
    },
    addItemModal () {
      this.$refs.receiveItemModal.show({ id: this.id })
    },
    onSaveModal (payload) {
      this.$refs.receiveItemModal.hide()
      this.$api
        .post(`inventory/item/${payload.id}/history`, payload)
        .then(response => {
          this.$form.makeToastInfo(response.message)

          this.getData()
        })
        .catch(function (error) {
          this.$form.makeToastError(error.message)
          this.getData()
        })
    },
    onCloseModal () {
      this.$refs.receiveItemModal.hide()
    },
    async saveNewRecordCallback (newRecord) {
      let self = this

      if (!newRecord['Order Number']) {
        self.$form.makeToastError('Please select an order')
        return
      }
      if (!newRecord['Date Received']) {
        self.$form.makeToastError('Please enter date received')
        return
      }
      if (!newRecord['Lot Number']) {
        self.$form.makeToastError('Please enter lot number')
        return
      }

      if (!newRecord['Amount Received']) {
        self.$form.makeToastError('Please enter amount received')
        return
      }

      if (!newRecord['Storage Location']) {
        self.$form.makeToastError('Please select storage location')
        return
      }

      let data = {}

      data['item_order_id'] = newRecord['item_order_id']
      data['order_id'] = newRecord['order_id']
      data['storage_location_id'] = newRecord['storage_location_id']
      data['lot_number'] = newRecord['Lot Number']
      data['date_received'] = newRecord['Date Received']
      data['amount_received'] = newRecord['Amount Received']
      data['amount_adjusted_loss'] = 0
      data['status'] = 'Full'

      self.dataTable.isInserting = false

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.updateActiveOrders()

        this.$api
          .post(`inventory/item/${this.id}/history`, data)
          .then(response => {
            self.$form.makeToastInfo(response.message)
            self.getData()
            self.updateActiveOrders()
          })
          .catch(function (error) {
            self.$form.makeToastError(error.message)

            self.getData()
            self.updateActiveOrders()
          })
      } else {
        return true
      }
    },

    async revertNewRecordCallback () {
      this.dataTable.isInserting = false

      return true
    },
    viewItemModal (e) {
      this.$refs.inventoryItemModal.show({
        id: e.row['Item ID'],
        name: e.row['Item Name']
      })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
