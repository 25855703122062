<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Finished product details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.product_name.id"
                  :value="data.product_name"
                  :label="controls.product_name.label"
                  :readonly="controls.product_name.readonly"
                  :mode="mode"
                  :required="controls.product_name.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.classification.id"
                  :value="data.classification"
                  :label="controls.classification.label"
                  :readonly="controls.classification.readonly"
                  :options="controls.classification.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.mas.id"
                  :value="data.mas"
                  :label="controls.mas.label"
                  :readonly="controls.mas.readonly"
                  :mode="mode"
                  :required="controls.mas.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.volume.id"
                  :value="data.volume"
                  :label="controls.volume.label"
                  :readonly="controls.volume.readonly"
                  :mode="mode"
                  :required="controls.volume.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.batch_number.id"
                  :value="data.batch_number"
                  :label="controls.batch_number.label"
                  :readonly="controls.batch_number.readonly"
                  :mode="mode"
                  :required="controls.batch_number.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.hts.id"
                  :value="data.hts"
                  :label="controls.hts.label"
                  :readonly="controls.hts.readonly"
                  :mode="mode"
                  :required="controls.hts.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.ttb.id"
                  :value="data.ttb"
                  :label="controls.ttb.label"
                  :readonly="controls.ttb.readonly"
                  :mode="mode"
                  :required="controls.ttb.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.scheduleB.id"
                  :value="data.scheduleB"
                  :label="controls.scheduleB.label"
                  :readonly="controls.scheduleB.readonly"
                  :mode="mode"
                  :required="controls.scheduleB.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2" md="6" sm="6">
                <inline-switch
                  :id="controls.active.id"
                  :value="data.active"
                  :readonly="false"
                  :label="controls.active.label"
                  @changed="onActiveChange"
                />
              </b-col>
            </b-row>
            <!--
            <b-row>
              <b-col lg="2" md="6" sm="6">
                <inline-switch
                  :id="controls.active.id"
                  :value="data.active"
                  :readonly="false"
                  :label="controls.active.label"
                  @changed="onActiveChange"
                />
              </b-col>
              <b-col lg="1" md="6" sm="6">
                <inline-switch
                  :id="controls.is_product_group.id"
                  :value="data.is_product_group"
                  :readonly="false"
                  :label="controls.is_product_group.label"
                  @changed="onIsProductGroupChange"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.group_products.id"
                  :value="data.group_products"
                  :label="controls.group_products.label"
                  :options="controls.group_products.options"
                  :allow-empty="false"
                  :multiple="true"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="data.is_product_group ? true : false"
                />
              </b-col>

              <b-col lg="2" md="6" sm="6">
                
                <inline-select
                  :id="controls.case_size.id"
                  :value="data.case_size"
                  :label="controls.case_size.label"
                  :readonly="controls.case_size.readonly"
                  :options="controls.case_size.options"
                  :taggable="true"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="data.is_product_group ? true : false"
                />
              
              </b-col>
              <b-col lg="1" md="6" sm="6">
                
                <inline-select
                  :id="controls.bottle_size.id"
                  :value="data.bottle_size"
                  :label="controls.bottle_size.label"
                  :readonly="controls.bottle_size.readonly"
                  :options="controls.bottle_size.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="data.is_product_group ? true : false"
                />
            
              </b-col>
            </b-row>
          -->
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created_by.id"
                  :value="data.created_by"
                  :label="controls.created_by.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified_by.id"
                  :value="data.modified_by"
                  :label="controls.modified_by.label"
                  :readonly="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
          </form>
          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="save('tabular').then(response => (response ? $router.push({name: 'Finished products'}) : false))"
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Finished product submission',
                      params: {action: 'view', id: response}
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Finished product submission',
                params: {action: 'edit', id: id}
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import FormSubmissionActions from '@/components/FormSubmissionActions'
import InlineSwitch from '@/components/InlineSwitch'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'FinishedProductSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FormSubmissionActions,
    InlineSwitch
  },
  data: function () {
    return {
      dataset: {
        name: 'finished-products'
      },
      baseApiUrl: 'finished-product',
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          storages: {
            title: 'Storages'
          }
        },
        product_name: {
          id: 'input:product_name',
          label: 'Product Name',
          changed: false,
          readonly: false,
          required: true
        },
        mas: {
          id: 'input:mas',
          label: 'Mas',
          changed: false,
          readonly: false,
          required: true
        },
        volume: {
          id: 'input:volume',
          label: 'Volume',
          changed: false,
          readonly: false,
          required: true
        },
        batch_number: {
          id: 'input:batch_number',
          label: 'Batch number',
          changed: false,
          readonly: false,
          required: true
        },
        classification: {
          id: 'select:classification',
          label: 'Classification',
          options: [],
          changed: false,
          readonly: false
        },
        hts: {
          id: 'input:hts',
          label: 'HTS',
          changed: false,
          readonly: false,
          required: true
        },
        ttb: {
          id: 'input:ttb',
          label: 'TTB',
          changed: false,
          readonly: false,
          required: true
        },
        scheduleB: {
          id: 'input:scheduleB',
          label: 'Schedule B',
          changed: false,
          readonly: false,
          required: true
        },
        active: {
          id: 'switch:active',
          label: 'Active',
          value: false,
          changed: false,
          readonly: false
        },
        is_product_group: {
          id: 'switch:is_product_group',
          label: 'Group of Products',
          value: false,
          changed: false,
          readonly: false
        },
        group_products: {
          id: 'select:group_products',
          label: 'Selected products',
          changed: false,
          readonly: false,
          options: []
        },
        bottle_size: {
          id: 'select:bottle_size',
          label: 'Bottle size',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        case_size: {
          id: 'select:case_size',
          label: 'Case size',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        created: {
          id: 'input:created',
          label: 'Created',
          changed: false,
          readonly: true
        },

        modified: {
          id: 'input:modified',
          label: 'Modified',
          changed: false,
          readonly: true
        },
        created_by: {
          id: 'input:created_by',
          label: 'Created By',
          changed: false,
          readonly: true
        },
        modified_by: {
          id: 'input:modified_by',
          label: 'Modified By',
          changed: false,
          readonly: true
        }
      },
      data: {
        id: '',
        product_name: '',
        classification: {
          id: '',
          label: ''
        },
        mas: '',
        volume: '',
        batch_number: '',
        hts: '',
        ttb: '',
        scheduleB: '',
        active: true,
        is_product_group: false,
        group_products: [],
        bottle_size: {
          id: '',
          name: ''
        },
        case_size: {id: ''},
        created_by: '',
        modified_by: '',
        created: '',
        modified: ''
      },
      serverFilter: undefined
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),

  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    async initialize () {
      if (this.action === 'create') this.mode = this.$constants.FORM_MODE.CREATE

      if (this.action === 'edit') this.mode = this.$constants.FORM_MODE.EDIT

      if (this.action === 'view') this.mode = this.$constants.FORM_MODE.VIEW

      if (this.mode !== this.$constants.FORM_MODE.CREATE) this.data.id = this.id

      this.loadDictionaries()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.loadData(this.id)
      }
    },

    loadDictionaries () {
      let self = this

      const classifications = () => {
        this.$api.get('dictionaries/product-classifications').then(response => {
          self.controls.classification.options = response.map(u => ({
            id: u.id,
            label: u.name
          }))
        })
      }

      const groupProducts = () => {
        this.$api.get('dictionaries/finished-products').then(response => {
          console.log('dictionaries/finished-products:', response)
          self.controls.group_products.options = response.map(u => ({
            id: u.id,
            label: u.name
          }))
        })
      }

      const case_sizes = async () => {
        let response = await this.$api.get('dictionaries/case-sizes')

        self.controls.case_size.options = response.map(u => ({
          id: u.bottles,
          label: u.name,
          bottles: u.bottles
        }))
      }

      const bottle_sizes = async () => {
        let response = await this.$api.get('dictionaries/bottle-sizes')

        self.controls.bottle_size.options = response.map(u => ({
          id: u.id,
          label: u.name,
          size: u.size
        }))
      }

      Promise.all([classifications(), groupProducts(), case_sizes(), bottle_sizes()])
    },
    edit () {},

    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let method = ''

      let url = this.baseApiUrl

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = this.baseApiUrl
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = 'put'
        url = `${this.baseApiUrl}/${this.data.id}`
      }

      this.isLoading = true
      console.log('this.url.data', url, this.data)
      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    onChangeGroupProducts (id, val) {
      this.data.group_products = val
      this.$form.updateField(this.baseApiUrl, this, 'group_products', val, this.mode)
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode)
    },
    onActiveChange (id, value) {
      this.data.active = value
      this.$form.updateField(this.baseApiUrl, this, 'active', value, this.mode)
    },
    onIsProductGroupChange (id, value) {
      this.data.is_product_group = value
      this.$form.updateField(this.baseApiUrl, this, 'is_product_group', value, this.mode)
    },
    updateSelect (id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode)
    },
    async loadData (id) {
      let self = this

      this.isLoading = true

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`)

        console.log(response)
        this.isLoading = false

        if (self.$_.isEmpty(response)) return

        this.data.id = response['ID']

        this.data.product_name = response['Product Name']
        this.data.mas = response['Mas']
        this.data.volume = response['Volume']
        this.data.batch_number = response['Batch Number']

        this.data.classification = {
          id: response['classification_id'],
          label: response['Classification']
        }

        this.data.hts = response['HTS']
        this.data.ttb = response['TTB']
        this.data.scheduleB = response['ScheduleB']

        //this.data.active = response["Active"];
        this.data.is_product_group = response['Product Group'] === 'Yes' ? true : false

        this.data.created = response['Created']
        this.data.modified = response['Modified']

        this.data.created_by = response['Created By']
        this.data.modified_by = response['Modified By']

        this.data.group_products = JSON.parse(response['Group Products'])

        this.data.bottle_size = JSON.parse(response['Bottle Size'])
        this.data.case_size = JSON.parse(response['Case Size'])

        console.log(this.data)
      } catch (error) {
        console.log(error)

        this.$form.makeToastError(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped></style>
