<template>
  <div class="animated fadeIn">
    <b-card>
      <b-alert show variant="danger" v-if="reportingAlertText">{{ reportingAlertText }} </b-alert>
      <b-tabs content-class="mt-3" justified>
        <b-tab :title="controls.tabs.overview.title" active>
          <div class="card-body">
            <form>
              <h5>
                Bottling details
                <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
              </h5>

              <hr />
              <b-row>
                <b-col lg="6" md="6" sm="6">
                  <inline-select
                    :id="controls.alcohol_finishing.id"
                    :value="data.alcohol_finishing"
                    :label="controls.alcohol_finishing.label"
                    :readonly="controls.alcohol_finishing.readonly"
                    :options="controls.alcohol_finishing.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-switch
                    :id="controls.bulk_bottling.id"
                    :value="data.bulk_bottling"
                    :readonly="controls.bulk_bottling.readonly"
                    :label="controls.bulk_bottling.label"
                    @changed="onBulkBottlingChange"
                  />
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.batch_number.id"
                    :value="data.batch_number"
                    :label="controls.batch_number.label"
                    :readonly="controls.batch_number.readonly"
                    :mode="mode"
                    :required="controls.batch_number.required"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.total_amount.id"
                    :value="data.total_amount"
                    :label="controls.total_amount.label"
                    :readonly="controls.total_amount.readonly"
                    :mode="mode"
                    :required="controls.total_amount.required"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-select-product
                    :id="controls.product.id"
                    :value="data.product"
                    :label="controls.product.label"
                    :readonly="controls.product.readonly"
                    :options="controls.product.options"
                    :mode="mode"
                    @changed="updateSelect"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.alcohol_percentage.id"
                    :value="data.alcohol_percentage"
                    :label="controls.alcohol_percentage.label"
                    :readonly="controls.alcohol_percentage.readonly"
                    :mode="mode"
                    :required="controls.alcohol_percentage.required"
                    @changed="updateField"
                  />
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <inline-select
                    v-if="!data.bulk_bottling"
                    :id="controls.bottle_size.id"
                    :value="data.bottle_size"
                    :label="controls.bottle_size.label"
                    :readonly="controls.bottle_size.readonly"
                    :options="controls.bottle_size.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />

                  <inline-input
                    v-if="data.bulk_bottling"
                    :id="controls.bulk_size.id"
                    :value="data.bulk_size"
                    :label="controls.bulk_size.label"
                    :readonly="controls.bulk_size.readonly"
                    :mode="mode"
                    :required="controls.bulk_size.required"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_bottles.id"
                    :value="data.amount_bottles"
                    :label="data.bulk_bottling ? 'Containers' : 'Bottles'"
                    :readonly="controls.amount_bottles.readonly"
                    :mode="mode"
                    :required="controls.amount_bottles.required"
                    @changed="updateField"
                /></b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    v-if="!data.bulk_bottling"
                    :id="controls.bottles_remaining.id"
                    :label="controls.bottles_remaining.label"
                    :value="data.bottles_remaining"
                    :readonly="controls.bottles_remaining.readonly"
                    :mode="mode"
                    :required="controls.bottles_remaining.required"
                    @changed="updateField"
                /></b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <inline-select
                    v-if="!data.bulk_bottling"
                    :id="controls.case_size.id"
                    :value="data.case_size"
                    :label="controls.case_size.label"
                    :readonly="controls.case_size.readonly"
                    :options="controls.case_size.options"
                    :taggable="true"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_cases.id"
                    :value="data.amount_cases"
                    :label="controls.amount_cases.label"
                    :readonly="controls.amount_cases.readonly"
                    :mode="mode"
                    :required="controls.amount_cases.required"
                    @changed="updateField"
                  />
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_bottled_pg.id"
                    :value="data.amount_bottled_pg"
                    :label="controls.amount_bottled_pg.label"
                    :readonly="controls.amount_bottled_pg.readonly"
                    :mode="mode"
                    :required="controls.amount_bottled_pg.required"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_loss_liters.id"
                    :value="data.amount_loss_liters"
                    :label="controls.amount_loss_liters.label"
                    :readonly="controls.amount_loss_liters.readonly"
                    :mode="mode"
                    :required="controls.amount_loss_liters.required"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_loss_pg.id"
                    :value="data.amount_loss_pg"
                    :label="controls.amount_loss_pg.label"
                    :readonly="controls.amount_loss_pg.readonly"
                    :mode="mode"
                    :required="controls.amount_loss_pg.required"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.amount_bottled_wg.id"
                    :value="data.amount_bottled_wg"
                    :label="controls.amount_bottled_wg.label"
                    :readonly="controls.amount_bottled_wg.readonly"
                    :mode="mode"
                    :required="controls.amount_bottled_wg.required"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-select
                    :id="controls.warehouse.id"
                    :value="data.warehouse"
                    :label="controls.warehouse.label"
                    :readonly="controls.warehouse.readonly"
                    :options="controls.warehouse.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-date-picker
                    :id="controls.bottle_date.id"
                    :value-single="data.bottle_date"
                    :label="controls.bottle_date.label"
                    :readonly="controls.bottle_date.readonly"
                    :mode="mode"
                    :required="controls.bottle_date.required"
                    @changed="updateDateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <inline-select
                    :id="controls.organic_status.id"
                    :value="data.organic_status"
                    :label="controls.organic_status.label"
                    :readonly="controls.organic_status.readonly"
                    :options="controls.organic_status.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.created_by.id"
                    :value="data.created_by"
                    :label="controls.created_by.label"
                    :readonly="true"
                  />
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.modified_by.id"
                    :value="data.modified_by"
                    :label="controls.modified_by.label"
                    :readonly="true"
                  />
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.created.id"
                    :value="data.created"
                    :label="controls.created.label"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <inline-input
                    :id="controls.modified.id"
                    :value="data.modified"
                    :label="controls.modified.label"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <hr />
            </form>

            <hr />
            <form-submission-actions
              :mode="mode"
              :loading="{
                save: saveInProgress,
                saveAndView: saveAndViewInProgress
              }"
              :buttons-visibility="{
                previous: $customTable.getPrevKey(dataset, id),
                next: $customTable.getNextKey(dataset, id)
              }"
              @previous-item="
                $router.push({
                  name: $route.name,
                  params: {
                    action: $route.params.action,
                    id: $customTable.getPrevKey(dataset, id)
                  }
                })
              "
              @next-item="
                $router.push({
                  name: $route.name,
                  params: {
                    action: $route.params.action,
                    id: $customTable.getNextKey(dataset, id)
                  }
                })
              "
              @save="save('tabular').then(response => (response ? $router.push({name: 'Bottling'}) : false))"
              @save-and-view="
                save('view').then(response =>
                  response
                    ? $router.push({
                        name: 'Bottling submission',
                        params: {action: 'view', id: response}
                      })
                    : false
                )
              "
              @edit="
                $router.push({
                  name: 'Bottling submission',
                  params: {action: 'edit', id: id}
                })
              "
              @back="$router.push($store.getters['router/previousRoute'])"
            />
          </div>
        </b-tab>
        <b-tab
          :title="controls.tabs.alc_fin.title"
          v-if="mode !== $constants.FORM_MODE.CREATE && data.alcohol_finishing.id"
        >
          <alcohol-finishing-table
            ref="alcohol-finishing-table"
            :finished-product-number="data.alcohol_finishing.id"
            @loaded="onAlcFinItemsLoad"
          ></alcohol-finishing-table>
        </b-tab>
        <b-tab :title="controls.tabs.picklists.title" v-if="mode !== $constants.FORM_MODE.CREATE">
          <PickListsTable :bottling-number="id" @loaded="onPickListsLoad"></PickListsTable>
        </b-tab>
        <b-tab :title="controls.tabs.invoices.title" v-if="mode !== $constants.FORM_MODE.CREATE">
          <WarehouseInvoicesTable :bottling-number="id" @loaded="onWarehouseInvoicesLoad"></WarehouseInvoicesTable>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import AlcoholFinishingTable from '@/views/Production/AlcoholFinishing/AlcoholFinishingTable.vue'
import PickListsTable from '@/views/Warehouse/PickLists/PickListsTable.vue'
import WarehouseInvoicesTable from '@/views/Warehouse/Invoices/InvoicesTable.vue'
import InlineSelectProduct from '@/components/InlineSelectProduct'

import {mapState, mapActions} from 'vuex'
import moment from 'moment'
export default {
  name: 'BottlingSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    AlcoholFinishingTable,
    PickListsTable,
    WarehouseInvoicesTable,
    InlineSelectProduct
  },
  data: function () {
    return {
      dataset: {
        name: 'bottling'
      },
      baseUrl: 'bottling',
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      reportingAlertText: undefined,
      controls: {
        tabs: {
          overview: {
            title: 'Overview'
          },
          alc_fin: {
            title: 'Alcohol finishings'
          },
          picklists: {
            title: 'Picklists'
          },
          invoices: {
            title: 'Invoices'
          }
        },
        alcohol_finishing: {
          id: 'select:alcohol_finishing',
          label: 'Alcohol finishing',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        bulk_bottling: {
          id: 'switch:bulk_bottling',
          label: 'Bulk bottling',
          value: false,
          changed: false,
          readonly: false
        },

        batch_number: {
          id: 'input:batch_number',
          label: 'Batch / Barrel Number',
          changed: false,
          readonly: false,
          required: true
        },
        total_amount: {
          id: 'input:total_amount',
          label: 'Amount Used (liters)',
          changed: false,
          readonly: false,
          required: true
        },
        alcohol_percentage: {
          id: 'input:alcohol_percentage',
          label: 'Alcohol %',
          changed: false,
          readonly: false,
          required: true
        },
        bulk_size: {
          id: 'input:bulk_size',
          label: 'Bulk size (liters)',
          changed: false,
          readonly: false,
          required: true
        },
        product: {
          id: 'select:product',
          label: 'Product',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        bottle_size: {
          id: 'select:bottle_size',
          label: 'Bottle size',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        amount_bottles: {
          id: 'input:amount_bottles',
          label: 'Bottles',
          changed: false,
          readonly: false,
          required: true
        },
        bottles_remaining: {
          id: 'input:bottles_remaining',
          label: 'Bottles Remaining',
          changed: false,
          readonly: false,
          required: true
        },
        case_size: {
          id: 'select:case_size',
          label: 'Case size',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        amount_cases: {
          id: 'input:amount_cases',
          label: 'Cases',
          changed: false,
          readonly: true,
          required: true
        },
        amount_bottled_pg: {
          id: 'input:amount_bottled_pg',
          label: 'PG Bottled',
          changed: false,
          readonly: true,
          required: true
        },
        amount_bottled_wg: {
          id: 'input:amount_bottled_wg',
          label: 'WG Bottled',
          changed: false,
          readonly: true,
          required: true
        },
        amount_loss_liters: {
          id: 'input:amount_loss_liters',
          label: 'Loss in Liters',
          changed: false,
          readonly: true,
          required: false
        },
        amount_loss_pg: {
          id: 'input:amount_loss_pg',
          label: 'Loss in PG',
          changed: false,
          readonly: true,
          required: false
        },
        warehouse: {
          id: 'select:warehouse',
          label: 'Warehouse Location',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        bottle_date: {
          id: 'datepicker:bottle_date',
          label: 'Date Bottled',
          changed: false,
          readonly: false,
          required: true
        },
        organic_status: {
          id: 'select:organic_status',
          label: 'Organic Status',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        created: {
          id: 'input:created',
          label: 'Created',
          changed: false,
          readonly: true
        },
        modified: {
          id: 'input:modified',
          label: 'Modified',
          changed: false,
          readonly: true
        },
        created_by: {
          id: 'input:created_by',
          label: 'Created By',
          changed: false,
          readonly: true
        },
        modified_by: {
          id: 'input:modified_by',
          label: 'Modified By',
          changed: false,
          readonly: true
        }
      },
      data: {
        id: '',
        alcohol_finishing: '',
        bulk_bottling: false,
        finished_product_id: 0,
        batch_number: '',
        total_amount: 0,
        alcohol_percentage: '',
        bulk_size: 0,
        bottle_size: {
          id: '',
          name: ''
        },
        product: {
          id: '',
          name: ''
        },
        case_size: {id: ''},
        amount_bottles: 0,
        bottles_remaining: 0,
        amount_cases: 0,
        amount_bottled_pg: 0,
        amount_bottled_wg: 0,
        amount_loss_liters: 0,
        amount_loss_pg: 0,
        warehouse: {id: '', name: ''},
        bottle_date: '',
        organic_status: {id: '', name: ''}

        /*
        finished_product: { id: "", name: "" },
        bulk_size: "",
        bottles_remaining: "",
        return: "",
        bottling_number_related: "",
        created: ""
        */
      },

      datasetKeys: []
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    initialize: async function () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      this.data.id = this.id

      this.loadDictionaries()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.loadData(this.id)

        this.controls.alcohol_finishing.readonly = true
        this.controls.total_amount.readonly = true
        this.controls.bulk_bottling.readonly = true
        this.controls.warehouse.readonly = true
      }
    },

    async loadDictionaries () {
      let self = this

      const alcohol_finishing = async () => {
        let response = await this.$api.post('alcohol-finishing/bottling-finishings')

        self.controls.alcohol_finishing.options = response.map(u => ({
          id: u.finished_product_number,
          label: u['Item Name'],
          amount_remaining: u.remaining_amount,
          alcohol_percentage: u.wanted_product_percentage,
          finished_product_id: u.finished_product_id,
          current_batch_number: u.current_batch_number,
          barrel_number: u.barrel_number
        }))

        self.alcohol_finishing = self.controls.alcohol_finishing.options
      }

      const organic_status = async () => {
        let response = await this.$api.get('dictionaries/organic-statuses')

        self.controls.organic_status.options = response.map(u => ({
          id: u.id,
          label: u.name
        }))
      }

      const case_sizes = async () => {
        let response = await this.$api.get('dictionaries/case-sizes')

        self.controls.case_size.options = response.map(u => ({
          id: u.id,
          label: u.name,
          bottles: u.bottles
        }))
      }

      const warehouses = async () => {
        let response = await this.$api.get('dictionaries/warehouses')

        self.controls.warehouse.options = response.map(u => ({
          id: u.id,
          label: u.name
        }))
      }

      const bottle_sizes = async () => {
        let response = await this.$api.get('dictionaries/bottle-sizes')

        self.controls.bottle_size.options = response.map(u => ({
          id: u.id,
          label: u.name,
          size: u.size
        }))
      }

      const products = async () => {
        let response = await this.$api.post('products')

        self.controls.product.options = response.map(i => ({
          ...i,
          id: i['ID'],
          label: i['Product Name Full'],
          item: i
        }))

        console.log('products', self.controls.product.options)
      }

      await Promise.all([alcohol_finishing(), organic_status(), warehouses(), bottle_sizes(), case_sizes(), products()])

      this.setDefaults()
    },
    setDefaults () {
      let self = this
      if (self.mode === this.$constants.FORM_MODE.CREATE) {
        self.data.warehouse = self.controls.warehouse.options.find(i => i.id === 2)

        self.data.organic_status = self.controls.organic_status.options.find(i => i.id === 1)

        self.data.bottle_date = self.$nowClient.format('YYYY-MM-DD')

        self.data.case_size = self.controls.case_size.options.find(i => i.bottles == 6)
      }
    },
    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let url = this.baseUrl,
        method = 'put'

      if (this.mode === this.$constants.FORM_MODE.EDIT || this.mode === this.$constants.FORM_MODE.VIEW) {
        url = `${this.baseUrl}/${this.data.id}`
      }

      this.isLoading = true

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (field, value) {
      let self = this
      let params = {}
      self.data[field] = value
      params['id'] = self.id
      params[field] = value

      this.updateCalculations()

      if (this.mode === this.$constants.FORM_MODE.VIEW) this.save('view').then(() => this.loadData(this.id))
    },
    updateSelect (id, value) {
      console.log('bottleSubmission.updateSelect', id, value)
      if (id === 'bottle_size') {
        this.data.bottle_size = value
        this.updateBottlesAmount()
      }

      if (id === 'product') this.data.product = value

      if (id === 'case_size') {
        //if new value then bottles = id
        if (!value.bottles) value.bottles = +value.id
        this.data.case_size = value
      }
      if (id === 'alcohol_finishing') {
        this.data.alcohol_finishing = value
        this.data.total_amount = value.amount_remaining
        this.data.alcohol_percentage = value.alcohol_percentage
        this.data.finished_product_id = value.finished_product_id

        this.data.batch_number = value.current_batch_number > 0 ? +value.current_batch_number + 1 : value.barrel_number
      }

      this.updateCalculations()

      if (this.mode === this.$constants.FORM_MODE.VIEW) this.save('view').then(() => this.loadData(this.id))
    },
    async updateBulkCalculations () {
      this.data.amount_bottled_pg =
        (((this.data.amount_bottles * this.data.bulk_size) / 3.7854118) * this.data.alcohol_percentage) / 100 / 0.5
      this.data.amount_bottled_wg = (this.data.amount_bottles * this.data.bulk_size) / 3.7854118
      this.data.amount_loss_liters = this.data.total_amount - this.data.amount_bottles * this.data.bulk_size

      this.data.amount_loss_pg =
        (((this.data.total_amount - this.data.amount_bottles * this.data.bulk_size) / 3.7854118) *
          this.data.alcohol_percentage) /
        100 /
        0.5

      this.data.amount_cases = 1

      this.data.amount_bottled_pg = this.$helpers.round(this.data.amount_bottled_pg, 4)
      this.data.amount_loss_liters = this.$helpers.round(this.data.amount_loss_liters, 4)
      this.data.amount_loss_pg = this.$helpers.round(this.data.amount_loss_pg, 4)

      if (+this.data.amount_loss_liters > 2) await this.$form.makeToastWarning(`Loss amount greater than 2L!`)
      else if (+this.data.amount_loss_liters < -2) await this.$form.makeToastWarning(`Loss amount less than -2L!`)
    },
    updateBottlesAmount () {
      let selectedBottleSize = this.controls.bottle_size.options.find(i => i.id == this.data.bottle_size.id)
      let bottleSizeValue = selectedBottleSize ? selectedBottleSize.size / 1000 : 0
      this.data.amount_bottles = bottleSizeValue ? Math.floor(this.data.total_amount / bottleSizeValue) : 0
    },
    async updateStandardCalculations () {
      //calculate bottles

      let selectedBottleSize = this.controls.bottle_size.options.find(i => i.id == this.data.bottle_size.id)
      let bottleSizeValue = selectedBottleSize ? selectedBottleSize.size / 1000 : 0

      //calculate cases
      let caseBottles = this.data.case_size.bottles

      this.data.amount_cases = Math.floor(this.data.amount_bottles / caseBottles)

      //
      this.data.amount_bottled_pg =
        (((this.data.amount_bottles * bottleSizeValue) / 3.7854118) * this.data.alcohol_percentage) / 100 / 0.5
      this.data.amount_bottled_wg = (this.data.amount_bottles * bottleSizeValue) / 3.7854118

      this.data.amount_loss_liters = this.data.total_amount - this.data.amount_bottles * bottleSizeValue

      this.data.amount_loss_pg = ((this.data.amount_loss_liters / 3.7854118) * this.data.alcohol_percentage) / 100 / 0.5

      this.data.amount_cases = this.data.case_size ? this.data.amount_bottles / this.data.case_size.bottles : 1

      this.data.amount_bottled_pg = this.$helpers.round(this.data.amount_bottled_pg, 4)
      this.data.amount_loss_liters = this.$helpers.round(this.data.amount_loss_liters, 4)
      this.data.amount_loss_pg = this.$helpers.round(this.data.amount_loss_pg, 4)
      this.data.amount_cases = this.$helpers.round(this.data.amount_cases, 4)

      if (this.data.amount_loss_liters > 2 && bottleSizeValue > 0)
        await this.$form.makeToastWarning(`Loss amount greater than 2L!`)
      else if (this.data.amount_loss_liters < -2 && bottleSizeValue > 0)
        await this.$form.makeToastWarning(`Loss amount less than -2L!`)
    },
    updateCalculations () {
      if (this.data.bulk_bottling) this.updateBulkCalculations()
      else this.updateStandardCalculations()
    },

    async loadData (id) {
      let self = this

      this.isLoading = true
      return this.$api
        .get(`${this.baseUrl}/${id}`)
        .then(response => {
          this.isLoading = false

          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.finished_product = {
            id: response['Finished Product ID'],
            label: response['Finished Product']
          }
          self.data.batch_number = response['Batch Number']
          self.data.alcohol_finishing = {
            id: response['Finished Product Number'],
            label: response['AF Name']
          }

          self.data.product = {
            id: response['Product ID'],
            label: response['Product']
          }

          self.data.total_amount = response['Total Amount']
          self.data.alcohol_percentage = response['Alcohol %']
          self.data.bottle_size = {
            id: response['Bottle Size ID'],
            label: response['Bottle Size (ml)']
          }
          self.data.amount_bottles = response['Amount Bottles']
          self.data.bottles_remaining = response['Bottles Remaining']

          let caseSize = self.controls.case_size.options.find(i => i.bottles == response['Case Size'])
          if (!caseSize) {
            caseSize = {
              id: self.$helpers.uuidv4(),
              label: response['Case Size'],
              bottles: response['Case Size']
            }
            self.controls.case_size.options.push(caseSize)
          }

          self.data.case_size = caseSize
          self.data.amount_cases = response['Amount Cases']
          self.data.amount_bottled_pg = response['PG']
          self.data.amount_loss_liters = response['Amount Loss']
          self.data.amount_loss_pg = response['Amount Loss PG']
          self.data.amount_bottled_wg = response['WG']
          self.data.warehouse = {
            id: response['Warehouse ID'],
            label: response['Warehouse']
          }
          self.data.bottle_date = response['Bottle Date']
          self.data.organic_status = {
            id: response['Organic Status ID'],
            label: response['Organic Status']
          }

          self.data.bulk_size = response['Bulk Size']
          self.data.bulk_bottling = response['Bulk Size'] > 0 ? true : false

          self.data.created = response['Created']
          self.data.modified = response['Modified']
          self.data.created_by = response['Created By']
          self.data.modified_by = response['Modified By']

          //reporting alert after 5th of month (Date Range: previous month!)
          let processYM = +moment(self.data.bottle_date).format('YYYYMM'),
            currentYM = +this.$nowClient.format('YYYYMM'),
            currentDay = +this.$nowClient.format('DD')

          this.reportingAlertText =
            currentYM > processYM && currentDay > 5 ? `Current bottling already been reported!` : undefined
        })
        .catch(error => {
          this.isLoading = false

          console.log(error)
        })
    },
    onBulkBottlingChange (id, value) {
      this.data.bulk_bottling = value
    },
    onAlcFinItemsLoad (count) {
      if (count > 0) this.controls.tabs.alc_fin.title = `Alcohol finishings (${count})`
    },
    onPickListsLoad (count) {
      if (count > 0) this.controls.tabs.picklists.title = `Picklists (${count})`
    },
    onWarehouseInvoicesLoad (count) {
      if (count > 0) this.controls.tabs.invoices.title = `Invoices (${count})`
    }
  },
  watch: {
    'data.bulk_bottling' (value) {
      if (value) {
        this.controls.amount_cases.readonly = true

        this.data.amount_cases = 1

        this.data.bottle_size = {id: 99}
      } else {
        this.controls.amount_cases.readonly = false
      }
    }
  }
}
</script>

<style scoped>
::v-deep .multiselect__option {
  padding-left: 0.5 rem !important;
  padding-bottom: 0 !important;
}
</style>
