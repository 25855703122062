var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('div', [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_vm.mode === _vm.$constants.FORM_MODE.CREATE ? _c('h5', [_vm._v(" New Project ")]) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('h5', [_vm._v(" Project overview ")]) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.project_name.id,
      "value": _vm.data.project_name,
      "label": _vm.controls.project_name.label,
      "readonly": _vm.controls.project_name.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v("Start Date")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.data.start_date
    }
  })])]), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v("Finish Date")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.data.finish_date
    }
  })])]), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account.id,
      "width": 20,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "loading": _vm.controls.account.loading,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": false,
      "async": true,
      "required": false
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [!_vm.id ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('label', {
    staticClass: "col-form-label",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_c('strong', [_vm._v("Description")])]), _c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.editor.id,
      "editor": _vm.editor,
      "disabled": _vm.controls.editor.readonly,
      "config": _vm.controls.editor.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1) : _vm._e(), _vm.id ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('label', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-1",
      modifiers: {
        "collapse-1": true
      }
    }],
    staticClass: "col-form-label",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_c('strong', [_vm._v("Description")])]), _c('b-collapse', {
    attrs: {
      "id": "collapse-1"
    }
  }, [_c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.editor.id,
      "editor": _vm.editor,
      "disabled": _vm.controls.editor.readonly,
      "config": _vm.controls.editor.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)])], 1)], 1), _vm.id ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticStyle: {
      "min-height": "22em"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', [_vm._v("Participants")]), _c('hr'), _c('div', [_c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.data.isMembersLoading
    }
  }), _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "start"
    }
  }, _vm._l(_vm.data.members, function (member, itemObjKey) {
    return _c('b-col', {
      key: itemObjKey
    }, [_c('b-card', {
      staticClass: "border-0",
      staticStyle: {
        "width": "8em",
        "height": "10em",
        "text-align": "center"
      }
    }, [member.is_group === '0' ? _c('div', [_c('img', {
      staticClass: "img-avatar img-participant",
      attrs: {
        "src": 'img/avatars/' + (member.imagename ? member.imagename : 'default.png')
      }
    })]) : _vm._e(), member.is_group === '1' ? _c('div', [_c('img', {
      staticClass: "img-avatar img-participant",
      attrs: {
        "src": 'img/avatars/' + (member.imagename ? member.imagename : 'default_group.png')
      }
    })]) : _vm._e(), _c('div', [_vm._v(_vm._s(member.name))]), _c('div', [_c('b-progress', {
      attrs: {
        "max": 100,
        "height": "1rem",
        "striped": true,
        "variant": "primary"
      }
    }, [_c('b-progress-bar', {
      attrs: {
        "value": parseFloat(member.progress),
        "label": "".concat(parseFloat(member.progress).toFixed(2), "%")
      }
    })], 1)], 1)])], 1);
  }), 1)], 1)], 1)])])], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticStyle: {
      "min-height": "22em"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', [_vm._v("Summary")]), _c('hr'), _c('b-row', [_c('b-col', [_vm.data.tasks_total ? _c('h6', [_vm._v(" " + _vm._s(_vm.data.tasks_completed) + " / " + _vm._s(_vm.data.tasks_total) + " OPENED TASKS ")]) : _vm._e(), _c('hr'), _c('b-card', {
    staticClass: "border-0 d-flex align-items-center"
  }, [_c('Progress', {
    attrs: {
      "transition-duration": 500,
      "radius": 50,
      "stroke-width": 15,
      "value": _vm.data.tasks_percent,
      "stroke-color": "#AAFF00"
    }
  })], 1)], 1), _c('b-col', [_vm.data.days_left && _vm.data.days_left >= 0 ? _c('h6', [_vm._v(" " + _vm._s(_vm.data.days_left) + " / " + _vm._s(_vm.data.days_total) + " DAYS LEFT ")]) : _vm._e(), _vm.data.days_left && _vm.data.days_left < 0 ? _c('h6', [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(Math.abs(_vm.data.days_left)) + " / " + _vm._s(_vm.data.days_total) + " DAYS OVERDUE ")])], 1) : _vm._e(), _c('hr'), _c('b-card', {
    staticClass: "border-0 d-flex align-items-center"
  }, [_c('Progress', {
    attrs: {
      "transition-duration": 500,
      "radius": 50,
      "stroke-color": _vm.data.days_left < 0 ? 'red' : '#AAFF00',
      "stroke-width": 15,
      "value": _vm.data.days_percent
    }
  })], 1)], 1)], 1)], 1)])], 1)], 1) : _vm._e(), _vm.action !== 'preview' && _vm.id ? _c('b-row', [_c('b-col', [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', [_vm._v("Project tasks")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-button-group', [_c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button",
      "title": "Include tasks"
    },
    on: {
      "click": function click($event) {
        return _vm.showModalTaskList();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1), _c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button",
      "disabled": _vm.projectTasks.selected.length === 0,
      "title": "Exclude tasks"
    },
    on: {
      "click": function click($event) {
        return _vm.excludeTasks();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "minus"
    }
  })], 1)])], 1)], 1), _c('hr'), _c('tasks-table', {
    ref: "project-tasks",
    attrs: {
      "project-id": _vm.id,
      "is-nested-table": true,
      "filter-by-column": true,
      "selectable-rows": true,
      "rows-per-page": 10
    },
    on: {
      "row-select": _vm.onExistingTaskSelected,
      "loaded": _vm.onTasksTableLoad,
      "bulk-complete": _vm.onBulkTasksCompleted
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": "Gantt",
      "lazy": ""
    }
  }, [_c('gantt', {
    ref: "gantt",
    attrs: {
      "project-id": this.id
    },
    on: {
      "loaded": _vm.onGanttLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.dataHistory.title
    }
  }, [_c('data-history-table', {
    ref: "dataHistory",
    attrs: {
      "module": "projects",
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onDataHistoryLoad
    }
  })], 1) : _vm._e()], 1), _c('hr'), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Projects'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Project submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Project submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1)], 1), _c('b-modal', {
    ref: "modal-tasklist",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "xl",
      "title": "Select tasks"
    }
  }, [_c('tasks-table', {
    attrs: {
      "filter": "available",
      "project-id": _vm.id,
      "rows-per-page": 5,
      "selectable-rows": true,
      "is-insert-allowed": false,
      "filter-by-column": true
    },
    on: {
      "row-select": _vm.onRecordSelected
    }
  }), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.modalTaskList.selected.length === 0
    },
    on: {
      "click": function click($event) {
        return _vm.includeTasks();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Add ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }