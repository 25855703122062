export const dragResize = {
  bind (el, binding, vnode) {
    const component = vnode.componentInstance || vnode.context
    let startX, startWidth
    let rafId = null
    let currentWidth = null
    let th
    let isRightEdge = true
    //console.log('Binding:', el, binding.value)

    setTimeout(() => {
      th = el.closest('th') || el.parentElement

      if (th) {
        th.addEventListener('mousemove', e => {
          const rect = th.getBoundingClientRect()
          const distanceRight = Math.abs(e.clientX - rect.right)
          const distanceLeft = Math.abs(e.clientX - rect.left)

          if (distanceRight <= 50 || distanceLeft <= 50) {
            el.classList.add('visible')
            isRightEdge = distanceRight < distanceLeft
            el.style.right = isRightEdge ? '-5px' : 'auto'
            el.style.left = isRightEdge ? 'auto' : '-5px'
          } else {
            el.classList.remove('visible')
          }
        })

        th.addEventListener('mouseleave', () => el.classList.remove('visible'))

        el.addEventListener('mousedown', function (e) {
          if (!el.classList.contains('visible')) return
          if (e.button !== 0) return

          e.preventDefault()
          e.stopPropagation()

          component.isDragging = true
          th.querySelector('.header-content').setAttribute('data-resizing', 'true')
          startX = e.clientX
          startWidth = isRightEdge ? th.offsetWidth : th.previousElementSibling?.offsetWidth
          currentWidth = startWidth

          document.body.style.cursor = 'col-resize !important'
          th.style.cursor = 'col-resize !important'
          document.body.style.userSelect = 'none'

          document.addEventListener('mousemove', onMouseMove)
          document.addEventListener('mouseup', onMouseUp)
        })
      }
    }, 0)

    function onMouseMove (e) {
      e.preventDefault()
      if (rafId) return

      document.body.style.cursor = 'col-resize !important'
      th.style.cursor = 'col-resize !important'

      rafId = requestAnimationFrame(() => {
        const dx = e.clientX - startX
        const newWidth = isRightEdge ? startWidth + dx : startWidth - dx
        if (currentWidth !== newWidth) {
          component.updateColumnWidth(isRightEdge ? binding.value : binding.value - 1, `${newWidth}px`)
          currentWidth = newWidth
        }
        rafId = null
      })
    }

    function onMouseUp () {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      document.body.style.cursor = ''
      th.style.cursor = ''
      document.body.style.userSelect = ''

      if (rafId) {
        cancelAnimationFrame(rafId)
        rafId = null
      }

      component.isDragging = false
      th.querySelector('.header-content').removeAttribute('data-resizing')
      component.saveColumnWidths()
    }
  },

  unbind (el) {
    document.body.style.cursor = ''
    document.body.style.userSelect = ''
  }
}
