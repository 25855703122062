var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [this.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add item"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Add item ")], 1)], 1)], 1)], 1) : _vm._e(), _c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "ct_inventory_order_items",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "inline-update": _vm.onInlineUpdate,
      "inline-dropdown-change": _vm.onInlineDropdownChange,
      "column-update": _vm.onInlineColumnUpdate
    },
    scopedSlots: _vm._u([{
      key: "Order ID",
      fn: function fn(props) {
        return _c('div', {}, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row['Order ID']) + " ")])], 1);
      }
    }, {
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [!_vm.orderId ? _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "View item"
          },
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1) : _vm._e(), props.row['ID'] && props.row['Delivery Status'] === 'Not Delivered' ? _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "Receive item"
          },
          on: {
            "click": function click($event) {
              return _vm.receiveItemModal(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1) : _vm._e(), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "title": "Delete item"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1), _c('button', {
          class: [props.row['Notes'] ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleDescription(props.row['ID']);
            }
          }
        }, [!props.row['Notes'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file"
          }
        }) : _vm._e(), props.row['Notes'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file-alt"
          }
        }) : _vm._e()], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('ckeditor', {
          ref: "ckeditor",
          attrs: {
            "editor": _vm.editor.mode,
            "config": _vm.editor.config
          },
          on: {
            "blur": function blur($event) {
              return _vm.onDescriptionBlur(props.row);
            }
          },
          model: {
            value: props.row['Notes'],
            callback: function callback($$v) {
              _vm.$set(props.row, 'Notes', $$v);
            },
            expression: "props.row['Notes']"
          }
        })], 1);
      }
    }])
  }), _c('receive-item-modal', {
    ref: "receiveItemModal",
    on: {
      "save": _vm.onReceiveItemModalSave,
      "close": _vm.onReceiveItemModalClose
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }