<template>
  <inline-select
    :id="id"
    :value="localValue"
    :label="label"
    :readonly="readonly"
    :options="options"
    :allow-empty="false"
    :multiple="false"
    :mode="mode"
    :custom-option-template="true"
    @changed="onChanged"
    :required="true"
  >
    <!-- Custom option template -->
    <div slot="singleLabel" slot-scope="props">
      <span v-if="props.slotScope.option && props.slotScope.option.id">
        {{ props.slotScope.option.label }}
      </span>
    </div>

    <div slot="option" slot-scope="props">
      <hr style="margin-top:0px;padding-top:0px;" />
      <span v-if="props.slotScope.option.item['GS1 UPC']">
        <b>{{ props.slotScope.option.label }}</b>
      </span>
      <span v-else>
        {{ props.slotScope.option.label }}
      </span>

      <b-table-simple
        v-if="props.slotScope.option.item['GS1 UPC']"
        small
        bordered
        responsive
        class="table table-striped"
        style="margin-top: 0.5em;font-size: 0.9rem;"
      >
        <b-tbody>
          <tr>
            <td> Product Type: {{ props.slotScope.option.item['Product Type'] }}</td>
            <td> Base: {{ props.slotScope.option.item['Base/Finished Product'] }} </td>
            <td> ABV: {{ props.slotScope.option.item['ABV'] }}</td>
            <td> UPC: {{ props.slotScope.option.item['GS1 UPC'] }} </td>
          </tr>
          <tr>
            <td> Bottle Size: {{ props.slotScope.option.item['Bottle Size'] }}ML </td>
            <td>
              Bottle Dimensions: {{ props.slotScope.option.item['Bottle Height'] }}x{{
                props.slotScope.option.item['Bottle Width']
              }}
            </td>
            <td> Bottle Weight: {{ props.slotScope.option.item['Bottle Weight'] }}</td>
            <td>Cases per Layer: {{ props.slotScope.option.item['Cases per Layer'] }}</td>
          </tr>
          <tr>
            <td> Case Size:{{ props.slotScope.option.item['Case Size'] }} </td>
            <td>
              Case Dimensions: {{ props.slotScope.option.item['Case Height'] }} x
              {{ props.slotScope.option.item['Case Dimensions'] }}
            </td>
            <td> Case Weight: {{ props.slotScope.option.item['Case Weight'] }} </td>
            <td>Layers per Pallet: {{ props.slotScope.option.item['Layers per Pallet'] }}</td>
          </tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </inline-select>
</template>

<script>
export default {
  name: 'InlineSelectProduct',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Object, Array],
      default: null
    },
    label: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    mode: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  methods: {
    onChanged (id, val) {
      this.localValue = val
      this.$emit('input', val)
      this.$emit('changed', id, val)
    }
  },
  watch: {
    value (val) {
      this.localValue = val
    }
  }
}
</script>
