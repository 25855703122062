<template>
  <div>
    <b-row>
      <b-col lg="6">
        <h5>Salary trend</h5>

        <g-chart-custom
          ref="trendChart"
          title=""
          subtitle=""
          type="LineChart"
          :loading="trendChart.isLoading"
          :data="trendChart.dataSet"
          :options="trendChart.options"
        />
      </b-col>
      <b-col>
        <h5>
          Changes history
          <clip-loader class="ml-3 mt-2" :size="14" color="#36D7B7" :loading="historyTable.isLoading" />
        </h5>
        <v-client-table
          v-if="historyTable.dataSet.length > 0"
          ref="historyTable"
          :data="historyTable.dataSet"
          :options="historyTable.options"
          :columns="historyTable.columns"
        />
      </b-col>
    </b-row>
    <hr />

    <form>
      <h5>Salary details</h5>
      <hr />
      <b-row>
        <b-col lg="6">
          <b-row>
            <b-col lg="6">
              <inline-date-picker
                :id="controls.salarystart.id"
                :value-single="salary.salarystart"
                :label="controls.salarystart.label"
                :readonly="!$permitted(controls.salarystart.id).write"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="6">
              <inline-select
                :id="controls.empstatus.id"
                :value="salary.empstatus"
                :label="controls.empstatus.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :options="controls.empstatus.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-select
                ref="category"
                :id="controls.category.id"
                :value="salary.category"
                :label="controls.category.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :options="controls.category.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSalaryCategory"
                :required="true"
              />
            </b-col>
            <b-col lg="6">
              <!-- :readonly="!$permitted(controls.ratehour.id).write" -->
              <inline-input
                v-if="salary.category.label === 'Hourly'"
                ref="ratehour"
                :id="controls.ratehour.id"
                :value="salary.ratehour"
                :label="controls.ratehour.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :mode="mode"
                :required="true"
                @changed="updateSalaryRate"
              />

              <!-- :readonly="!$permitted(controls.rateyear.id).write" -->
              <inline-input
                v-if="salary.category.label === 'Exempt'"
                ref="rateyear"
                :id="controls.rateyear.id"
                :value="salary.rateyear"
                :label="controls.rateyear.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :mode="mode"
                :required="true"
                @changed="updateSalaryRate"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-select
                :id="controls.insurance.id"
                :value="salary.insurance"
                :label="controls.insurance.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :options="controls.insurance.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="6">
              <inline-input
                :id="controls.stipend.id"
                :value="salary.stipend"
                :label="controls.stipend.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <inline-input
                :id="controls.notes.id"
                :value="salary.notes"
                :label="controls.notes.label"
                :readonly="mode === $constants.FORM_MODE.READONLY"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="6">
          <h5>
            <a :href="'#/user-management/bonus-payments'">Bonus payments</a>
          </h5>

          <TableWrapper
            :ref="models.userManagement.bonusPayments.name"
            :model="models.userManagement.bonusPayments"
            :parent-id="id"
            :columns="['Bonus Date', 'Amount', 'Description']"
            :read-only="true"
            :perPage="5"
            :filter-by-column="false"
          >
          </TableWrapper>
        </b-col>
      </b-row>

      <hr />
      <b-row>
        <b-col>
          <h5>Vacation details (VL)</h5>
          <hr />
          <div>Rollover hours: {{ vacationData.rollover }}</div>
          <div>Accrued hours: {{ vacationData.accrued }}</div>
          <div>Used paid hours: {{ vacationData.usedPaid }}</div>
          <div>Not used hours: {{ vacationData.notUsed }}</div>
          <div>Used unpaid hours: {{ vacationData.usedUnpaid }}</div>
          <div>Available hours: {{ vacationData.available }}</div>
        </b-col>

        <b-col>
          <h5>Sick details (PSL)</h5>
          <hr />

          <div>Rollover hours: {{ sickData.rollover }}</div>
          <div>Accrued hours: {{ sickData.accrued }}</div>
          <div>Used paid hours: {{ sickData.usedPaid }}</div>
          <div>Not used hours: {{ sickData.notUsed }}</div>
          <div>Used unpaid hours: {{ sickData.usedUnpaid }}</div>
          <div>Available hours: {{ sickData.available }}</div>
        </b-col>
        <b-col>
          <h5>Personal leave details (PLA)</h5>
          <hr />
          <div>Rollover hours: {{ PLAData.rollover }}</div>
          <div>Accrued hours: {{ PLAData.accrued }}</div>
          <div>Used paid hours: {{ PLAData.usedPaid }}</div>
          <div>Not used hours: {{ PLAData.notUsed }}</div>
          <div>Used unpaid hours: {{ PLAData.usedUnpaid }}</div>
          <div>Available hours: {{ PLAData.available }}</div>
        </b-col>
      </b-row>
    </form>
    <hr />

    <form-submission-actions
      :mode="mode"
      :loading="{save: saveInProgress, saveAndView: saveAndViewInProgress}"
      :buttons-visibility="{
        previous: $customTable.getPrevKey($route.meta.module, id),
        next: $customTable.getNextKey($route.meta.module, id)
      }"
      @previous-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getPrevKey($route.meta.module, id)
          }
        })
      "
      @next-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getNextKey($route.meta.module, id)
          }
        })
      "
      @save="save('tabular').then(response => (response ? $router.push({name: 'Users'}) : false))"
      @save-and-view="
        save('view').then(response =>
          response
            ? $router.push({
                name: 'User submission',
                params: {action: 'view', id: response}
              })
            : false
        )
      "
      @edit="
        $router.push({
          name: 'User submission',
          params: {action: 'edit', id: id}
        })
      "
      @back="$router.push($store.getters['router/previousRoute'])"
    />
  </div>
</template>

<script>
import moment from 'moment'

import FormSubmissionActions from '@/components/FormSubmissionActions'

import GChartCustom from '@/components/GChartCustom'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineDatePicker from '@/components/InlineDatePicker'

import models from '@/models'
import TableWrapper from '@/components/DataView/TableWrapper'

export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    },

    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: 'UserSalaryForm',
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    'g-chart-custom': GChartCustom,
    FormSubmissionActions,
    TableWrapper
  },
  data: function () {
    return {
      models: models,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        category: {
          id: 'select:category',
          label: 'Salary category',
          options: [
            {
              id: '1',
              label: 'Exempt'
            },
            {
              id: '2',
              label: 'Hourly'
            }
          ],
          changed: false,
          readonly: false
        },
        empstatus: {
          id: 'select:empstatus',
          label: 'Employment status',
          options: [
            {
              id: '1',
              label: 'Full time'
            },
            {
              id: '2',
              label: 'Part time'
            }
          ],
          changed: false,
          readonly: false
        },

        salarystart: {
          id: 'input:salarystart',
          label: 'Salary start',
          changed: false,
          readonly: false,
          value: {},
          options: {
            opens: 'center',
            singleDatePicker: true,
            autoApply: true,
            linkedCalendars: false
          }
        },
        ratehour: {
          id: 'input:ratehour',
          label: 'Hourly rate',
          changed: false,
          readonly: false,
          mode: this.$constants.FORMCONTROLMODE.VIEW,
          visible: true
        },
        rateyear: {
          id: 'input:rateyear',
          label: 'Yearly amount',
          changed: false,
          readonly: false,
          mode: this.$constants.FORMCONTROLMODE.VIEW,
          visible: true
        },
        insurance: {
          id: 'select:insurance',
          label: 'Salary insurance',
          options: [
            {
              id: '1',
              label: 'Yes'
            },
            {
              id: '2',
              label: 'No'
            }
          ],
          changed: false,
          readonly: false
        },
        stipend: {
          id: 'input:stipend',
          label: 'Salary stipend',
          changed: false,
          readonly: false
        },
        notes: {
          id: 'input:notes',
          label: 'Notes',
          changed: false,
          readonly: false
        }
      },
      salary: {
        salary_id: '',
        category: {
          id: '',
          label: ''
        },
        empstatus: {
          id: '',
          label: ''
        },
        insurance: {
          id: '',
          label: ''
        },
        salarystart: '',
        ratehour: '',
        rateyear: '',
        stipend: '',
        accrued: '',
        notes: ''
      },
      vacationData: {
        rollover: '',
        accrued: '',
        usedPaid: '',
        notUsed: '',
        usedUnpaid: '',
        available: ''
      },
      sickData: {
        rollover: '',
        accrued: '',
        usedPaid: '',
        notUsed: '',
        usedUnpaid: '',
        available: ''
      },
      PLAData: {
        rollover: '',
        accrued: '',
        usedPaid: '',
        notUsed: '',
        usedUnpaid: '',
        available: ''
      },
      historyTable: {
        isLoading: false,
        options: {
          filterable: false,
          filterByColumn: false,
          perPage: 5,
          perPageValues: [],
          saveState: true,
          storage: 'session'
        },
        columns: ['Date', 'Modified by', 'Employee', 'Field', 'Old value', 'New value'],
        dataSet: []
      },
      trendChart: {
        isLoading: false,
        dataSet: [],
        options: {
          height: 300,
          //curveType: 'function',
          chartArea: {
            width: '80%',
            height: '80%'
          },
          title: '',
          subtitle: '',
          series: {
            0: {
              targetAxisIndex: 0
            },

            1: {
              targetAxisIndex: 1
            }
          },
          vAxis: {
            minValue: 0
          },
          hAxis: {
            //  format: 'MMM, YY',
          }
        }
      }
    }
  },
  created () {
    let self = this

    this.controls.salarystart.update = value => {
      self.salary.salarystart = moment(value.startDate).format('YYYY-MM-DD')

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        self.controls.salarystart.readonly = true

        self.updateField('salarystart', self.salary.salarystart)
      }
    }

    this.initialize()
  },
  methods: {
    initialize: async function () {
      //this.mode = this.FORM_MODE.VIEW

      let self = this

      this.updateControlsState()

      //this.user.id = this.id

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.$api.get(`timetracker/users/${this.id}/sick/statistics-new`).then(v => {
          let data = v.find(i => i.yy == moment().format('YYYY'))
          if (data)
            self.sickData = {
              rollover: data.prev_rollover_h ?? 0.0,
              accrued: data.accrued_h ?? 0.0,
              usedPaid: data.used_h ?? 0.0,
              notUsed: data.not_used_h ?? 0.0,
              usedUnpaid: data.sick_unpaid ?? 0.0,
              available: data.available_h ?? 0.0
            }
        })

        this.$api.get(`timetracker/users/${this.id}/vacation/statistics-new`).then(v => {
          console.log('timetracker/users/${this.id}/vacation/statistics-new', v, moment().format('YYYY'))

          let data = v.find(i => i.yy == moment().format('YYYY'))

          if (data)
            self.vacationData = {
              rollover: data.prev_rollover_h ?? 0.0,
              accrued: data.accrued_h ?? 0.0,
              usedPaid: data.used_h ?? 0.0,
              notUsed: data.not_used_h ?? 0.0,
              usedUnpaid: data.unpaid_h ?? 0.0,
              available: data.available_h ?? 0.0
            }

          console.log('self.vacationData', self.vacationData)
        })

        this.$api.get(`timetracker/users/${this.id}/pla/statistics-new`).then(v => {
          console.log('timetracker/users/${this.id}/pla/statistics-new', v, moment().format('YYYY'))

          let data = v.find(i => i.yy == moment().format('YYYY'))

          if (data)
            self.PLAData = {
              rollover: data.prev_rollover_h ?? 0.0,
              accrued: data.accrued_h ?? 0.0,
              usedPaid: data.used_h ?? 0.0,
              notUsed: data.not_used_h ?? 0.0,
              usedUnpaid: data.unpaid_h ?? 0.0,
              available: data.available_h ?? 0.0
            }

          console.log('self.plaData', self.plaData)
        })

        await this.fetchSalaryData(this.id)

        this.updateRatesVisibility(this.salary.category)

        this.drawHistoryTable()

        this.drawTrendChart()
      }
    },
    /*
        edit: function () {

            //this.mode = this.FORM_MODE.EDIT

            this.$router.push({
                name: 'User submission',
                params: {
                    action: 'edit',
                    id: this.id,
                }
            })

        },
        */
    save (_mode) {
      let self = this

      this.salary.user_id = this.id

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      return this.$api
        .put('salary/update', this.salary)
        .then(response => {
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          //if new record created save id
          if (response.id) {
            self.salary.salary_id = response.id
          }

          self.drawHistoryTable()
          self.drawTrendChart()

          self.$form.makeToastInfo(response.message)

          return response.id
        })
        .catch(error => {
          self.$form.makeToastError(error ? error.message : 'undefined')

          return Promise.resolve(false)
        })
    },
    fetchSalaryData: function (user_id) {
      let self = this

      return this.$api
        .get(`salary/${user_id}`)
        .then(response => {
          if (Object.keys(response).length === 0) return

          let category = self.controls.category.options.find(item => item.id == response.category_id)

          response.category = {
            id: response.category_id,
            label: category ? category.label : ''
          }

          let empstatus = self.controls.empstatus.options.find(item => item.id == response.empstatus_id)

          response.empstatus = {
            id: response.empstatus_id,
            label: empstatus ? empstatus.label : ''
          }

          let insurance = self.controls.insurance.options.find(item => item.id == response.insurance_id)

          response.insurance = {
            id: response.insurance_id,
            label: insurance ? insurance.label : ''
          }

          self.controls.salarystart.value = {
            startDate: new Date(response.salarystart),
            endDate: new Date(response.salarystart)
          }

          self.salary = response
        })
        .catch(error => {
          console.log(error)

          this.$emit('message', {
            title: 'Error',
            text: error ? error.message : 'undefined'
          })
        })
    },
    drawHistoryTable: function () {
      if (!this.salary.salary_id) return

      let self = this

      self.historyTable.isLoading = true

      this.$api
        .get(`salary/${this.salary.salary_id}/history`)
        .then(response => {
          self.historyTable.isLoading = false

          let data = []

          response.forEach(row => {
            if (row.Field == 'salarycategory') {
              row['Field'] = 'Category'
            }

            if (row.Field == 'salaryempstatus') {
              row['Field'] = 'Employment status'
            }

            if (row.Field == 'salaryinsurance') {
              row['Field'] = 'Insurance'
            }

            if (row.Field == 'salaryratehour') {
              row['Field'] = 'Hourly rate'
            }

            if (row.Field == 'salaryrateyear') {
              row['Field'] = 'Yearly amount'
            }

            data.push(row)
          })
          self.historyTable.dataSet = response
        })
        .catch(error => {
          console.log(error)

          this.$emit('message', {
            title: 'Error',
            text: error ? error.message : 'undefined'
          })
        })
    },
    drawTrendChart: function () {
      if (!this.salary.salary_id) return

      let self = this

      self.trendChart.isLoading = true

      this.$api
        .get(`salary/${this.salary.salary_id}/trend`)
        .then(response => {
          self.trendChart.isLoading = false

          if (response.length == 0) return

          let headers = [['Date', 'Hourly rate', 'Yearly amount']]
          let data = [
            ...response.map(item => {
              return [
                new Date(moment(item.date).format('MM/DD/YY')),
                parseFloat(item.hourly || 0),
                parseFloat(item.yearly || 0)
              ]
            })
          ]
          self.trendChart.dataSet = headers.concat(data)
        })
        .catch(error => {
          console.log(error)

          this.$emit('message', {
            title: 'Error',
            text: error ? error.message : 'undefined'
          })
        })
    },
    updateRatesVisibility (value) {
      if (value.label == 'Exempt') {
        this.controls.rateyear.visible = true
        this.controls.ratehour.visible = false
      }
      if (value.label == 'Hourly') {
        this.controls.ratehour.visible = true
        this.controls.rateyear.visible = false
      }
    },
    updateSalaryCategory (id, value) {
      //   if (this.mode === this.FORM_MODE.VIEW) {
      this.salary[id] = value

      this.controls[id].readonly = true

      if (value.label == 'Hourly') {
        this.salary.ratehour = ''
        this.salary.rateyear = ''

        // this.$refs.ratehour.setValue('')
        // this.$refs.rateyear.setValue('')

        this.updateRatesVisibility(value)

        this.$nextTick(() => this.$refs.ratehour.setMode(this.$constants.FORMCONTROLMODE.EDIT))
      }

      if (value.label == 'Exempt') {
        this.salary.ratehour = ''
        this.salary.rateyear = ''

        //this.$refs.ratehour.setValue('')
        //this.$refs.rateyear.setValue('')

        this.updateRatesVisibility(value)

        this.$nextTick(() => this.$refs.rateyear.setMode(this.$constants.FORMCONTROLMODE.EDIT))
      }

      //  }
    },

    updateSalaryRate (field, value) {
      this.salary[field] = value

      let self = this
      let data = {}
      data['salary_id'] = self.salary.salary_id
      data['user_id'] = self.id
      //data[field] = value

      data['category'] = this.salary.category
      data['ratehour'] = this.salary.ratehour
      data['rateyear'] = this.salary.rateyear

      if (this.mode == this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put('salary/update', data)
          .then(async function (response) {
            //if new record created save id
            if (response.id) {
              self.salary.salary_id = response.id
            }

            self.$emit('toast', {
              title: field,
              text: response.message,
              type: 'warning'
            })

            self.controls[field].changed = false

            self.drawHistoryTable()
            self.drawTrendChart()
          })
          .catch(error => {
            console.log(error)

            self.$emit('toast', {
              title: field,
              text: error ? error.message : 'undefined',
              type: 'danger'
            })
          })
      }
    },
    updateSelect (id, value) {
      this.salary[id] = value

      this.updateField(id, value)
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString)
    },
    updateField (field, value) {
      let self = this
      let data = {}
      data['salary_id'] = self.salary.salary_id
      data['user_id'] = self.id
      data[field] = value

      this.$api
        .put('salary/update', data)
        .then(response => {
          //if new record created save id
          if (response.id) {
            self.salary.salary_id = response.id
          }

          self.$emit('toast', {
            title: field,
            text: response.message,
            type: 'warning'
          })

          self.controls[field].changed = false

          self.drawHistoryTable()
          self.drawTrendChart()
        })
        .catch(error => {
          console.log(error)

          self.$emit('toast', {
            title: field,
            text: error ? error.message : 'undefined',
            type: 'danger'
          })
        })
    },
    updateControlsState () {
      let _readonly = false

      if (this.mode == this.$constants.FORM_MODE.EDIT) {
        _readonly = false
      }

      if (this.mode == this.$constants.FORM_MODE.VIEW) {
        _readonly = true
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly
        }
      }

      //this.controls.ratehour.mode = this.mode
      //this.controls.rateyear.mode = this.mode
    }
  },
  watch: {
    'salary.category' () {},
    mode () {
      this.updateControlsState()
    },
    active (newValue) {
      //refresh chart
      if (newValue) this.drawTrendChart()
    }
  }
}
</script>

<style scoped>
.multiselect__tags {
  min-height: 30px;
  padding: 6px 40px 0 6px;
}

.multiselect__single {
  font-size: 14px;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.disabled {
  background-color: #e8e8e873 !important;
}

.vue-daterange-picker {
  position: relative;
  display: block !important;
}
</style>
