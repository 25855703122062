var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-image-container",
    style: _vm.containerStyle,
    on: {
      "click": _vm.triggerUpload
    }
  }, [!_vm.imageUrl ? _c('div', {
    staticClass: "simple-image-placeholder"
  }, [_c('i', {
    staticClass: "bi bi-image fs-1 mb-2"
  }), _c('span', [_vm._v("Click to upload")])]) : _vm._e(), _vm.imageUrl ? _c('img', {
    staticClass: "simple-image",
    attrs: {
      "src": "".concat(_vm.STORAGE_URL).concat(_vm.imageUrl),
      "alt": "Product"
    }
  }) : _vm._e(), _vm.imageUrl ? _c('button', {
    staticClass: "delete-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.removeImage.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "bi bi-x",
    staticStyle: {
      "font-size": "20px",
      "color": "#000"
    }
  })]) : _vm._e(), _vm.isLoading ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "spinner-border text-primary"
  })]) : _vm._e(), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": _vm.acceptTypes.join(',')
    },
    on: {
      "change": _vm.handleFileChange
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }