<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group v-if="showAddButton">
              <b-button
                variant="outline-dark"
                title="Create"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            v-if="!barrelNumber"
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
export default {
  props: {
    barrelNumber: {
      type: [String, Number],
      default: ''
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    purchaseOrderId: {
      type: [Number, String],
      default: ''
    },
    lotNumber: {
      type: [Number, String],
      default: ''
    },
    title: {
      type: String,
      default: undefined
    }
  },
  name: 'BarrelFillingTable',
  components: {},
  data: function () {
    return {
      dataset: {
        name: 'barrel-filling'
      },
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          title: '',
          columns: [
            'ID',
            'BF ID',
            'AF Name',
            'Finished Product',
            'Barrel Date',
            'Total Amount Used',
            'Barrels Filled',
            'Purchase Number',
            'Created',
            'Created By'
            /*
            "ID",
            "AF Action Number",
            "AF Name",
            "Finished Product",
            "Amount Used",
            "Barrel #",
            "Barrel Date",
            "Created By",
            "Modified By",
            "Created",
            "Modified"
*/
          ],
          filterable: [
            'ID',
            'AF Name',
            'Finished Product',
            'Barrel Date',
            'Total Amount Used',
            'Barrels Filled',
            'Created',
            'Created By'
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: true,
          showCustomActions: true,
          dataFilters: []
        }
      }
    }
  },
  computed: {},
  mounted () {
    this.dataTable.options.title = this.title || ''

    if (this.barrelNumber) this.getData({ barrel_number: this.barrelNumber })
    else if (this.purchaseOrderId)
      this.getData({ purchase_order_id: this.purchaseOrderId })
    else if (this.lotNumber) this.getData({ lot_number: this.lotNumber })
  },
  methods: {
    onFilter () {},

    async getData (payload) {
      let self = this

      this.dataFilters = payload

      this.dataTable.isLoading = true

      //  this.dataTable.dataSet = [];

      let response = []

      try {
        response = await this.$api.post('barrel-filling', payload)

        self.dataTable.isLoading = false

        self.dataTable.dataSet = response

        if (response.length === 0) return

        self.$emit('loaded', self.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        self.dataTable.isLoading = false
        self.$form.msgBoxOk('Error occured')
      }
    },
    addItem: function () {
      this.$router.push({
        name: 'Barrel filling submission',
        params: {
          action: 'create'
        }
      })
    },
    viewItem: function (id) {
      this.$router.push({
        name: 'Barrel filling submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editItem: function (id) {
      this.$router.push({
        name: 'Barrel filling submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteItem: async function (id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(
        `Barrel filling #${item.ID} and all related barrels which are not bottled and not selected in sample requests will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.$api
        .delete(`barrel-filling/${item['ID']}`)
        .then(response => {
          self.$form.makeToastInfo(response.message)
          self.getData(this.dataFilters)
        })
        .catch(response => {
          console.log(response)
          self.$form.makeToastError(response.message)
        })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
