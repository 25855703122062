var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "parent-id": _vm.orderId,
      "actions-mode": "inline",
      "model": _vm.model,
      "columns": _vm.columns,
      "read-only": _vm.readOnly,
      "editable-columns": _vm.editableColumns,
      "cell-classes": _vm.cellClasses,
      "custom-actions": _vm.customActions
    },
    on: {
      "inline-dropdown-change": _vm.onInlineDropdownChange,
      "updated": _vm.onItemsTableUpdate,
      "inserted": _vm.onItemsTableInsert,
      "deleted": _vm.onItemsTableDelete,
      "loaded": _vm.onItemsTableLoad
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }