import { api } from '@/shared/api'

const Products = {
  fetchProducts: async payload => {
    let res = await api.post(`products`, payload)

    return res
  },
  saveProducts: async payload => {
    console.log('saveProducts', payload)

    let url = payload.ID ? `products/${payload.ID}` : `products`

    return api.put(url, payload)
  },
  deleteProduct: async ID => {
    return api.delete(`products/${ID}`)
  },
  fetchProductsCategories: async () => {
    let res = await api.get(`products/categories`)

    return res
  },
  fetchFinishedProducts: async () => {
    let res = await api.get(`products/finished-products`)

    return res
  }
}

export default Products
