<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(model)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
      @row-select="onRowSelect"
    >
      <div slot="afterFilter">
        <b-row v-if="model.actions && model.actions.Create && !readOnly">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                size="sm"
                :disabled="dataTable.isInserting"
                @click="addItem()"
                title="Add item"
              >
                <font-awesome-icon icon="plus" /> Add item
              </b-button>
              <slot name="afterFilterButtons" />
            </b-button-group>
          </b-col>
        </b-row>
      </div>

      <slot v-for="slotName in slots" :slot="slotName" :name="slotName" slot-scope="props" :row="props.row"> </slot>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-for="(customAction, index) in customActions"
            :key="`ca-${index}`"
            class="btn btn-secondary btn-sm"
            @click="customAction.click(props.row)"
            :title="customAction.title"
          >
            <font-awesome-icon :icon="customAction.icon" />
          </button>

          <button v-if="model.actions.View" class="btn btn-primary btn-sm" @click="viewItem(props.row)" title="View">
            <font-awesome-icon icon="eye" />
          </button>

          <button
            v-if="model.actions.Edit && model.actions.Edit.validator(props.row) && !readOnly"
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <!-- show delete button to everyone if action is defined in the model file OR show it to those users
          who has defined permissions (For examle WH Invoices Robert && Mark )
          -->
          <button
            v-if="
              ((model.actions.Delete && model.actions.Delete.validator(props.row)) || $permitted('delete').visible) &&
                !readOnly
            "
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import modelHelpers from '@/models/helpers'

export default {
  name: 'TableWrapper',
  props: {
    parentId: {
      type: [String, Number],
      default: '',
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {},
      required: true
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    slots: {
      type: Array,
      required: false,
      default: () => []
    },
    perPage: {
      type: [String, Number],
      default: 50
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    disableInlineEditing: {
      type: Boolean,
      default: false
    },

    cellClasses: {
      type: Object,
      default: () => {}
    },
    actionsMode: {
      type: String,
      default: 'router',
      validator: propValue => {
        let check = ['router', 'inline', 'event'].some(v => propValue == v)

        if (!check)
          console.error(
            `actionsMode property value '${propValue}' is invalid. Allowed only "router", "inline", "event"`
          )

        return check
      }
    },
    filterByColumn: {
      type: Boolean,
      default: true
    },
    selectableRows: {
      type: Boolean,
      default: false
    },
    customActions: {
      type: Array,
      required: false,
      default: () => {}
    }
  },
  emits: ['add', 'create', 'inserted', 'updated', 'deleted', 'loaded'],
  data: function () {
    return {
      isLoading: false,
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          showEmpty: true,
          filterByColumn: this.filterByColumn,
          columns: this.columns,
          filterable: this.columns,
          editableColumns: this.disableInlineEditing
            ? []
            : this.model.entities
            ? this.model.entities.filter(e => e.readonly == false).map(e => e.name)
            : [],
          dropdownColumns: [],
          perPage: this.perPage,
          //showActions: false,
          showCustomActions: true,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback,
          hideSettingsBar: false,
          cellClasses: this.cellClasses,
          selectableRows: this.selectableRows,
          readOnly: this.readOnly,
          slots: this.slots,
          explicitColumnTypes: this.model.entities
            ? this.model.entities.map(e => ({
                column: e.name,
                type: e.type
              }))
            : []
        }
      },
      dataFilter: {},
      dblclick: undefined,
      serverFilter: undefined
    }
  },
  computed: {},
  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    async initialize () {
      // console.log('TableWrapper.initialize')

      if (!this.model.name) console.error('TableWrapper.model.name is undefined!', this.model.name)

      if (!this.model.entities) console.error('TableWrapper.model.entities is undefined!', this.model.entities)

      if (this.model.actions && !this.readOnly)
        this.dataTable.options.columns = [...this.dataTable.options.columns, ...['Actions']]

      this.dataTable.options.dropdownColumns = this.model.entities
        .filter(e => e.type == 'dropdown')
        .map(e => {
          return {
            name: e.name,
            options: [],
            templates: e?.templates || null
          }
        })

      for (let column of this.dataTable.options.dropdownColumns) {
        let entity = this.model.entities.find(e => e.name == column.name)

        if (!entity.optionsService)
          console.error(`Dropdown column '${this.model.name}.${column.name}' has undefined 'optionsService' property`)
        column.options = await entity.optionsService()
        column.options = column.options.map(i => ({id: i.id, label: i.name, item: i}))
      }

      if (this.parentId) this.getData()
    },

    onInlineDropdownChange (e) {
      this.$emit('inline-dropdown-change', e)
    },
    onInlineColumnUpdate () {},
    onInlineUpdate (e) {
      let rowIndex = this.dataTable.dataSet.findIndex(i => i['ID'] == e.id)

      let row = this.dataTable.dataSet[rowIndex]

      if (!e.value.label) row[e.column] = e.value
      else {
        row[e.column + '_ID'] = e.value.id
      }

      this.dataTable.dataSet[rowIndex] = row

      this.$emit('updated', this.dataTable.dataSet, row)

      if (this.actionsMode == 'event') {
        //this.$emit("updated", this.dataTable.dataSet, row);
        return
      }

      /*
      if (
        this.$form.isViewMode(this) &&
        (!this.model.services || !this.model.services.saveRecord)
      ) {
        this.$form.makeToastError(
          `saveRecord service is not defined for ${this.model.name}`
        );
        return;
      } else {
        */

      /*it should work only in view mode details form and in filterable datasets */
      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.saveRecord
      )
        // console.log('TableWrapper.onInlineUpdate', row) //20240827

        return this.model.services
          .saveRecord(row)
          .then(response => {
            this.$form.makeToastInfo(response.message)

            this.getData()
          })
          .catch(error => {
            this.$form.makeToastError(error.message)
          })
    },
    loadDictionaries () {},

    getDataSet () {
      return this.dataTable.dataSet
    },

    updateDataSet (payload) {
      this.dataTable.dataSet = payload
      this.$refs.dataTable.refresh()
    },
    setOrder (columnName, ascending = true) {
      this.$refs.dataTable.setOrder(columnName, ascending)
    },
    addItem () {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'create'
          }
        })

        return
      }

      if (this.actionsMode == 'inline') {
        let newItem = {
          // uid: this.$helpers.uuidv4(),
          ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          ...modelHelpers.getEmptyEntitiesObjectFlatten(this.model.entities)
        }

        this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem)

        this.dataTable.isInserting = true

        return
      }

      if (this.actionsMode == 'event') {
        this.$emit('create')
        return
      }
    },
    viewItem: function (row) {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'view',
            id: row.ID
          }
        })
      }

      if (this.actionsMode == 'event') {
        this.$emit('view', {row: row})
        return
      }
    },
    editItem: function (id) {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'edit',
            id: id
          }
        })
      }
    },
    async saveNewRecordCallback (newRecord) {
      console.log('newRecord', newRecord)

      let self = this

      for (let entity of this.model.entities) {
        if (entity.required && !newRecord[entity.name]) {
          //if (entity.required && newRecord[entity.name]==null) {
          this.$form.makeToastError(`Please specify the "${entity.name}" value`)
          return
        }
      }

      self.dataTable.isInserting = false

      /*20220112*/
      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.saveRecord
      ) {
        let response = await this.model.services.saveRecord(newRecord)

        if (response.status == 200) this.$form.makeToastInfo(response.message)
        else this.$form.makeToastError(response.message)

        this.getData()
      }

      this.$refs.dataTable.drawTable(this.dataTable.dataSet)

      /**********/

      this.$emit('inserted', newRecord)

      return true
    },

    async revertNewRecordCallback () {
      this.dataTable.isInserting = false

      return true
    },
    async deleteItem (row) {
      let idProp = this.$form.isCreateMode(this) ? 'uid' : 'ID'

      let confirm = await this.$form.showConfirmation(`Item #${row[idProp]} will be removed. Do you want to proceed?`)

      if (!confirm) return
      /*
      if (!this.model.services || !this.model.services.deleteRecord) {
        this.$form.makeToastError(
          `deleteRecord service is not defined for ${this.model.name}`
        );
        return;
      }
*/
      this.dataTable.dataSet = this.dataTable.dataSet.filter(i => i[idProp] !== row[idProp])

      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.deleteRecord
      ) {
        let response = await this.model.services.deleteRecord(row[idProp])

        if (response.status == 200) this.$form.makeToastInfo(response.message)
        else this.$form.makeToastError(response.message)

        this.getData()
      }

      this.$refs.dataTable.drawTable(this.dataTable.dataSet)

      this.$emit('deleted', row)
    },
    onRowSelect () {
      let selectedRows = this.$refs.dataTable.getSelectedRows()

      this.$emit('row-select', selectedRows)
    },
    async getData (payload) {
      //console.log('TableWrapper.getData.payload', payload)
      if (payload) this.dataFilter = payload

      this.dataTable.isLoading = true

      let response = []

      if (this.parentId) {
        if (!this.dataFilter) this.dataFilter = {}

        this.dataFilter.parentId = this.parentId
      }

      try {
        //console.log('TableWrapper.getData.dataFilter', this.dataFilter)
        response = await this.model.services.fetchData(this.dataFilter)

        this.dataTable.isLoading = false

        this.dataTable.dataSet = response

        //if (response.length === 0) return;

        this.$emit('loaded', this.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        this.dataTable.isLoading = false
        this.$form.msgBoxOk('Error occured')
      }
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler (newVal) {
        this.dataTable.isLoading = newVal
      }
    },
    readOnly: {
      immediate: true,
      handler (newVal) {
        this.dataTable.options.readOnly = newVal
      }
    }
  }
}
</script>

<style scoped>
/*
::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
*/

::v-deep .ct-inline-select {
  width: 15em !important;
}
</style>
