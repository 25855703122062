import axios from 'axios'
import moment from 'moment'

let url = process.env.VUE_APP_API_URL

//url = 'http://koval-api-02.local';

export let api = {
  get: resource => api.execute('get', resource),
  put: (resource, data) => api.execute('put', resource, data),
  post: (resource, data) => api.execute('post', resource, data),
  delete: (resource, data) => api.execute('delete', resource, data),

  execute (method, resource, data) {
    return new Promise(function (fulfill, reject) {
      return axios({ method: method, url: `${url}/${resource}`, data: data })
        .then(response => {
          return fulfill(response ? response.data : response)
        })
        .catch(function (error) {
          return reject(error ? error.response : error)
        })
    })
  },
  countries: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/countries`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    getMapped: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        data.set('mapped', 'true')

        axios
          .post(`${url}/dictionaries/countries`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },

  states: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/states`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },
  cities: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/cities`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },

  accounts: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/accounts`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },
  chains: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/chains`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },

  products: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .get(`${url}/dictionaries/products`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    get_sales: filters => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        data.set('filter', JSON.stringify(filters))

        axios
          .post(`${url}/reports/sold-products`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },
  categories: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/categories`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },
  productcategories: {
    get: () => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        axios
          .post(`${url}/dictionaries/productcategories`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },

  data: {
    StoreLocator: {
      getData: function (filter) {
        return api.data.requestReportData('store-locator-data', filter)
      },
      getSoldProducts: function (filter) {
        return api.data.requestReportData('store-locator-sold-products', filter)
      }
    },
    getSalesByProduct: filter => {
      return api.data.requestReportData('sales-by-product', filter)
    },
    getSalesByState: filter => {
      return api.data.requestReportData('sales-by-state', filter)
    },
    getSalesByAccount: filter => {
      return api.data.requestReportData('sales-by-account', filter)
    },
    'Sales.InvoiceData': filter => {
      return api.data.requestReportData('sales-full', filter)
    },
    getSalesReportCustom: filter => {
      return api.data.requestReportData('sales-report-custom', filter)
    },
    getStateLevelMetrics: filter => {
      return api.data.requestReportData('state-level-metrics', filter)
    },
    getChainPerformance: filter => {
      return api.data.requestReportData('chain-performance', filter)
    },
    'ChainPerformance.Details': filter => {
      return api.data.requestReportData('chain-performance-details', filter)
    },
    'ChainPerformance.Locations': filter => {
      return api.data.requestReportData('chain-performance-locations', filter)
    },
    'ChainPerformance.MonthlySales': filter => {
      return api.data.requestReportData(
        'chain-performance-monthly-sales',
        filter
      )
    },
    getMarketSalesActivity: filter => {
      return api.data.requestReportData('market-sales-activity', filter)
    },
    getMarketProductCategory: filter => {
      return api.data.requestReportData('market-product-category', filter)
    },
    getMarketSalesByProduct: filter => {
      return api.data.requestReportData('market-sales-by-product', filter)
    },
    getMarketChangeInTotalSales: filter => {
      return api.data.requestReportData('market-change-total-sales', filter)
    },
    getMarketBestWorstProducts: filter => {
      return api.data.requestReportData('market-best-worst-products', filter)
    },
    getMarketOrderRegularity: filter => {
      return api.data.requestReportData('salesteam-order-regularity', filter)
    },
    getMarketSalesDetails: filter => {
      return api.data.requestReportData('market-sales-details', filter)
    },
    getMarketActivityDetails: filter => {
      return api.data.requestReportData('market-activity-details', filter)
    },
    'Market.AccountConversion': function (filter) {
      return api.data.requestReportData('market-account-conversion', filter)
    },
    'Market.AccountConversionDrilldown': function (filter) {
      return api.data.requestReportData(
        'market-account-conversion-drilldown',
        filter
      )
    },
    'Market.HistoricalActivityDrilldown': function (filter) {
      return api.data.requestReportData(
        'market-historical-activity-drilldown',
        filter
      )
    },
    'Market.TopOnPremise': function (filter) {
      return api.data.requestReportData('market-top-onpremise', filter)
    },
    'Market.TopOffPremise': function (filter) {
      return api.data.requestReportData('market-top-offpremise', filter)
    },
    'Market.TopCocktails': function (filter) {
      return api.data.requestReportData('market-top-cocktails', filter)
    },
    'Market.OverdueAccounts': function (filter) {
      return api.data.requestReportData('market-overdue-accounts', filter)
    },
    'Product.Overview.InvoicesAccountsChart4Drilldown': function (filter) {
      return api.data.requestReportData(
        'products/overview/invoices-accounts-drilldown',
        filter
      )
    },
    'Product.WH-Overview.InvoicesAccountsChart4Drilldown': function (filter) {
      return api.data.requestReportData(
        'products/wh-overview/invoices-accounts-drilldown',
        filter
      )
    },
    'Account.Contacts': function (filter) {
      return api.data.requestReportData('account-contacts', filter)
    },
    getAccountDetails: filter => {
      return api.data.requestReportData('account-details', filter)
    },
    getAccountPerformance: filter => {
      return api.data.requestReportData('account-performance', filter)
    },
    getAccountBestWorstProducts: filter => {
      return api.data.requestReportData('account-best-worst-products', filter)
    },
    getAccountSalesAndActivity: filter => {
      return api.data.requestReportData('account-sales-and-activity', filter)
    },
    getAccountProductCategorySales: filter => {
      return api.data.requestReportData(
        'account-product-category-sales',
        filter
      )
    },
    getAccountSalesByProduct: filter => {
      return api.data.requestReportData('account-sales-by-product', filter)
    },
    'SalesTeam.WeeklyActivity': function (filter) {
      return api.data.requestReportData('salesteam-weekly-activity', filter)
    },
    'SalesTeam.WeeklyActivityDrilldown': function (filter) {
      return api.data.requestReportData(
        'salesteam-weekly-activity-drilldown',
        filter
      )
    },
    'SalesTeam.MonthlyActivityDrilldown': function (filter) {
      return api.data.requestReportData(
        'salesteam-monthly-activity-drilldown',
        filter
      )
    },
    'SalesTeam.WeeklyReport': function (filter) {
      return api.data.requestReportData('salesteam-weekly-report', filter)
    },

    'SalesTeam.AccountEngagement': function (filter) {
      return api.data.requestReportData('salesteam-account-engagement', filter)
    },
    'SalesTeam.AccountEngagementDrilldown': function (filter) {
      return api.data.requestReportData(
        'salesteam-account-engagement-drilldown',
        filter
      )
    },
    'SalesTeam.OverdueRegularAccounts': function (filter) {
      return api.data.requestReportData('salesteam-overdue-accounts', filter)
    },
    'SalesTeam.DecreasingSalesAccounts': function (filter) {
      return api.data.requestReportData(
        'salesteam-decreasingsales-accounts',
        filter
      )
    },
    'SalesTeam.IncreasingSalesAccounts': function (filter) {
      return api.data.requestReportData(
        'salesteam-increasingsales-accounts',
        filter
      )
    },
    'SalesTeam.BiggestNewAccounts': function (filter) {
      return api.data.requestReportData('salesteam-biggestnew-accounts', filter)
    },
    getSalesTeamAccountLevelDetails: function (filter) {
      return api.data.requestReportData(
        'salesteam-account-level-details',
        filter
      )
    },
    requestReportData: (report, filter) => {
      return new Promise(function (fulfill, reject) {
        let data = new FormData()

        let _filter = JSON.parse(JSON.stringify(filter))

        if (_filter.period)
          _filter.period.startDate = moment(_filter.period.startDate).format(
            'YYYY-MM-DD'
          )
        if (_filter.period)
          _filter.period.endDate = moment(_filter.period.endDate).format(
            'YYYY-MM-DD'
          )

        if (_filter.period2)
          _filter.period2.startDate = moment(_filter.period2.startDate).format(
            'YYYY-MM-DD'
          )
        if (_filter.period2)
          _filter.period2.endDate = moment(_filter.period2.endDate).format(
            'YYYY-MM-DD'
          )

        if (_filter.period3)
          _filter.period3.startDate = moment(_filter.period3.startDate).format(
            'YYYY-MM-DD'
          )
        if (_filter.period3)
          _filter.period3.endDate = moment(_filter.period3.endDate).format(
            'YYYY-MM-DD'
          )

        data.set('name', report)
        data.set('filter', JSON.stringify(_filter))

        console.log(`${url}/reports`, 'requestReportData', report, _filter)
        axios
          .post(`${url}/reports`, data)
          .then(response => {
            fulfill(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }
}
