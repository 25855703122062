import {api} from '@/shared/api'

const dictionary = {
  async fetchWeekDays () {
    return [
      {id: 1, name: 'Monday'},
      {id: 2, name: 'Tuesday'},
      {id: 3, name: 'Wednesday'},
      {id: 4, name: 'Thursday'},
      {id: 5, name: 'Friday'},
      {id: 6, name: 'Saturday'},
      {id: 7, name: 'Sunday'}
    ]
  },

  async fetchIncomingOrdersDictionary (payload) {
    return api.get('dictionary/incoming-orders', payload)
  },
  async fetchIncomingOrdersWithoutPicklists (payload) {
    return api.get('dictionary/incoming-orders/wo-picklists', payload)
  },
  async fetchProducts () {
    let response = await api.post('products')

    let products = response.map(i => ({
      ...i,
      id: i['ID'],
      name: i['Product Name Full'],
      item: i
    }))

    return products
  },
  async fetchFinishedProducts () {
    return api.get('dictionaries/finished-products')
  },
  async fetchFinishedProductsBottling () {
    return api.get('dictionaries/finished-products/bottling')
  },
  async fetchDistributors () {
    return api.get('dictionaries/accounts/distributors/warehouse')
  },
  async fetchDistributorContacts (payload) {
    console.log('fetchDistributorContacts.payload', payload)
    return api.get(`accounts/${payload}/contacts`)
  },
  async fetchCurrencies () {
    return api.get('dictionaries/inventory/currencies')
  },
  async fetchWarehouseInvoices () {
    return api.get('dictionaries/warehouse/invoices')
  },
  async fetchWarehouses () {
    return api.get('dictionaries/warehouses')
  },
  async fetchWarehouseStatuses () {
    return [
      {id: 5, name: 'Available bottles'},
      {id: 1, name: 'Available and used bottles'},
      {id: 2, name: 'All Errors'},
      {id: 4, name: 'Negative Errors'},
      {id: 3, name: 'Positive Errors'}
    ]
  },
  async fetchOrganicStatuses () {
    return api.get('dictionaries/organic-statuses')
  },
  async fetchBottleSizes () {
    return api.get('dictionaries/bottle-sizes')
  },
  async fetchCaseSizes () {
    return api.get('dictionaries/case-sizes')
  },
  async fetchTerritories () {
    return api.get('dictionaries/territories')
  },
  async fetchMarkets () {
    return api.get('dictionaries/markets')
  },
  async fetchYesNo () {
    return [
      {id: 1, label: 'Yes'},
      {id: 0, label: 'No'}
    ]
  }
}

export default dictionary
