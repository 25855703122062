<template>
  <div class="animated fadeIn">
    <b-card>
      <DetailsFormWrapper
        ref="form"
        :id="id"
        :model="model"
        :data="data"
        :custom-buttons="customButtons"
        @custom-click="
          name => {
            this[name]()
          }
        "
        header="Incoming order"
        @loaded="onFormLoad"
      >
        <slot name="content">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Overview" active>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput v-model="data['ID']" :properties="entity('ID')" @change="onFieldChange"></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['PO Number']"
                    :properties="entity('PO Number')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Warehouse']"
                    :properties="entity('Warehouse')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    ref="refDistributor"
                    v-model="data['Distributor']"
                    :properties="entity('Distributor')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['PO Date']"
                    :properties="entity('PO Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Est Finish Date']"
                    :properties="entity('Est Finish Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    ref="refReceivedFrom"
                    v-model="data['Received From']"
                    :properties="entity('Received From')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Currency']"
                    :properties="entity('Currency')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput v-model="data['Status']" :properties="entity('Status')"></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    ref="refPOConfirmed"
                    v-model="data['PO Confirmed']"
                    :properties="entity('PO Confirmed')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Confirmation Sent Date']"
                    :properties="entity('Confirmation Sent Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput v-model="data['Pick List ID']" :properties="entity('Pick List ID')"></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput v-model="data['Invoice Number']" :properties="entity('Invoice Number')"></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="6" sm="6">
                  <FormInput v-model="data['Notes']" :properties="entity('Notes')" @change="onFieldChange"></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created By']"
                    :properties="entity('Created By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created']"
                    :properties="entity('Created')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified By']"
                    :properties="entity('Modified By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified']"
                    :properties="entity('Modified')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <hr />

              <incoming-order-items-table
                ref="incoming-order-items"
                :order-id="id"
                :form-data="data"
                :read-only="isReadOnlyRecord"
                @changed="onItemsChange"
                @loaded="onItemsTableLoad"
              ></incoming-order-items-table>

              <b-row>
                <b-col lg="6" md="6" sm="6">
                  <div :key="`gpp-${data['items'].length}`">
                    <div v-for="(group, productId) in groupedProducts" :key="productId" class="product-group mb-4">
                      <b-table-simple small bordered responsive class="table table-striped">
                        <b-thead>
                          <b-tr class="bg-light">
                            <b-th colspan="3" class="text-left"
                              >{{ group.productName }} ( {{ group.pallets.length }} pallets,

                              {{ group.pallets.reduce((sum, p) => sum + parseFloat(p.weight), 0).toFixed(2) }} lbs,

                              {{ group.pallets.reduce((sum, p) => sum + p.fullCases, 0) }} cases,
                              {{ group.item.Bottles }} bottles total)</b-th
                            >
                          </b-tr>
                          <!--
                          <b-tr class="bg-white">
                            <b-th>Pallet</b-th>
                            <b-th>Weight</b-th>
                            <b-th>Layout</b-th>
                          </b-tr>
                        -->
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="pallet in group.pallets" :key="pallet.palletNumber">
                            <b-td>{{ pallet.palletNumber }}</b-td>
                            <b-td>{{ pallet.weight }} lbs</b-td>
                            <b-td>
                              [{{ pallet.layerInfo }}, {{ pallet.fullCases }} cases{{
                                pallet.extraBottles ? ' + ' + pallet.extraBottles + ' bottles' : ''
                              }}, {{ pallet.totalBottles }} bottles total]
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </div>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Discount']"
                    :properties="entity('Discount')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput v-model="data['Total']" :properties="entity('Total')"></FormInput>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Confirmation letter" v-if="id">
              <b-row class="justify-content-start">
                <b-col class="text-left" cols="auto">
                  <b-button variant="outline-dark" class="m-1" @click="downloadCL">
                    <b-spinner v-if="loading.save" small type="grow" />
                    <font-awesome-icon v-if="!loading.save" icon="download" />
                    Download
                  </b-button>
                </b-col>
                <b-col class="text-left" cols="auto">
                  <b-button variant="outline-dark" class="m-1" @click="sendCL">
                    <b-spinner v-if="loading.send" small type="grow" />
                    <font-awesome-icon v-if="!loading.send" icon="paper-plane" />
                    Send
                  </b-button>
                </b-col>
                <b-col class="text-left d-flex align-items-center" cols="auto">
                  Send to:
                </b-col>

                <b-col class="text-left d-flex align-items-center" cols="auto">
                  <b-form-input v-model="data['Send To']" />
                </b-col>
              </b-row>
              <ConvirmationLetterEditor :data.sync="data"> </ConvirmationLetterEditor>
            </b-tab>
            <b-tab :title="controls.tabs.files.title" v-if="id">
              <files-container :module-id="$route.meta.module.id" :entity-id="id" @loaded="onFileContainerLoad" />
            </b-tab>
          </b-tabs>
        </slot>
      </DetailsFormWrapper>
    </b-card>
  </div>
</template>

<script>
import models from '@/models'
import modelHelpers from '@/models/helpers'
import IncomingOrderItemsTable from '@/views/Warehouse/IncomingOrders/IncomingOrderItemsTable'
import dictionaryServices from '@/services/dictionary.service'
import ConvirmationLetterEditor from '@/views/Warehouse/IncomingOrders/ConfirmationLetterEditor'
import productServices from '@/services/SalesAdministrative/products.service'

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import html2pdf from 'html2pdf.js'
export default {
  name: 'IncomingOrderSubmission',
  components: {
    IncomingOrderItemsTable,
    ConvirmationLetterEditor,
    html2canvas,
    jsPDF,
    html2pdf
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data: function () {
    return {
      loading: {
        save: false,
        send: false
      },
      isReadOnlyRecord: false,
      model: models.warehouse.incomingOrders,

      data: {
        view_content: '',
        edit_content: '',
        content: '',
        productsDictionary: [],
        Distributor: {},
        items: []
      },
      controls: {
        tabs: {
          files: {
            title: 'Files'
          }
        },
        editor: {
          data: '',
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        }
      },
      customButtons: [
        {
          text: 'Confirmation',
          icon: 'paper-plane',
          loading: false,
          visible: false,
          method: 'sendConfirmation',
          variant: 'outline-dark'
        },
        {
          text: 'Convert to picklist',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'convert2picklist',
          variant: 'outline-dark'
        },
        {
          text: 'Open picklist',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'openPicklist',
          variant: 'outline-dark'
        },
        {
          text: 'Open invoice',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'openInvoice',
          variant: 'outline-dark'
        }
      ],
      entity: modelHelpers.entity
    }
  },
  computed: {
    groupedProducts () {
      const groups = {}

      const itemsGrouped = this.data['items'].reduce((acc, item) => {
        if (item.Deleted) return acc
        if (!acc[item.Product_ID]) {
          acc[item.Product_ID] = {...item, Bottles: 0}
        }
        acc[item.Product_ID].Bottles += parseInt(item.Bottles)
        return acc
      }, {})

      Object.values(itemsGrouped).forEach(item => {
        const product = this.data.productsDictionary.find(p => p.ID === item.Product_ID)
        if (!product) return

        groups[item.Product_ID] = {
          productName: product['Product Name Full'],
          item,
          pallets: []
        }

        const bottlesPerCase = product['Case Size']
        const casesPerLayer = product['Cases per Layer']
        const layersPerPallet = product['Layers per Pallet']
        const casesPerPallet = item['Cases per Pallet'] || casesPerLayer * layersPerPallet
        const caseWeight = parseFloat(product['Case Weight'])

        const totalFullCases = Math.floor(item.Bottles / bottlesPerCase)
        const remainingBottles = item.Bottles % bottlesPerCase
        let remainingCases = totalFullCases
        let palletsNeeded = Math.ceil(remainingCases / casesPerPallet)

        for (let palletIndex = 0; palletIndex < palletsNeeded; palletIndex++) {
          const isLastPallet = palletIndex === palletsNeeded - 1
          const casesInThisPallet = isLastPallet ? remainingCases : Math.min(remainingCases, casesPerPallet)

          const completeLayersCount = Math.floor(casesInThisPallet / casesPerLayer)
          const remainderCases = casesInThisPallet % casesPerLayer
          const palletWeight = (casesInThisPallet * caseWeight).toFixed(2)
          const extraBottles = isLastPallet ? remainingBottles : 0

          groups[item.Product_ID].pallets[palletIndex] = {
            palletNumber: palletIndex + 1,
            weight: palletWeight,
            fullCases: casesInThisPallet,
            extraBottles,
            layerInfo: `${completeLayersCount} layers${remainderCases ? ' + ' + remainderCases + ' cases' : ''}`,
            totalBottles: casesInThisPallet * bottlesPerCase + extraBottles
          }

          remainingCases -= casesPerPallet
        }
      })

      return groups
    }
  },
  created () {
    this.setDefaults()
  },
  async mounted () {
    const response = await productServices.fetchProducts()
    this.data.productsDictionary = response

    this.updateDistributorsOptions()

    this.updateComputedFields()
  },
  methods: {
    traverseAndSetContentEditable (element, isEditable) {
      if (element.hasAttribute('contenteditable')) {
        element.setAttribute('contenteditable', isEditable.toString())
      }
      Array.from(element.children).forEach(child => this.traverseAndSetContentEditable(child, isEditable))
    },
    async generatePDF () {
      const element = document.getElementById('confirmationLetter')

      const fileName = `Order confirmation #${this.data['PO Number']} ${new Date().toISOString().split('T')[0]}.pdf`
      // Set options for html2pdf
      let options = {
        margin: 10,
        filename: fileName,
        image: {type: 'jpeg', quality: 0.98}, // Set quality if using images
        html2canvas: {scale: 5}, // Increase scale to improve quality
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
      }

      await new Promise(resolve => setTimeout(resolve, 100))

      this.traverseAndSetContentEditable(element, false)

      await html2pdf()
        .from(element)
        .set(options)
        .save()

      this.traverseAndSetContentEditable(element, true)
    },

    async downloadCL () {
      this.loading.save = true

      let result = ''

      this.generatePDF()

      this.loading.save = false

      if (result) {
        //   saveAs(result, `Confirmation Letter ${this.id}.docx`)
      }
    },
    async sendCL () {
      let confirm = await this.$form.showConfirmation(
        `Order confirmation letter will be sent to ${this.data['Received From'].label} [${this.data['Send To']}]. Do you want to proceed?`
      )
      if (!confirm) return

      this.loading.send = true
      try {
        const element = document.getElementById('confirmationLetter')
        const fileName = `Order confirmation #${this.data['PO Number']} ${new Date().toISOString().split('T')[0]}.pdf`

        // PDF generation options
        const options = {
          margin: 10,
          filename: fileName,
          image: {type: 'jpeg', quality: 0.98},
          html2canvas: {scale: 5},
          jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
        }

        this.traverseAndSetContentEditable(element, false)
        // Generate PDF blob with proper options
        const pdfBlob = await html2pdf()
          .from(element)
          .set(options)
          .outputPdf('blob')

        this.traverseAndSetContentEditable(element, true)

        // Create File object from Blob
        const file = new File([pdfBlob], fileName, {type: 'application/pdf'})

        // Create FormData
        const formData = new FormData()
        formData.append('file', file)
        formData.append('email', this.data['Send To'])
        formData.append('subject', `Order Confirmation #${this.data['PO Number']}`)
        formData.append('body', 'Please find attached the order confirmation.')
        formData.append('file_name', fileName)

        // Send request
        await this.$api.post(`warehouse/incoming-order/${this.id}/send-email`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.data['PO Confirmed'] = this.$refs.refPOConfirmed.options.find(i => i.label === 'Yes')
        await this.$refs['form'].save('view')
        await this.$refs['form'].initialize()
        await this.$refs['incoming-order-items'].getData()
        this.$form.makeToastInfo('Email sent successfully')
      } catch (error) {
        console.error('PDF generation or email sending failed:', error)
        this.$form.makeToastError(`Failed to send email: ${error.message}`)
      } finally {
        this.loading.send = false
      }
    },
    async convert2picklist () {
      let confirm = await this.$form.showConfirmation(
        `Incoming order will be converted to picklist. Do you want to proceed?`
      )

      if (!confirm) return

      this.customButtons[1].loading = true

      let result = await this.$api.post(`warehouse/incoming-order/${this.id}/convert`)

      this.customButtons[1].loading = false

      if (result.id) {
        this.$form.makeToastInfo('Success')

        this.$refs['form'].initialize()
      }
    },
    async sendConfirmation () {
      let confirm = await this.$form.showConfirmation(`Confirmation email will be sent. Do you want to proceed?`)

      if (!confirm) return

      this.customButtons[0].loading = true

      let result = await this.$api.post(`warehouse/incoming-order/${this.id}/send-confirmation`)

      this.customButtons[0].loading = false

      if (result.id) {
        this.$form.makeToastInfo('Success')

        this.$refs['form'].initialize()
      }

      this.$form.makeToastInfo('Success')
    },
    async openPicklist () {
      this.$router.push({
        name: 'Pick list submission',
        params: {mode: 'view', id: this.data['Pick List ID']}
      })
      //this.data["Pick List ID"]
    },
    async openInvoice () {
      this.$router.push({
        name: 'WH Invoice submission',
        params: {mode: 'view', id: this.data['Invoice Number']}
      })
      //this.data["Pick List ID"]
    },

    setDefaults () {
      this.data = modelHelpers.getEmptyEntitiesObject(this.model.entities)

      this.data['items'] = []
    },
    async onFormLoad (payload) {
      if (!payload) return

      this.data = {...this.data, ...payload}

      this.data['Warehouse'] = {
        id: payload['Warehouse ID'],
        label: payload['Warehouse']
      }

      this.data['Distributor'] = {
        id: payload['Account ID'],
        label: payload['Account Name']
      }

      await this.updateReceivedFromOptions(payload['Account ID'])

      this.data['Received From'] = this.$refs.refReceivedFrom.options.find(i => i.id == payload['Received From ID'])
      this.data['Send To'] = this.data['Received From']?.email ?? ''

      this.data['Currency'] = {
        id: payload['Currency ID'],
        label: payload['Currency']
      }

      this.$refs.refPOConfirmed.options = this.$form.yesNo.options

      this.data['PO Confirmed'] = this.$refs.refPOConfirmed.options.find(i => i.id == payload['PO Confirmed ID'])

      if (payload['Account ID']) {
        let response = await this.$api.get(`accounts/${payload['Account ID']}`)

        this.data.Distributor = {...this.data.Distributor, ...response}
      }

      this.customButtons[0].visible = false

      this.customButtons[1].visible = !(
        this.$form.mode(this) === this.$constants.FORM_MODE.CREATE ||
        (this.data && this.data['Status'] !== 'Incoming order')
      )
      this.customButtons[2].visible = this.data['Pick List ID'] > 0
      this.customButtons[3].visible = this.data['Invoice Number'] > 0

      this.isReadOnlyRecord = !(this.model.actions.Edit && this.model.actions.Edit.validator(this.data))

      this.updateDistributorsOptions()
    },
    async onFieldChange (e) {
      if (e.id === 'discount') this.updateComputedFields()

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW)
        this.$refs['form'].save('view').then(() => this.$refs['form'].initialize())

      if (e.id === 'warehouse') this.updateDistributorsOptions()

      if (e.id === 'distributor') this.updateReceivedFromOptions(e.value.id)

      if (e.id === 'received_from') {
        this.data['Send To'] = e.value.email
      }
    },

    async updateReceivedFromOptions (distributorId) {
      console.log('updateReceivedFromOptions.distributorId:', distributorId)
      let response = await dictionaryServices.fetchDistributorContacts(distributorId)

      if (!response?.data) return

      this.$refs.refReceivedFrom.options = response.data.map(i => ({
        id: i['ID'],
        label: i['Contact Name'],
        email: i['Email']
      }))
    },
    async updateDistributorsOptions () {
      let response = await this.entity('Distributor').optionsService()
      //console.log('updateDistributorsOptions.response:', response)
      this.$refs.refDistributor.options = response
        .filter(i => i.bill_country === 'United States')
        .map(i => ({
          id: i.id,
          label: i.name
        }))

      console.log('this.data:', this.data)

      if (this.data['Warehouse']?.label) {
        if (this.data['Warehouse'].label.includes('4241')) {
          this.$refs.refDistributor.options = response
            .filter(i => ['United States', 'Japan', 'Canada', 'Taiwan'].includes(i.bill_country))
            .map(i => ({id: i.id, label: i.name}))
        } else {
          this.$refs.refDistributor.options = response.map(i => ({
            id: i.id,
            label: i.name
          }))
        }
      }
    },
    onItemsChange (payload) {
      console.log('onItemsChange.payload', payload)

      this.data['items'] = payload

      this.$forceUpdate()

      this.updateComputedFields()

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW) {
        this.$refs['form'].save('view').then(() => {
          this.$refs['form'].initialize()
          this.$refs['incoming-order-items'].getData()
        })
      }
    },
    onItemsTableLoad (payload) {
      // console.log(        'onItemsTableLoad.payload',        payload      )

      //this.data.items = payload

      this.data['items'] = payload

      this.$forceUpdate()
    },
    updateComputedFields () {
      let orderItems = this.$refs['incoming-order-items'].getDataSet()

      let itemsTotal = orderItems.reduce((a, b) => +a + +b['Line Cost'], 0)

      this.data['Total'] = this.$helpers.round(itemsTotal - this.data['Discount'], 2)
    },
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    }
  },
  watch: {}
}
</script>

<style scoped>
@media print {
  #contentToConvert {
    width: 100%; /* Adjust width as needed */
    font-size: 16px; /* Increase font size if necessary */
    /* Add any other styles for print here */
  }
}
.btn {
  width: 10em;
  margin-right: 0.5em;
}
</style>
