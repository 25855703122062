<template>
  <div class="animated fadeIn">
    <b-card>
      <b-alert show variant="danger" v-if="reportingAlertText"
        >{{ reportingAlertText }}
      </b-alert>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Distillation details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.id.id"
                  :value="data.id"
                  :label="controls.id.label"
                  :readonly="controls.id.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="6">
                <inline-select
                  :id="controls.fermentation.id"
                  :value="data.fermentation"
                  :label="controls.fermentation.label"
                  :readonly="controls.fermentation.readonly"
                  :options="controls.fermentation.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.distillation_container.id"
                  :value="data.distillation_container"
                  :label="controls.distillation_container.label"
                  :readonly="controls.distillation_container.readonly"
                  :options="controls.distillation_container.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.distillation_status.id"
                  :value="data.distillation_status"
                  :label="controls.distillation_status.label"
                  :readonly="controls.distillation_status.readonly"
                  :options="controls.distillation_status.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_used.id"
                  :value="data.amount_used"
                  :label="controls.amount_used.label"
                  :readonly="controls.amount_used.readonly"
                  :mode="mode"
                  :required="controls.amount_used.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.start_date.id"
                  :value-single="data.start_date"
                  :label="controls.start_date.label"
                  :readonly="controls.start_date.readonly"
                  :mode="mode"
                  :required="controls.start_date.required"
                  @changed="updateDateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-time-picker
                  :id="controls.start_time.id"
                  :value="data.start_time"
                  :label="'Start time'"
                  :readonly="controls.start_time.readonly"
                  :required="controls.start_time.required"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-time-picker
                  :id="controls.end_time.id"
                  :value="data.end_time"
                  :label="'End time'"
                  :readonly="controls.end_time.readonly"
                  :required="controls.end_time.required"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.distillation_psi.id"
                  :value="data.distillation_psi"
                  :label="controls.distillation_psi.label"
                  :readonly="controls.distillation_psi.readonly"
                  :mode="mode"
                  :required="controls.distillation_psi.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.condensation_temp.id"
                  :value="data.condensation_temp"
                  :label="controls.condensation_temp.label"
                  :readonly="controls.condensation_temp.readonly"
                  :mode="mode"
                  :required="controls.condensation_temp.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.dephlegmator_1.id"
                  :value="data.dephlegmator_1"
                  :label="controls.dephlegmator_1.label"
                  :readonly="controls.dephlegmator_1.readonly"
                  :mode="mode"
                  :required="controls.dephlegmator_1.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.dephlegmator_2.id"
                  :value="data.dephlegmator_2"
                  :label="controls.dephlegmator_2.label"
                  :readonly="controls.dephlegmator_2.readonly"
                  :mode="mode"
                  :required="controls.dephlegmator_2.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.heads_amount.id"
                  :value="data.heads_amount"
                  :label="controls.heads_amount.label"
                  :readonly="controls.heads_amount.readonly"
                  :mode="mode"
                  :required="controls.heads_amount.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.hearts_amount.id"
                  :value="data.hearts_amount"
                  :label="controls.hearts_amount.label"
                  :readonly="controls.hearts_amount.readonly"
                  :mode="mode"
                  :required="controls.hearts_amount.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.hearts_amount_remaining.id"
                  :value="data.hearts_amount_remaining"
                  :label="controls.hearts_amount_remaining.label"
                  :readonly="controls.hearts_amount_remaining.readonly"
                  :mode="mode"
                  :required="controls.hearts_amount_remaining.required"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.tails_amount.id"
                  :value="data.tails_amount"
                  :label="controls.tails_amount.label"
                  :readonly="controls.tails_amount.readonly"
                  :mode="mode"
                  :required="controls.tails_amount.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.tails_amount_remaining.id"
                  :value="data.tails_amount_remaining"
                  :label="controls.tails_amount_remaining.label"
                  :readonly="controls.tails_amount_remaining.readonly"
                  :mode="mode"
                  :required="controls.tails_amount_remaining.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.distillation_inc.id"
                  :value="data.distillation_inc"
                  :label="controls.distillation_inc.label"
                  :readonly="controls.distillation_inc.readonly"
                  :mode="mode"
                  :required="controls.distillation_inc.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.distillation_flowrate.id"
                  :value="data.distillation_flowrate"
                  :label="controls.distillation_flowrate.label"
                  :readonly="controls.distillation_flowrate.readonly"
                  :mode="mode"
                  :required="controls.distillation_flowrate.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.heads_percentage.id"
                  :value="data.heads_percentage"
                  :label="controls.heads_percentage.label"
                  :readonly="controls.heads_percentage.readonly"
                  :mode="mode"
                  :required="controls.heads_percentage.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.hearts_percentage.id"
                  :value="data.hearts_percentage"
                  :label="controls.hearts_percentage.label"
                  :readonly="controls.hearts_percentage.readonly"
                  :mode="mode"
                  :required="controls.hearts_percentage.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.tails_percentage.id"
                  :value="data.tails_percentage"
                  :label="controls.tails_percentage.label"
                  :readonly="controls.tails_percentage.readonly"
                  :mode="mode"
                  :required="controls.tails_percentage.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.heads_pg.id"
                  :value="data.heads_pg"
                  :label="controls.heads_pg.label"
                  :readonly="controls.heads_pg.readonly"
                  :mode="mode"
                  :required="controls.heads_pg.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.hearts_pg.id"
                  :value="data.hearts_pg"
                  :label="controls.hearts_pg.label"
                  :readonly="controls.hearts_pg.readonly"
                  :mode="mode"
                  :required="controls.hearts_pg.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.tails_pg.id"
                  :value="data.tails_pg"
                  :label="controls.tails_pg.label"
                  :readonly="controls.tails_pg.readonly"
                  :mode="mode"
                  :required="controls.tails_pg.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created_by.id"
                  :value="data.created_by"
                  :label="controls.created_by.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified_by.id"
                  :value="data.modified_by"
                  :label="controls.modified_by.label"
                  :readonly="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
          </form>
          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Distillation' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Distillation submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Distillation submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.mashings.title"
          v-if="mode !== $constants.FORM_MODE.CREATE && data.fermentation.id"
        >
          <mashing-table
            :mash-id="data.fermentation.id"
            :show-add-button="false"
            @loaded="onMashingsTableLoad"
          ></mashing-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.fermentations.title"
          v-if="mode !== $constants.FORM_MODE.CREATE && data.fermentation.id"
        >
          <fermentation-table
            :mash-id="data.fermentation.id"
            :show-add-button="false"
            @loaded="onFermentationsTableLoad"
          ></fermentation-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.storages.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
          :active="moveHearts > 0 || moveTails > 0"
        >
          <distillation-storage-containers-table
            ref="dist-storages-table"
            :distillation-id="this.id"
            @loaded="onDistillationStorageContainersLoad"
          ></distillation-storage-containers-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.report.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
          @click="onDistillationReportTabClick"
        >
          <distillation-report
            ref="distillation-report"
            :distillation-id="this.id"
          >
          </distillation-report>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import DistillationStorageContainersTable from '@/views/Production/Distillation/DistillationStorageContainersTable.vue'
import MashingTable from '@/views/Production/Mashing/MashingTable.vue'
import FermentationTable from '@/views/Production/Fermentation/FermentationTable.vue'
import DistillationReport from '@/views/Production/Distillation/DistillationAutoLogReport.vue'

import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'DistillationSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    fermentationId: {
      type: String,
      default: ''
    },
    moveHearts: {
      type: Number,
      default: 0
    },
    moveTails: {
      type: Number,
      default: 0
    }
  },
  components: {
    DistillationStorageContainersTable,
    MashingTable,
    FermentationTable,
    DistillationReport
  },
  data: function () {
    return {
      dataset: {
        name: 'distillation'
      },
      reportingAlertText: undefined,
      baseApiUrl: 'distillation',
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          mashings: {
            title: 'Mashes'
          },
          fermentations: {
            title: 'Fermentations'
          },
          report: {
            title: 'Report'
          },
          storages: {
            title: 'Storages'
          }
        },
        id: {
          id: 'input:ID',
          label: 'Distillation ID',
          changed: false,
          readonly: true
        },
        fermentation: {
          id: 'select:fermentation',
          label: 'Fermentation',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        distillation_container: {
          id: 'select:distillation_container',
          label: 'Distillation container',
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        distillation_status: {
          id: 'select:distillation_status',
          label: 'Distillation status',
          options: [
            { id: '0', label: 'First time' },
            { id: '1', label: 'Redistill' }
          ],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        start_date: {
          id: 'datepicker:start_date',
          label: 'Start Date',
          changed: false,
          readonly: false,
          required: true
        },
        start_time: {
          id: 'timepicker:start_time',
          label: 'Start time',
          changed: false,
          readonly: false,
          required: true
        },
        end_time: {
          id: 'timepicker:end_time',
          label: 'End time',
          changed: false,
          readonly: false
        },
        amount_used: {
          id: 'input:amount_used',
          label: 'Amount Used',
          changed: false,
          readonly: false,
          required: true
        },
        distillation_psi: {
          id: 'input:distillation_psi',
          label: 'PSI',
          changed: false,
          readonly: false,
          required: true
        },
        condensation_temp: {
          id: 'input:condensation_temp',
          label: 'Condensation Temp',
          changed: false,
          readonly: false,
          required: true
        },
        dephlegmator_1: {
          id: 'input:dephlegmator_1',
          label: 'Dephlegmator 1',
          changed: false,
          readonly: false,
          required: true
        },
        dephlegmator_2: {
          id: 'input:dephlegmator_2',
          label: 'Dephlegmator 2',
          changed: false,
          readonly: false,
          required: true
        },
        heads_amount: {
          id: 'input:heads_amount',
          label: 'Heads Amount',
          changed: false,
          readonly: false,
          required: true
        },
        hearts_amount: {
          id: 'input:hearts_amount',
          label: 'Hearts Amount',
          changed: false,
          readonly: false,
          required: false
        },
        tails_amount: {
          id: 'input:tails_amount',
          label: 'Tails Amount',
          changed: false,
          readonly: false,
          required: false
        },
        hearts_amount_remaining: {
          id: 'input:hearts_amount_remaining',
          label: 'Hearts Amount Remaining',
          changed: false,
          readonly: false,
          required: false
        },
        tails_amount_remaining: {
          id: 'input:tails_amount_remaining',
          label: 'Tails Amount Remaining',
          changed: false,
          readonly: false,
          required: false
        },
        distillation_inc: {
          id: 'input:distillation_inc',
          label: 'Distillation Inc',
          changed: false,
          readonly: false,
          required: true
        },
        distillation_flowrate: {
          id: 'input:distillation_flowrate',
          label: 'Distillation Flowrate',
          changed: false,
          readonly: false,
          required: true
        },
        heads_percentage: {
          id: 'input:heads_percentage',
          label: 'Heads %',
          changed: false,
          readonly: false,
          required: true
        },
        hearts_percentage: {
          id: 'input:hearts_percentage',
          label: 'Hearts %',
          changed: false,
          readonly: false,
          required: false
        },
        tails_percentage: {
          id: 'input:tails_percentage',
          label: 'Tails %',
          changed: false,
          readonly: false,
          required: false
        },
        heads_pg: {
          id: 'input:heads_pg',
          label: 'Heads Pg',
          changed: false,
          readonly: true,
          required: false
        },
        hearts_pg: {
          id: 'input:hearts_pg',
          label: 'Hearts Pg',
          changed: false,
          readonly: true,
          required: false
        },
        tails_pg: {
          id: 'input:tails_pg',
          label: 'Tails Pg',
          changed: false,
          readonly: true,
          required: false
        },

        created: {
          id: 'input:created',
          label: 'Created',
          changed: false,
          readonly: true
        },
        modified: {
          id: 'input:modified',
          label: 'Modified',
          changed: false,
          readonly: true
        },
        created_by: {
          id: 'input:created_by',
          label: 'Created By',
          changed: false,
          readonly: true
        },
        modified_by: {
          id: 'input:modified_by',
          label: 'Modified By',
          changed: false,
          readonly: true
        }
      },
      data: {
        id: '',
        fermentation: {
          id: '',
          label: ''
        },
        distillation_container: {
          id: '',
          label: ''
        },
        start_date: '',
        start_time: '',
        end_time: '',
        amount_used: '',
        distillation_psi: '',
        condensation_temp: '',
        dephlegmator_1: '',
        dephlegmator_2: '',

        heads_amount: '',
        hearts_amount: '',
        tails_amount: '',
        hearts_amount_remaining: '',
        tails_amount_remaining: '',
        distillation_inc: '',
        distillation_flowrate: '',
        heads_percentage: '',
        hearts_percentage: '',
        tails_percentage: '',
        heads_pg: '',
        hearts_pg: '',
        tails_pg: '',
        created_by: '',
        modified_by: '',
        created: '',
        modified: ''
      },
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dblclick: undefined,
      serverFilter: undefined
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    async initialize () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE

        this.setDefaults()
      }

      if (this.action === 'edit') this.mode = this.$constants.FORM_MODE.EDIT

      if (this.action === 'view') this.mode = this.$constants.FORM_MODE.VIEW

      this.data.id = this.id

      this.loadDictionaries()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.controls.amount_used.readonly = true

        this.loadData(this.id)
      }
    },
    setDefaults () {
      this.data.distillation_psi = 45
      this.data.condensation_temp = 78.5
      this.data.dephlegmator_1 = 98
      this.data.dephlegmator_2 = 79.5
      this.data.distillation_inc = 15
      this.data.distillation_flowrate = 50
      this.data.start_date = this.$nowClient.format('YYYY-MM-DD')
    },
    loadDictionaries () {
      let self = this

      const distillation_containers = () => {
        self.$api.get('dictionaries/distillation-containers').then(response => {
          self.controls.distillation_container.options = response.map(u => ({
            id: u.id,
            label: u.name
          }))
        })
      }
      const fermentations = async () => {
        let response = await self.$api.post('dictionaries/fermentations', {
          not_empty: self.mode === this.$constants.FORM_MODE.CREATE
        })
        self.controls.fermentation.options = response.map(u => ({
          id: u.id,
          label: u.name,
          amount_remaining: u.amount_remaining
        }))

        if (self.fermentationId) {
          self.data.fermentation = self.controls.fermentation.options.find(
            u => u.id === self.fermentationId
          )
        }
      }

      Promise.all([fermentations(), distillation_containers()])
    },
    edit () {},

    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let method = ''

      let url = this.baseApiUrl

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = this.baseApiUrl
      }
      if (
        this.mode === this.$constants.FORM_MODE.EDIT ||
        this.mode === this.$constants.FORM_MODE.VIEW
      ) {
        method = 'put'
        url = `${this.baseApiUrl}/${this.data.id}`
      }

      this.isLoading = true

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (field, value) {
      //this.$form.updateField(this.baseApiUrl, this, field, value, mode);

      this.data[field] = value

      //this.updateCalculations();
      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save('view').then(() => this.loadData(this.id))
    },
    onKeyupField (e) {
      const CONST_PG = 10 * 5 * 3.7854118

      if (e.id === 'input:heads_percentage')
        this.data.heads_pg = (this.data.heads_amount * e.val) / CONST_PG
      if (e.id === 'input:heads_amount')
        this.data.heads_pg = (e.val * this.data.heads_percentage) / CONST_PG
      if (e.id === 'input:hearts_percentage')
        this.data.hearts_pg = (this.data.hearts_amount * e.val) / CONST_PG
      if (e.id === 'input:hearts_amount')
        this.data.hearts_pg = (e.val * this.data.hearts_percentage) / CONST_PG
      if (e.id === 'input:tails_percentage')
        this.data.tails_pg = (this.data.tails_amount * e.val) / CONST_PG
      if (e.id === 'input:tails_amount')
        this.data.tails_pg = (e.val * this.data.tails_percentage) / CONST_PG
    },
    updateSelect (id, value) {
      // this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);
      this.data[id] = value

      if (id == 'fermentation') this.data.amount_used = +value.amount_remaining

      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save('view').then(() => this.loadData(this.id))
    },
    async loadData (id) {
      let self = this

      this.isLoading = true

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`)

        this.isLoading = false

        if (self.$_.isEmpty(response)) return

        this.data.id = response['ID']

        this.data.fermentation = {
          id: response['Fermentation ID'],
          label: response['Fermentation Name']
        }
        this.data.distillation_container = {
          id: response['Container ID'],
          label: response['Container Number']
        }

        let status = this.controls.distillation_status.options.find(
          i => i.id === response['Redistillation Status']
        )
        this.data.distillation_status = status
        this.data.amount_used = response['Amount Used']
        this.data.start_date = response['Start Date']
        this.data.start_time = response['Start Time']
        this.data.end_time = response['End Time']

        this.data.distillation_psi = response['PSI']
        this.data.condensation_temp = response['Condensation Temp']
        this.data.dephlegmator_1 = response['Dephlegmator 1']
        this.data.dephlegmator_2 = response['Dephlegmator 2']

        this.data.heads_amount = response['Heads Amount']
        this.data.hearts_amount = response['Hearts Amount']
        this.data.tails_amount = response['Tails Amount']
        this.data.hearts_amount_remaining = response['Hearts Amount Remaining']
        this.data.tails_amount_remaining = response['Tails Amount Remaining']
        this.data.distillation_inc = response['Distillation Inc']
        this.data.distillation_flowrate = response['Distillation Flowrate']
        this.data.heads_percentage = response['Heads %']
        this.data.hearts_percentage = response['Hearts %']
        this.data.tails_percentage = response['Tails %']
        this.data.heads_pg = response['Heads Pg']
        this.data.hearts_pg = response['Hearts Pg']
        this.data.tails_pg = response['Tails Pg']

        this.data.created = response['Created']
        this.data.modified = response['Modified']

        this.data.created_by = response['Created By']
        this.data.modified_by = response['Modified By']

        //reporting alert after 5th of month (Date Range: previous month!)
        let processYM = +moment(this.data.start_date).format('YYYYMM'),
          currentYM = +this.$nowClient.format('YYYYMM'),
          currentDay = +this.$nowClient.format('DD')

        this.reportingAlertText =
          currentYM > processYM && currentDay > 5
            ? `Current distillation already been reported!`
            : undefined
      } catch (error) {
        console.log(error)

        this.$form.makeToastError(error)
      } finally {
        this.isLoading = false
      }
    },
    onDistillationStorageContainersLoad (count) {
      if (count > 0) this.controls.tabs.storages.title = `Storages (${count})`

      if (this.moveHearts > 0 || this.moveTails > 0) {
        this.insertRowDistStorageTable()
      }
    },
    onMashingsTableLoad (count) {
      if (count > 0) this.controls.tabs.mashings.title = `Mashings (${count})`
    },
    onFermentationsTableLoad (count) {
      if (count > 0)
        this.controls.tabs.fermentations.title = `Fermentations (${count})`
    },
    onDistillationReportTabClick () {
      this.$refs['distillation-report'].drawCharts()
    },
    insertRowDistStorageTable () {
      let data

      if (this.moveHearts > 0)
        data = {
          Distillate: 'Hearts',
          Amount: this.moveHearts
        }

      if (this.moveTails > 0)
        data = {
          Distillate: 'Tails',
          Amount: this.moveTails
        }

      this.$refs['dist-storages-table'].addItem(data)
    }
  }
}
</script>

<style scoped></style>
