<template>
  <div class="table-container">
    <div class="controls-wrapper">
      <div class="action-buttons" v-if="data.length">
        <button class="btn btn-outline-dark save-btn" :disabled="!modifiedRows.size" @click="saveChanges">
          <span class="icon">💾</span>
          Save
        </button>
        <button class="btn btn-outline-dark cancel-btn" :disabled="!modifiedRows.size" @click="cancelEdits">
          <span class="icon">✖</span>
          Cancel
        </button>
      </div>
    </div>
    <table class="table table-sm table-hover table-bordered table-striped">
      <thead class="thead-light">
        <tr>
          <th
            v-for="header in headers"
            :key="header"
            scope="col"
            class="text-nowrap"
            :data-readonly="config.columns[header]?.readOnly"
            :style="{
              width: columnWidths[header] || 'auto',
              backgroundColor: selectedHeader === header ? 'rgba(25, 118, 210, 0.04)' : ''
            }"
          >
            <div class="header-content" @click="selectHeader(header)">
              {{ getColumnTitle(header) }}
            </div>
            <div class="resize-handle" v-drag-resize="header"></div>
          </th>
        </tr>

        <tr>
          <th v-for="header in headers" :key="`filter-${header}`" class="th-filter-input ">
            <input type="text" class="filter-input" v-model="filters[header]" @input="handleFilter" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in sortedData" :key="rowIndex">
          <td
            v-for="header in headers"
            :key="header"
            @click="startEdit(row, header, rowIndex)"
            :data-readonly="config.columns[header]?.readOnly"
            :class="{
              'edit-cell': isEditing(rowIndex, header),
              'edited-cell': isDirtyCell(rowIndex, header)
            }"
          >
            <input
              v-if="isEditing(rowIndex, header)"
              v-model="editValue"
              class="edit-input"
              @blur="finishEdit"
              @keyup.enter="finishEdit"
              @keyup.esc="cancelEdits"
              v-focus
            />
            <span v-else :class="{'edited-value': isDirtyCell(rowIndex, header)}">
              {{ row[header] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-if="!data.length" class="text-center text-muted my-3">
      No data available
    </p>
  </div>
</template>

<script>
import DataTableScript from './DataTableScript'

export default DataTableScript
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #999 #f1f1f1;
  position: relative;

  user-select: none;
}

.table-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.table {
  margin-bottom: 0;
  width: 100%;
  table-layout: fixed;
}

.table th,
.table td {
  padding: 0.5rem 0.3rem; /* Reduced from 0.25rem 0.5rem */
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  overflow: visible;
}

.table th .header-content {
  padding-top: 0.5rem;
  white-space: normal;
  word-wrap: break-word;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table .thead-light th {
  background-color: #fff;
}
.header-content {
  position: relative;
  padding-right: 10px;
  cursor: pointer;
  user-select: none;
}

.sort-icon {
  margin-left: 4px;
  font-size: 0.8em;
}

.thead-light th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  position: relative;
}
.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;
}

.resize-handle.visible {
  opacity: 1;
  cursor: col-resize;
  border-left: 1px dashed #a5a5a5;
  border-right: 1px dashed #a5a5a5;
}

.table-hover tbody tr:hover {
  --bs-table-hover-bg: transparent;
}

.table td {
  transition: border 0.2s;
}

.table td[data-readonly='true'] {
  color: #a5a5a5;
}
.table td:not([data-readonly='true']):hover {
  outline: 1px dashed #a5a5a5;
  outline-offset: -1px;
}

.table td[data-readonly='true'] {
  color: #a5a5a5;
}

.th-filter-input {
  padding: 0.2em !important;
  position: relative;
  z-index: 90;
}

th .filter-input {
  padding-left: 0.2em;

  border: 1px solid #dee2e6;
  width: 100%;
  height: 2.5em;
}

.table td[data-selected-column='true'],
.table th[data-selected-column='true'] {
  background-color: rgba(25, 118, 210, 0.04);
}
.filter-input:focus {
  outline: none;
  border-color: #aeaeb1;
}

.edit-cell {
  padding: 0 !important;
}

.edit-input {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.3rem;
  border: none;
  background-color: rgb(255, 248, 211);
  outline: 2px solid #474747;
}

.edited-cell {
  background-color: rgb(255, 248, 211);
}

.edited-value {
  font-weight: 500;
}

.cancel-btn {
  margin-left: 0.5rem;
}
.action-buttons {
  display: flex;
  margin-bottom: 0.4rem;

  gap: 0.5rem;
}

.btn-outline-dark {
  justify-content: center;
  border: 1px solid #474747;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 10em;
  border-radius: 0.2rem;
  font-size: 1em;
  background-color: white;
  transition: all 0.2s;
  cursor: pointer;
}

.btn-outline-dark:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #ccc;
  background-color: #f5f5f5;
}

.icon {
  font-size: 1rem;
}

.controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}

.header-content {
  position: relative;
  padding-right: 5px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
  z-index: 100;
}

.header-content[data-resizing='true'] {
  cursor: col-resize !important;
}

.sort-menu {
  position: absolute;
  right: 8px; /* 8px */
  top: 50%;
  transform: translateY(-50%);
}
</style>
