<template>
  <div class="simple-image-container" :style="containerStyle" @click="triggerUpload">
    <div v-if="!imageUrl" class="simple-image-placeholder">
      <i class="bi bi-image fs-1 mb-2"></i>
      <span>Click to upload</span>
    </div>

    <img v-if="imageUrl" :src="`${STORAGE_URL}${imageUrl}`" class="simple-image" alt="Product" />
    <button v-if="imageUrl" class="delete-button" @click.stop="removeImage">
      <i class="bi bi-x" style="font-size: 20px; color: #000;"></i>
    </button>
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner-border text-primary"></div>
    </div>
    <input ref="fileInput" type="file" class="d-none" @change="handleFileChange" :accept="acceptTypes.join(',')" />
  </div>
</template>

<script>
export default {
  name: 'SimpleImage',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    imageUrl: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 300
    },
    maxHeight: {
      type: Number,
      default: 300
    },
    acceptTypes: {
      type: Array,
      default: () => ['image/jpg', 'image/jpeg', 'image/png']
    },
    maxFileSize: {
      type: Number,
      default: 5 * 1024 * 1024
    },
    uploadFunction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      STORAGE_URL: process.env.VUE_APP_API_URL + '/storage'
    }
  },
  computed: {
    containerStyle () {
      return {
        width: `${this.maxWidth}px`,
        height: `${this.maxHeight}px`
      }
    }
  },
  methods: {
    triggerUpload () {
      this.$refs.fileInput.click()
    },
    handleFileChange (event) {
      console.log('this.handleFileChange', event.target.files[0])
      const file = event.target.files[0]
      if (!file) return

      if (!this.acceptTypes.includes(file.type)) {
        this.$emit('invalid', {id: this.id, reason: 'type'})
        return
      }

      if (file.size > this.maxFileSize) {
        this.$emit('invalid', {id: this.id, reason: 'size'})
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('control-id', this.id)

      this.isLoading = true
      this.$emit('upload-start', {id: this.id, formData})

      this.uploadFunction(formData)
        .then(response => {
          this.$emit('upload-success', {id: this.id, response})
        })
        .catch(error => {
          this.$emit('upload-error', {id: this.id, error})
        })
        .finally(() => {
          this.isLoading = false
          event.target.value = ''
        })
    },
    async removeImage () {
      const confirm = await this.$form.showConfirmation('Are you sure you want to delete this image?')
      if (!confirm) return

      console.log('this.removeImage', this.id)
      this.$emit('remove', {id: this.id})
    }
  }
}
</script>

<style scoped>
.simple-image-container {
  border: 2px dashed #dee2e6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.simple-image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  color: #6c757d;
}

.simple-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.delete-button:hover {
  background: #f8f9fa;
}

.delete-x {
  color: #000;
  font-size: 20px;
  margin-right: 2px;
  color: #dc3545;
}

.delete-button:hover .delete-x {
  color: #c82333;
}
</style>
