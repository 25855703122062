var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "overview-tab",
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Order details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.order_no.id,
      "value": _vm.id,
      "label": _vm.controls.order_no.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.order_date.id,
      "value-single": _vm.data.order_date,
      "label": _vm.controls.order_date.label,
      "readonly": _vm.controls.order_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.supplier.id,
      "value": _vm.data.supplier,
      "label": _vm.controls.supplier.label,
      "readonly": _vm.controls.supplier.readonly,
      "options": _vm.controls.supplier.options,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": true
    },
    on: {
      "link-clicked": _vm.onSupplierLinkClicked,
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.shipping_cost.id,
      "value": _vm.data.shipping_cost,
      "label": _vm.controls.shipping_cost.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.closed.id,
      "value": _vm.data.closed,
      "label": _vm.controls.closed.label,
      "readonly": true
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('purchases-table', {
    ref: "purchases-table",
    attrs: {
      "order-id": this.id,
      "mode": _vm.mode
    }
  })], 1)], 1)], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.mashings.title
    }
  }, [_c('mashing-table', {
    attrs: {
      "purchaseOrderId": this.id,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onMashingsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.barrel_filling.title
    }
  }, [_c('barrel-filling-table', {
    attrs: {
      "purchaseOrderId": this.id
    },
    on: {
      "loaded": _vm.onBarrelFillingTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$constants.MODULES.InventoryOrders.id,
      "entity-id": _vm.id,
      "show-category": true
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.supplier_files.title
    }
  }, [_vm.data.supplier.id ? _c('files-container', {
    attrs: {
      "module-id": _vm.$constants.MODULES.Accounts.id,
      "entity-id": _vm.data.supplier.id,
      "show-category": true
    },
    on: {
      "loaded": _vm.onSupplierFileContainerLoad
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Purchase orders'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Order submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Order submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1), _c('b-modal', {
    ref: "supplier-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.supplierPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openSupplierInNewWindow(_vm.supplierPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeSupplierPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountSubmissionForm', {
    attrs: {
      "id": _vm.supplierPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }