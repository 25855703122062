<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <b-button-group v-if="showAddButton">
          <b-button variant="outline-dark" title="Add mash" @click="addItem()">
            <font-awesome-icon icon="plus" /> Create
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter"></div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-dark btn-sm"
            @click="openPreviewModal(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>
          <button
            v-if="+props.row['Amount Mashed Remaining'] > 0"
            class="btn btn-warning btn-sm"
            @click="createFermentation(props.row.ID)"
            title="New fermentation"
          >
            <strong>F</strong>
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
            title="View"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>

    <b-modal
      ref="mash-preview-modal"
      centered
      scrollable
      size="lg"
      :title="mashPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 70vh">
        <MashSubmissionForm :id="mashPreviewModal.id" action="preview" />
      </b-container>
      <!-- <template #modal-footer="" /> -->

      <hr />
      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openMashInNewWindow(mashPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>

          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closePreviewModal()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'
import MashSubmissionForm from './MashSubmissionForm.vue'

export default {
  props: {
    mashId: {
      type: [Number, String],
      default: ''
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    itemId: {
      type: [Number, String],
      default: ''
    },
    purchaseOrderId: {
      type: [Number, String],
      default: ''
    },
    lotNumber: {
      type: [Number, String],
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  name: 'MashingTable',
  components: {
    TableCustom,
    MashSubmissionForm
  },
  data: function () {
    return {
      dataset: {
        name: 'mashing'
      },
      mashPreviewModal: {
        id: '',
        title: ''
      },

      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          subtitle: '',
          columns: [
            'ID',
            'Base Product',
            'Mash Tank Number',
            'Mash Tank Capacity',
            'Date Started',
            'Amount Mashed',
            'Amount Mashed Remaining',
            'Brix In',
            'Brix Out',
            'Fermentations',
            'Distillations',
            'Notes',
            'Created By',
            'Modified By',
            'Actions'
          ],
          filterable: [
            'ID',
            'Base Product',
            'Mash Tank Number',
            'Mash Tank Capacity',
            'Date Started',
            'Amount Mashed',
            'Amount Mashed Remaining',
            'Brix In',
            'Brix Out',
            'Fermentations',
            'Distillations',
            'Notes',
            'Created By',
            'Modified By'
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {
    //this.getData({ purchase_order_id: 829 })
    console.log('mashingTable.purchaseOrderId', this.purchaseOrderId)

    this.dataTable.options.title = this.title || ''

    if (this.mashId) {
      this.getData({ id: this.mashId })
    } else if (this.itemId) {
      this.getData({ item_id: this.itemId })
    } else if (this.purchaseOrderId) {
      this.getData({ purchase_order_id: this.purchaseOrderId })
    } else if (this.lotNumber) {
      this.getData({ lot_number: this.lotNumber })
    } else {
      this.getData()
    }
  },
  methods: {
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },

    async getData (payload) {
      console.log('getData.payload', payload)
      let self = this

      this.dataTable.isLoading = true

      //  this.dataTable.dataSet = [];

      let response = []

      try {
        response = await this.$api.post('mashing', payload)

        self.dataTable.isLoading = false

        self.dataTable.dataSet = response

        if (response.length === 0) return

        self.$emit('loaded', self.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        self.dataTable.isLoading = false
        self.$form.msgBoxOk('Error occured')
      }
    },
    addItem: function () {
      this.$router.push({
        name: 'Mash submission',
        params: {
          action: 'create'
        }
      })
    },
    createFermentation: function (id) {
      this.$router.push({
        name: 'Fermentation submission',
        params: {
          action: 'create',
          mashId: id
        }
      })
    },

    viewItem: function (id) {
      this.$router.push({
        name: 'Mash submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editItem: function (id) {
      this.$router.push({
        name: 'Mash submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteItem: async function (id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(
        `Mash #${item['Mash Name']} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.$api
        .delete(`mash/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.getData()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.message)
        })
    },
    openPreviewModal (id) {
      this.mashPreviewModal.id = id
      this.mashPreviewModal.title = `Mash #${id}`

      this.$refs['mash-preview-modal'].show()
    },
    closePreviewModal: function () {
      this.$refs['mash-preview-modal'].hide()
    },
    openMashInNewWindow (id) {
      let routeData = this.$router.resolve({
        name: 'Mash submission',
        params: {
          id: id,
          action: 'view'
        }
      })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {}
}
</script>

<style scoped>
/*account preview modal resize */
::v-deep .modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
</style>
