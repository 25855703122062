<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab :title="controls.tabs.product.title" active>
          <form>
            <h5>
              Product details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="6" md="2" sm="2">
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <inline-input
                      :id="controls.product_name.id"
                      :value="data.product_name"
                      :label="controls.product_name.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" md="2" sm="2" class="mt-2">
                    <inline-select
                      :id="controls.category.id"
                      :value="data.category"
                      :label="controls.category.label"
                      :readonly="controls.category.readonly"
                      :options="controls.category.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                  <b-col lg="6" md="2" sm="2">
                    <inline-select
                      :id="controls.base_product.id"
                      :value="data.base_product"
                      :label="controls.base_product.label"
                      :readonly="controls.base_product.readonly"
                      :options="controls.base_product.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" md="2" sm="2">
                    <inline-select
                      :id="controls.bottle_size.id"
                      :value="data.bottle_size"
                      :label="controls.bottle_size.label"
                      :readonly="controls.bottle_size.readonly"
                      :options="controls.bottle_size.options"
                      :allow-empty="false"
                      :multiple="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="data.is_product_group ? true : false"
                    />
                  </b-col>
                  <b-col lg="6" md="2" sm="2">
                    <inline-select
                      :id="controls.case_size.id"
                      :value="data.case_size"
                      :label="controls.case_size.label"
                      :readonly="controls.case_size.readonly"
                      :options="controls.case_size.options"
                      :taggable="true"
                      :allow-empty="false"
                      :multiple="false"
                      :mode="mode"
                      @changed="updateSelect"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" md="2" sm="2">
                    <inline-input
                      :id="controls.abv.id"
                      :value="data.abv"
                      :label="controls.abv.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="6" md="2" sm="2">
                    <inline-input
                      :id="controls.gs1_upc.id"
                      :value="data.gs1_upc"
                      :label="controls.gs1_upc.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <SimpleImage
                  :id="controls.front_label_image.id"
                  :imageUrl="data.front_label_image"
                  :uploadFunction="uploadFrontLabel"
                  @remove="deleteFrontLabel"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <SimpleImage
                  :id="controls.back_label_image.id"
                  :imageUrl="data.back_label_image"
                  :uploadFunction="uploadBackLabel"
                  @remove="deleteBackLabel"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="2" sm="2">
                <inline-switch
                  :id="controls.is_product_group.id"
                  :value="data.is_product_group"
                  :readonly="false"
                  :label="controls.is_product_group.label"
                  @changed="onIsProductGroupChange"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-select
                  :id="controls.group_products.id"
                  :value="data.group_products"
                  :label="controls.group_products.label"
                  :options="controls.group_products.options"
                  :allow-empty="false"
                  :multiple="true"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="data.is_product_group ? true : false"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.bottle_height.id"
                  :value="data.bottle_height"
                  :label="controls.bottle_height.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.bottle_width.id"
                  :value="data.bottle_width"
                  :label="controls.bottle_width.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.bottle_weight.id"
                  :value="data.bottle_weight"
                  :label="controls.bottle_weight.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.cases_per_layer.id"
                  :value="data.cases_per_layer"
                  :label="controls.cases_per_layer.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.layers_per_pallet.id"
                  :value="data.layers_per_pallet"
                  :label="controls.layers_per_pallet.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.case_dimensions.id"
                  :value="data.case_dimensions"
                  :label="controls.case_dimensions.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.case_height.id"
                  :value="data.case_height"
                  :label="controls.case_height.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.case_weight.id"
                  :value="data.case_weight"
                  :label="controls.case_weight.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.case_price.id"
                  :value="data.case_price"
                  :label="controls.case_price.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.bottle_price.id"
                  :value="data.bottle_price"
                  :label="controls.bottle_price.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.scc_code.id"
                  :value="data.scc_code"
                  :label="controls.scc_code.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.nabca_code.id"
                  :value="data.nabca_code"
                  :label="controls.nabca_code.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.ttb_id.id"
                  :value="data.ttb_id"
                  :label="controls.ttb_id.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.schedule_b.id"
                  :value="data.schedule_b"
                  :label="controls.schedule_b.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.cn_codes_eu.id"
                  :value="data.cn_codes_eu"
                  :label="controls.cn_codes_eu.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.product_type.id"
                  :value="data.product_type"
                  :label="controls.product_type.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.productcode.id"
                  :value="data.productcode"
                  :label="controls.productcode.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.serialno.id"
                  :value="data.serialno"
                  :label="controls.serialno.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-date-picker
                  :id="controls.start_date.id"
                  :value-single="data.start_date"
                  :label="controls.start_date.label"
                  :readonly="controls.start_date.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3" md="6">
                <inline-select
                  :id="controls.discontinued.id"
                  :value="data.discontinued"
                  :label="controls.discontinued.label"
                  :readonly="controls.discontinued.readonly"
                  :options="controls.discontinued.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="false" lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.qty_per_unit.id"
                  :value="data.qty_per_unit"
                  :label="controls.qty_per_unit.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.unit_price.id"
                  :value="data.unit_price"
                  :label="controls.unit_price.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="2" sm="2">
                <inline-date-picker
                  :id="controls.sales_start_date.id"
                  :value-single="data.sales_start_date"
                  :label="controls.sales_start_date.label"
                  :readonly="controls.sales_start_date.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3" md="2" sm="2">
                <inline-date-picker
                  :id="controls.sales_end_date.id"
                  :value-single="data.sales_end_date"
                  :label="controls.sales_end_date.label"
                  :readonly="controls.sales_end_date.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col  v-if="false"  lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.weight.id"
                  :value="data.weight"
                  :label="controls.weight.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col v-if="false"  lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.pack_size.id"
                  :value="data.pack_size"
                  :label="controls.pack_size.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="6" md="2" sm="2">
                <inline-input
                  :id="controls.description.id"
                  :value="data.description"
                  :label="controls.description.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="6" md="2" sm="2">
                <inline-input
                  :id="controls.notes.id"
                  :value="data.notes"
                  :label="controls.notes.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
          </form>
        </b-tab>
        <b-tab :title="controls.tabs.files.title" v-if="mode !== this.$constants.FORM_MODE.CREATE">
          <files-container :module-id="$route.meta.module.id" :entity-id="id" @loaded="onFileContainerLoad" />
        </b-tab>
      </b-tabs>

      <form-submission-actions
        :mode="mode"
        :loading="{
          save: saveInProgress,
          saveAndView: saveAndViewInProgress
        }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="save('tabular').then(response => (response ? $router.push({name: 'Products'}) : false))"
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Product submission',
                  params: {action: 'view', id: response}
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Product submission',
            params: {action: 'edit', id: id}
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

import FormSubmissionActions from '@/components/FormSubmissionActions'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineDatePicker from '@/components/InlineDatePicker'
import productServices from '@/services/SalesAdministrative/products.service'
import SimpleImage from '@/components/SimpleImage'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'ProductSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    FormSubmissionActions,
    SimpleImage
  },
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          files: {
            title: 'Files'
          },
          product: {
            title: 'Product'
          }
        },

        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        front_label_image: {
          id: 'front_label_image',
          label: 'Front Label',
          changed: false,
          readonly: false
        },
        back_label_image: {
          id: 'back_label_image',
          label: 'Back Label',
          changed: false,
          readonly: false
        },
        product_name: {
          id: 'input:product_name',
          label: 'Product Name',
          changed: false,
          readonly: false
        },
        category: {
          id: 'select:category',
          label: 'Category',
          options: [],
          changed: false,
          readonly: false
        },
        base_product: {
          id: 'select:base_product',
          label: 'Base/Finished Product',
          options: [],
          changed: false,
          readonly: false
        },
        is_product_group: {
          id: 'switch:is_product_group',
          label: 'Group of Products',
          value: false,
          changed: false,
          readonly: false
        },
        group_products: {
          id: 'select:group_products',
          label: 'Selected products',
          changed: false,
          readonly: false,
          options: []
        },

        bottle_size: {
          id: 'input:bottle_size',
          label: 'Bottle Size',
          changed: false,
          readonly: false
        },
        abv: {
          id: 'input:abv',
          label: 'ABV',
          changed: false,
          readonly: false
        },
        product_type: {
          id: 'input:product_type',
          label: 'Product Type',
          changed: false,
          readonly: false
        },
        bottle_height: {
          id: 'input:bottle_height',
          label: 'Bottle Height',
          changed: false,
          readonly: false
        },
        bottle_width: {
          id: 'input:bottle_width',
          label: 'Bottle Width',
          changed: false,
          readonly: false
        },
        bottle_weight: {
          id: 'input:bottle_weight',
          label: 'Bottle Weight',
          changed: false,
          readonly: false
        },
        case_size: {
          id: 'input:case_size',
          label: 'Case Size',
          changed: false,
          readonly: false
        },
        cases_per_layer: {
          id: 'input:cases_per_layer',
          label: 'Cases per Layer',
          changed: false,
          readonly: false
        },
        layers_per_pallet: {
          id: 'input:layers_per_pallet',
          label: 'Layers per Pallet',
          changed: false,
          readonly: false
        },
        case_dimensions: {
          id: 'input:case_dimensions',
          label: 'Case Dimensions',
          changed: false,
          readonly: false
        },
        case_height: {
          id: 'input:case_height',
          label: 'Case Height',
          changed: false,
          readonly: false
        },
        case_weight: {
          id: 'input:case_weight',
          label: 'Case Weight',
          changed: false,
          readonly: false
        },
        case_price: {
          id: 'input:case_price',
          label: 'Case Price',
          changed: false,
          readonly: false
        },
        bottle_price: {
          id: 'input:bottle_price',
          label: 'Bottle Price',
          changed: false,
          readonly: false
        },
        gs1_upc: {
          id: 'input:gs1_upc',
          label: 'GS1 UPC',
          changed: false,
          readonly: false
        },
        scc_code: {
          id: 'input:scc_code',
          label: 'SCC Code',
          changed: false,
          readonly: false
        },
        nabca_code: {
          id: 'input:nabca_code',
          label: 'NABCA Code',
          changed: false,
          readonly: false
        },
        ttb_id: {
          id: 'input:ttb_id',
          label: 'TTB ID',
          changed: false,
          readonly: false
        },
        schedule_b: {
          id: 'input:schedule_b',
          label: 'Schedule B',
          changed: false,
          readonly: false
        },
        cn_codes_eu: {
          id: 'input:cn_codes_eu',
          label: 'CN Codes EU',
          changed: false,
          readonly: false
        },
        qty_per_unit: {
          id: 'input:qty_per_unit',
          label: 'Qty per Unit',
          changed: false,
          readonly: false
        },
        unit_price: {
          id: 'input:unit_price',
          label: 'Unit Price',
          changed: false,
          readonly: false
        },
        productcode: {
          id: 'input:productcode',
          label: 'Product Code',
          changed: false,
          readonly: false
        },
        weight: {
          id: 'input:weight',
          label: 'Weight',
          changed: false,
          readonly: false
        },
        pack_size: {
          id: 'input:pack_size',
          label: 'Pack Size',
          changed: false,
          readonly: false
        },
        sales_start_date: {
          id: 'datepicker:sales_start_date',
          label: 'Sales Start Date',
          changed: false,
          readonly: false
        },
        sales_end_date: {
          id: 'datepicker:sales_end_date',
          label: 'Sales End Date',
          changed: false,
          readonly: false
        },
        start_date: {
          id: 'datepicker:start_date',
          label: 'Start Date',
          changed: false,
          readonly: false
        },
        discontinued: {
          id: 'select:discontinued',
          label: 'Discontinued',
          options: this.$form.yesNo.options,
          changed: false,
          readonly: false
        },
        serialno: {
          id: 'input:serialno',
          label: 'Serialno',
          changed: false,
          readonly: false
        },
        description: {
          id: 'input:description',
          label: 'Description',
          changed: false,
          readonly: false
        },
        notes: {
          id: 'input:notes',
          label: 'Notes',
          changed: false,
          readonly: false
        }
      },
      data: {
        id: '',
        front_label_image: '',
        back_label_image: '',
        product_name: '',
        category: {
          id: '',
          label: ''
        },
        base_product: {
          id: '',
          label: ''
        },
        is_product_group: false,
        group_products: [],
        bottle_size: '',
        abv: '',
        product_type: '',
        bottle_height: '',
        bottle_width: '',
        bottle_weight: '',
        case_size: '',
        case_per_layer: '',
        layers_per_pallet: '',
        case_dimensions: '',
        case_height: '',
        case_weight: '',
        case_price: '',
        bottle_price: '',
        gs1_upc: '',
        scc_code: '',
        nabca_code: '',
        ttb_id: '',
        schedule_b: '',
        cn_codes_eu: '',
        qty_per_unit: '',
        unit_price: '',
        productcode: '',
        weight: '',
        pack_size: '',
        sales_start_date: '',
        sales_end_date: '',
        start_date: '',
        discontinued: this.$form.yesNo.no,
        serialno: '',
        description: '',
        notes: ''
      },
      datasetKeys: []
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    initialize: async function () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      this.data.id = this.id

      await this.fetchDropdowns()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id)
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.start_date = moment.utc().format('YYYY-MM-DD')
      }
    },
    async uploadFrontLabel (formData) {
      this.data.front_label_image = await this.uploadLabel(formData, 'front')
    },
    async uploadBackLabel (formData) {
      this.data.back_label_image = await this.uploadLabel(formData, 'back')
    },
    async deleteFrontLabel (e) {
      console.log('deleteFrontLabel', e)
      this.data.front_label_image = ''

      this.updateField('front_label_image', '', this.mode)
    },
    async deleteBackLabel () {
      this.data.back_label_image = ''
      this.updateField('back_label_image', '', this.mode)
    },

    async uploadLabel (formData, labelType) {
      try {
        formData.append('type', labelType)
        formData.append('productId', this.id)

        const response = await this.$api.post('products/labels', formData)

        console.log('uploadLabel.response', response)

        this.$form.makeToastInfo(response.message)
        return response.url
      } catch (error) {
        this.$form.makeToastError(error.message)
        throw error
      }
    },
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },
    async fetchDropdowns () {
      productServices.fetchProducts().then(res => {
        console.log('fetchProducts', res)

        this.controls.group_products.options = res.map(item => {
          return {
            id: item.ID,
            label: item['Product Name Full']
          }
        })
      })

      this.$api.get('products/categories').then(categoriesResponse => {
        console.log('categoriesResponse', categoriesResponse)
        this.controls.category.options = categoriesResponse.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
      })

      this.$api.get('products/finished-products').then(finishedProductsResponse => {
        console.log('finishedProductsResponse', finishedProductsResponse)
        this.controls.base_product.options = finishedProductsResponse.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
      })

      const caseSizesResponse = await this.$api.get('dictionaries/case-sizes')
      console.log('caseSizesResponse', caseSizesResponse)
      this.controls.case_size.options = caseSizesResponse.map(u => ({
        id: u.id,
        label: u.name,
        bottles: u.bottles
      }))

      const bottleSizesResponse = await this.$api.get('dictionaries/bottle-sizes')
      console.log('bottleSizesResponse', bottleSizesResponse)
      this.controls.bottle_size.options = bottleSizesResponse.map(u => ({
        id: u.id,
        label: u.name,
        size: u.size
      }))
    },
    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let method = ''

      let url = 'products'

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = 'products'
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = 'put'
        url = `products/${this.data.id}`
      }

      this.isLoading = true
      console.log('save.data', this.data)
      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (field, value, mode) {
      this.$form.updateField('products', this, field, value, mode)
    },
    updateSelect (id, value) {
      this.$form.updateField('products', this, id, value, this.mode)
    },
    onIsProductGroupChange (id, value) {
      this.data.is_product_group = value
      this.updateField('is_product_group', value, this.mode)
    },
    fetchData: async function (id) {
      let self = this

      this.isLoading = true
      return this.$api
        .get(`products/${id}`)
        .then(response => {
          console.log('product', response)
          this.isLoading = false

          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.front_label_image = response['Front Label']
          self.data.back_label_image = response['Back Label']

          self.data.product_name = response['Product Name']
          self.data.category = {
            id: response['Category'],
            label: response['Category']
          }

          self.data.base_product = {
            id: response['base_product_id'],
            label: response['Base/Finished Product']
          }

          this.data.is_product_group = response['Product Group'] === 'Yes' ? true : false

          this.data.group_products = JSON.parse(response['Group Products'])

          if (response['Bottle Size JSON'] !== '') {
            this.data.bottle_size = response['Bottle Size JSON']
              ? JSON.parse(response['Bottle Size JSON'])
              : {id: '', label: '', size: ''}
          } else {
            //if value exist find it in options to set id
            this.data.bottle_size = this.controls.bottle_size.options.find(i => i.size === response['Bottle Size'])
          }
          //self.data.bottle_size = response['Bottle Size']
          self.data.abv = response['ABV']
          self.data.product_type = response['Product Type']
          self.data.bottle_height = response['Bottle Height']
          self.data.bottle_width = response['Bottle Width']
          self.data.bottle_weight = response['Bottle Weight']
          //self.data.case_size = response['Case Size']

          if (response['Case Size JSON'] !== '') {
            this.data.case_size = response['Case Size JSON']
              ? JSON.parse(response['Case Size JSON'])
              : {id: '', label: '', bottles: ''}
          } else {
            //if value exist find it in options to set id
            this.data.case_size = this.controls.case_size.options.find(i => i.bottles === response['Case Size'])
          }

          self.data.cases_per_layer = response['Cases per Layer']
          self.data.layers_per_pallet = response['Layers per Pallet']
          self.data.case_dimensions = response['Case Dimensions']
          self.data.case_height = response['Case Height']
          self.data.case_weight = response['Case Weight']
          self.data.case_price = response['Case Price']
          self.data.bottle_price = response['Bottle Price']
          self.data.gs1_upc = response['GS1 UPC']
          self.data.scc_code = response['SCC Code']
          self.data.nabca_code = response['NABCA Code']
          self.data.ttb_id = response['TTB ID']
          self.data.schedule_b = response['Schedule B']
          self.data.cn_codes_eu = response['CN Codes EU']
          self.data.qty_per_unit = response['Qty/Unit']
          self.data.unit_price = response['Unit Price']

          self.data.weight = response['Weight']
          self.data.pack_size = response['Pack Size']
          self.data.sales_start_date = response['Sales Start Date']
          self.data.sales_end_date = response['Sales End Date']
          self.data.start_date = response['Start Date']
          //  self.data.discontinued = { id: response['discontinued_id'], label: this.$form.yesNo.find( i => i === response['discontinued_id'])}
          self.data.productcode = response['Product Code']
          self.data.serialno = response['Serial No']
          self.data.description = response['Description']
          self.data.notes = response['Notes']
        })
        .catch(error => {
          this.isLoading = false

          console.log(error)
        })
    }
  },
  watch: {}
}
</script>

<style></style>
