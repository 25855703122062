var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.product.title,
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Product details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.product_name.id,
      "value": _vm.data.product_name,
      "label": _vm.controls.product_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.base_product.id,
      "value": _vm.data.base_product,
      "label": _vm.controls.base_product.label,
      "readonly": _vm.controls.base_product.readonly,
      "options": _vm.controls.base_product.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.bottle_size.id,
      "value": _vm.data.bottle_size,
      "label": _vm.controls.bottle_size.label,
      "readonly": _vm.controls.bottle_size.readonly,
      "options": _vm.controls.bottle_size.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": _vm.data.is_product_group ? true : false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.case_size.id,
      "value": _vm.data.case_size,
      "label": _vm.controls.case_size.label,
      "readonly": _vm.controls.case_size.readonly,
      "options": _vm.controls.case_size.options,
      "taggable": true,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.abv.id,
      "value": _vm.data.abv,
      "label": _vm.controls.abv.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.gs1_upc.id,
      "value": _vm.data.gs1_upc,
      "label": _vm.controls.gs1_upc.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "sm": "3"
    }
  }, [_c('SimpleImage', {
    attrs: {
      "id": _vm.controls.front_label_image.id,
      "imageUrl": _vm.data.front_label_image,
      "uploadFunction": _vm.uploadFrontLabel
    },
    on: {
      "remove": _vm.deleteFrontLabel
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "sm": "3"
    }
  }, [_c('SimpleImage', {
    attrs: {
      "id": _vm.controls.back_label_image.id,
      "imageUrl": _vm.data.back_label_image,
      "uploadFunction": _vm.uploadBackLabel
    },
    on: {
      "remove": _vm.deleteBackLabel
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.is_product_group.id,
      "value": _vm.data.is_product_group,
      "readonly": false,
      "label": _vm.controls.is_product_group.label
    },
    on: {
      "changed": _vm.onIsProductGroupChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.group_products.id,
      "value": _vm.data.group_products,
      "label": _vm.controls.group_products.label,
      "options": _vm.controls.group_products.options,
      "allow-empty": false,
      "multiple": true,
      "mode": _vm.mode,
      "required": _vm.data.is_product_group ? true : false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bottle_height.id,
      "value": _vm.data.bottle_height,
      "label": _vm.controls.bottle_height.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bottle_width.id,
      "value": _vm.data.bottle_width,
      "label": _vm.controls.bottle_width.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bottle_weight.id,
      "value": _vm.data.bottle_weight,
      "label": _vm.controls.bottle_weight.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.cases_per_layer.id,
      "value": _vm.data.cases_per_layer,
      "label": _vm.controls.cases_per_layer.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.layers_per_pallet.id,
      "value": _vm.data.layers_per_pallet,
      "label": _vm.controls.layers_per_pallet.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.case_dimensions.id,
      "value": _vm.data.case_dimensions,
      "label": _vm.controls.case_dimensions.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.case_height.id,
      "value": _vm.data.case_height,
      "label": _vm.controls.case_height.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.case_weight.id,
      "value": _vm.data.case_weight,
      "label": _vm.controls.case_weight.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.case_price.id,
      "value": _vm.data.case_price,
      "label": _vm.controls.case_price.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bottle_price.id,
      "value": _vm.data.bottle_price,
      "label": _vm.controls.bottle_price.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.scc_code.id,
      "value": _vm.data.scc_code,
      "label": _vm.controls.scc_code.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.nabca_code.id,
      "value": _vm.data.nabca_code,
      "label": _vm.controls.nabca_code.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ttb_id.id,
      "value": _vm.data.ttb_id,
      "label": _vm.controls.ttb_id.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.schedule_b.id,
      "value": _vm.data.schedule_b,
      "label": _vm.controls.schedule_b.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.cn_codes_eu.id,
      "value": _vm.data.cn_codes_eu,
      "label": _vm.controls.cn_codes_eu.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.product_type.id,
      "value": _vm.data.product_type,
      "label": _vm.controls.product_type.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.productcode.id,
      "value": _vm.data.productcode,
      "label": _vm.controls.productcode.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.serialno.id,
      "value": _vm.data.serialno,
      "label": _vm.controls.serialno.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.start_date.id,
      "value-single": _vm.data.start_date,
      "label": _vm.controls.start_date.label,
      "readonly": _vm.controls.start_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.discontinued.id,
      "value": _vm.data.discontinued,
      "label": _vm.controls.discontinued.label,
      "readonly": _vm.controls.discontinued.readonly,
      "options": _vm.controls.discontinued.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [false ? _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.qty_per_unit.id,
      "value": _vm.data.qty_per_unit,
      "label": _vm.controls.qty_per_unit.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.unit_price.id,
      "value": _vm.data.unit_price,
      "label": _vm.controls.unit_price.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.sales_start_date.id,
      "value-single": _vm.data.sales_start_date,
      "label": _vm.controls.sales_start_date.label,
      "readonly": _vm.controls.sales_start_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.sales_end_date.id,
      "value-single": _vm.data.sales_end_date,
      "label": _vm.controls.sales_end_date.label,
      "readonly": _vm.controls.sales_end_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1), _c('b-row', [false ? _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.weight.id,
      "value": _vm.data.weight,
      "label": _vm.controls.weight.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1) : _vm._e(), false ? _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.pack_size.id,
      "value": _vm.data.pack_size,
      "label": _vm.controls.pack_size.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.data.description,
      "label": _vm.controls.description.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.data.notes,
      "label": _vm.controls.notes.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1)]), _vm.mode !== this.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e()], 1), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Products'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Product submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Product submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }