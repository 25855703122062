var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app"
  }, [_c('AppHeader', {
    staticClass: "exclude-print",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "navbar-brand-full exclude-print",
    attrs: {
      "src": "/img/brand/logo-koval2.jpg",
      "height": "20",
      "alt": "Koval Logo"
    }
  })]), _c('SidebarToggler', {
    staticClass: "d-md-down-none p-toggle-absolute",
    attrs: {
      "display": "lg",
      "default-open": true
    }
  }), _c('b-navbar', {
    style: !_vm.$isMobile ? 'float:right' : '',
    attrs: {
      "toggleable": "lg"
    }
  }, [_c('SidebarToggler', {
    staticClass: "d-lg-none",
    attrs: {
      "display": "md",
      "mobile": ""
    }
  }), _c('b-navbar-toggle', {
    attrs: {
      "target": "nav-collapse"
    }
  }), _c('b-collapse', {
    attrs: {
      "id": "nav-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', {
    staticClass: "ml-auto p-1"
  }, [_c('HeaderSearchBox'), _c('HeaderDropdownTimeTracker'), _c('HeaderDropdownQuickCreate'), !_vm.$isMobile ? _c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.profile.data.full_name))]) : _vm._e(), _c('DefaultHeaderDropdownAccnt')], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "app-body"
  }, [_c('AppSidebar', {
    attrs: {
      "fixed": ""
    }
  }, [_c('SidebarHeader'), _c('SidebarForm'), _c('SidebarNav', {
    attrs: {
      "nav-items": _vm.menu
    }
  }), _c('SidebarFooter'), _c('SidebarMinimizer')], 1), _c('main', {
    staticClass: "main",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('visited-pages', {
    staticClass: "exclude-print"
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_c('router-view', {
    key: _vm.$route.path
  })], 1)], 1)], 1), _c('TheFooter', [_c('span'), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "color": "#c8ced3"
    }
  }, [_c('span', [_vm._v("Version: 2.0.56 ")]), _c('span', {
    ref: "build_date",
    staticClass: "ml-2"
  }, [_vm._v("Date:  2025-01-24 05:12:37 PM  ")]), _c('span', {
    ref: "build_age",
    staticClass: "ml-2"
  }, [_vm._v(" [ " + _vm._s(_vm.buildAge) + " ]")])]), _c('div', {
    staticClass: "ml-auto"
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }