<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="inventory-list"
              :show-labels="true"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <b-row v-show="dataTable.visible">
          <b-col>
            <inventory-list-table ref="inventory-list" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from '@/components/FilteringPanel'

import InventoryListTable from './InventoryListTable'

export default {
  name: 'InventoryList',
  components: {
    FilteringPanel,
    InventoryListTable
  },
  data: function () {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Inventory item',
            title: 'Inventory item',
            name: 'inventory_item_name',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },

          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Delivery status',
            title: 'Delivery status',
            name: 'inventory_delivery_status',
            trackby: 'id',
            label: 'label',
            options: [
              { id: 0, label: 'Not Delivered' },
              { id: 1, label: 'Delivered' }
            ],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Storage location',
            title: 'Storage location',
            name: 'inventory_storage_location',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Status',
            title: 'Status',
            name: 'inventory_item_status',
            trackby: 'id',
            label: 'label',
            options: [
              { id: 0, label: '' },
              { id: 1, label: 'Empty' },
              { id: 2, label: 'Active' },
              { id: 3, label: 'Full' }
            ],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            title: 'Ordered Amount',
            name: 'inventory_ordered_amount',
            trackby: 'id',
            label: 'label',
            options: [
              {
                id: '0',
                label: '0',
                min: 0,
                max: 0
              },
              {
                id: '1',
                label: ' 0.1 - 1000',
                min: 0.1,
                max: 1000
              },
              {
                id: '2',
                label: '1000.01 - 10000',
                min: 1000.01,
                max: 10000
              },
              {
                id: '3',
                label: '10000.01 - 100000',
                min: 10000.01,
                max: 100000
              }
            ],
            selected: {},
            ///
            dataType: 'number',
            tooltip: 'Ordered Amount',
            multiple: true
          },
          {
            type: 'select',
            title: 'Amount remaining',
            name: 'inventory_amount_remaining',
            trackby: 'id',
            label: 'label',
            options: [
              {
                id: '0',
                label: '< 0',
                min: Number.MIN_VALUE,
                max: -0.0001
              },
              {
                id: '1',
                label: '= 0',
                min: 0,
                max: 0
              },
              {
                id: '2',
                label: '> 0',
                min: 0.0001,
                max: Number.MAX_VALUE
              }
            ],
            selected: {},
            ///
            dataType: 'number',
            tooltip: 'Amount remaining',
            multiple: true
          },
          {
            type: 'select',
            title: 'Amount remaining %',
            name: 'inventory_amount_remaining_percent',
            trackby: 'id',
            label: 'label',
            options: [
              {
                id: '0',
                label: '0',
                min: 0,
                max: 0
              },
              {
                id: '1',
                label: '0.01 - 10',
                min: 0.01,
                max: 10
              },
              {
                id: '2',
                label: '10.01 - 50',
                min: 10.01,
                max: 50
              },
              {
                id: '3',
                label: '50.01 - 100',
                min: 50.01,
                max: 100
              }
            ],
            selected: {},
            ///
            dataType: 'number',
            tooltip: 'Amount remaining %',
            multiple: true
          },
          {
            type: 'daterange',
            dataType: 'datetime',
            title: 'Estimated arrival date',
            tooltip: 'Estimated arrival date',
            defaultRange: 'Last 12 months',
            name: 'inventory_est_arrival_period'
          },
          {
            type: 'daterange',
            dataType: 'datetime',
            title: 'Date received',
            tooltip: 'Date received',
            defaultRange: 'Last 12 months',
            name: 'inventory_received_period'
          },

          {
            type: 'daterange',
            dataType: 'datetime',
            title: 'Order Date',
            tooltip: 'Order period',
            defaultRange: 'Last 12 months',
            name: 'inventory_order_period'
          }
        ]
      },
      dataTable: {
        visible: true
      }
    }
  },
  computed: {},
  mounted () {},
  methods: {
    async loadDictionaries () {
      let self = this

      const items = async () => {
        let response = await self.$api.get('dictionaries/inventory/items')

        self.filteringPanel.filters.find(
          f => f.name === 'inventory_item_name'
        ).options = this.$helpers.getDistinctArray(
          response,
          'ID',
          'Item Name',
          'id',
          'label'
        )
      }

      const storages = async () => {
        let response = await self.$api.get(
          'dictionaries/inventory/storage-location'
        )

        self.filteringPanel.filters.find(
          f => f.name === 'inventory_storage_location'
        ).options = this.$helpers.getDistinctArray(
          response,
          'id',
          'name',
          'id',
          'label'
        )
      }

      Promise.all([items(), storages()]).then(
        () => (self.filteringPanel.loaded = true)
      )
    },
    onFilteringPanelStateChange () {
      this.dataTable.visible =
        !this.$refs.filteringPanel.expressionBuilder.visible
    },
    onFilteringPanelLoad () {
      this.getData()
    },
    onCustomFilterSelected () {
      this.getData()
    },
    filterData: function (e) {
      this.filteringPanel.selected = e
    },
    getData () {
      let fp = this.$refs.filteringPanel

      if (!fp.isSearchAllowed()) return

      this.dataTable.isLoading = true

      this.dataTable.dataSet = []

      let f = fp.selected

      let payload = {
        inventory_item_name: f.inventory_item_name
          ? f.inventory_item_name.map(i => i.label)
          : [],
        inventory_delivery_status: f.inventory_delivery_status
          ? f.inventory_delivery_status.map(i => i.label)
          : [],
        inventory_storage_location: f.inventory_storage_location
          ? f.inventory_storage_location.map(i => i.label)
          : [],
        inventory_item_status: f.inventory_item_status
          ? f.inventory_item_status.map(i => i.label)
          : [],
        inventory_est_arrival_period: f.inventory_est_arrival_period,
        inventory_received_period: f.inventory_received_period,
        inventory_order_period: f.inventory_order_period
      }

      if (f.inventory_ordered_amount && f.inventory_ordered_amount.length > 0) {
        payload.inventory_ordered_amount = {
          min: f.inventory_ordered_amount.reduce(
            (min, p) => (p.min < min ? p.min : min),
            f.inventory_ordered_amount[0].min
          ),
          max: f.inventory_ordered_amount.reduce(
            (max, p) => (p.max > max ? p.max : max),
            f.inventory_ordered_amount[0].max
          )
        }
      }
      if (
        f.inventory_amount_remaining &&
        f.inventory_amount_remaining.length > 0
      ) {
        payload.inventory_amount_remaining = {
          min: f.inventory_amount_remaining.reduce(
            (min, p) => (p.min < min ? p.min : min),
            f.inventory_amount_remaining[0].min
          ),
          max: f.inventory_amount_remaining.reduce(
            (max, p) => (p.max > max ? p.max : max),
            f.inventory_amount_remaining[0].max
          )
        }
      }
      if (
        f.inventory_amount_remaining_percent &&
        f.inventory_amount_remaining_percent.length > 0
      ) {
        payload.inventory_amount_remaining_percent = {
          min: f.inventory_amount_remaining_percent.reduce(
            (min, p) => (p.min < min ? p.min : min),
            f.inventory_amount_remaining_percent[0].min
          ),
          max: f.inventory_amount_remaining_percent.reduce(
            (max, p) => (p.max > max ? p.max : max),
            f.inventory_amount_remaining_percent[0].max
          )
        }
      }

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f['custom-filter'])
        )
      }

      this.$refs['inventory-list'].getData(payload)
    },
    dropdownFilter (_name) {
      return this.filteringPanel.filters.find(n => n.title === _name)
    },
    onOrdersLoad () {}
  },
  watch: {}
}
</script>

<style></style>
