export function formatHeader (header) {
  // If header is all uppercase, it's likely an abbreviation - return as is
  if (header === header.toUpperCase() || /^[A-Z]/.test(header)) {
    return header
  }

  // Split on uppercase letters and underscores, preserving abbreviations
  const parts = header.split(/(?=[A-Z](?![A-Z]))|_/g)

  return parts
    .map((part, i) => {
      // Keep abbreviations intact
      if (part === part.toUpperCase() && part.length > 1) {
        return part
      }
      // Capitalize first word, lowercase others
      return i === 0
        ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        : part.toLowerCase()
    })
    .join(' ')
    .trim()
}
