import {dragResize} from './dragResize'
import {formatHeader} from './formatters'
import {STORAGE_KEY} from './constants'

export default {
  name: 'DataTable',
  components: {},
  directives: {
    dragResize,
    focus: {
      inserted: el => el.focus()
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    config: {
      type: Object,
      default: () => ({
        columns: {}
      })
    },
    baseMinWidth: {
      type: Number,
      default: 30
    }
  },
  data () {
    return {
      filters: {},
      filterTimeout: null,
      columnWidths: {},
      minColumnWidths: {},
      sortBy: null,
      sortDesc: false,
      columnTypes: {},
      isDragging: false,
      editingCell: null, // {row, header}
      editValue: null,
      modifiedRows: new Set(),
      dirtyRows: {},
      originalData: null,
      visibleColumns: [],
      perPage: 10,
      selectedHeader: null,
      hoverHeader: null
    }
  },
  computed: {
    headers () {
      if (!this.data.length) return []
      const allHeaders = Object.keys(this.data[0])
      return this.visibleColumns.length ? this.visibleColumns : allHeaders
    },
    sortedData () {
      const filtered = this.data.filter(row => {
        return Object.entries(this.filters).every(([key, value]) => {
          if (!value) return true
          return String(row[key])
            .toLowerCase()
            .includes(value.toLowerCase())
        })
      })

      if (!this.sortBy) return filtered

      const type = this.columnTypes[this.sortBy]
      return [...filtered].sort((a, b) => {
        const aVal = a[`${this.sortBy}`]
        const bVal = b[`${this.sortBy}`]

        if (type === 'number') {
          return this.sortDesc ? bVal - aVal : aVal - bVal
        }
        if (type === 'date') {
          return this.sortDesc ? new Date(bVal) - new Date(aVal) : new Date(aVal) - new Date(bVal)
        }
        return this.sortDesc ? String(bVal).localeCompare(String(aVal)) : String(aVal).localeCompare(String(bVal))
      })
    }
  },
  watch: {
    data: {
      handler (newData) {
        if (newData?.length) {
          if (!this.originalData) {
            this.originalData = JSON.parse(JSON.stringify(newData))
          }
          this.detectColumnTypes()
          this.initializeColumnWidths()
          this.visibleColumns = Object.keys(newData[0])
        }
        this.$nextTick(this.initScrollSync)
      },
      deep: true
    }
  },
  methods: {
    handleFilter () {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        this.sortBy = null
        this.sortDesc = false
      }, 300)
    },
    getColumnTitle (header) {
      return this.config.columns[header]?.title || formatHeader(header)
    },
    detectColumnTypes () {
      if (!this.data.length) return

      this.headers.forEach(header => {
        const sample = this.data.find(row => row[`${header}`] != null)?.[`${header}`]
        if (!sample) {
          this.columnTypes[header] = 'string'
          return
        }

        if (!isNaN(sample) && typeof sample !== 'boolean') {
          this.columnTypes[header] = 'number'
        } else if (!isNaN(Date.parse(sample))) {
          this.columnTypes[header] = 'date'
        } else {
          this.columnTypes[header] = 'string'
        }
      })
    },
    calculateMinWidth (header) {
      const configMinWidth = this.config.columns[header]?.minWidth
      if (configMinWidth) return Math.max(configMinWidth, this.baseMinWidth)

      if (!this.data.length) return this.baseMinWidth

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '0.875rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'

      const headerText = this.getColumnTitle(header)
      const headerWidth = context.measureText(headerText.split(' ')[0]).width
      const columnWidths = this.data.map(row => {
        const value = row[`${header}`]?.toString() || ''
        return context.measureText(value).width
      })

      const avgWidth = columnWidths.reduce((sum, width) => sum + width, 0) / columnWidths.length
      return Math.max(this.baseMinWidth, Math.max(avgWidth, headerWidth) + 40)
      //return Math.max(this.baseMinWidth, Math.max(avgWidth))
    },

    selectHeader (header) {
      if (this.selectedHeader === header) {
        this.selectedHeader = null
      } else {
        this.selectedHeader = header
      }
    },

    handleSort (direction) {
      if (direction === null) {
        this.sortBy = null
        this.sortDesc = false
      } else {
        this.sortBy = this.selectedHeader
        this.sortDesc = direction === 'desc'
      }
    },
    handleHeaderClick (event, header) {
      if (event.target.classList.contains('resize-handle')) {
        return
      }
      this.toggleSort(header)
    },
    toggleSort (header) {
      if (this.isDragging) return

      if (this.sortBy === header) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = header
        this.sortDesc = false
      }
    },
    initializeColumnWidths () {
      if (!this.data.length) return

      this.headers.forEach(header => {
        this.minColumnWidths[header] = this.calculateMinWidth(header)
        const currentWidth = parseInt(this.columnWidths[header])
        if (!currentWidth || currentWidth < this.minColumnWidths[header]) {
          this.$set(this.columnWidths, header, `${this.minColumnWidths[header]}px`)
        }
      })
    },
    updateColumnWidth (header, width) {
      const numericWidth = parseInt(width)
      const minWidth = this.minColumnWidths[header] || this.baseMinWidth
      this.$set(this.columnWidths, header, `${Math.max(numericWidth, minWidth)}px`)
    },
    saveColumnWidths () {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.columnWidths))
    },
    loadColumnWidths () {
      try {
        const saved = localStorage.getItem(STORAGE_KEY)
        if (saved) {
          const savedWidths = JSON.parse(saved)
          Object.entries(savedWidths).forEach(([header, width]) => {
            this.$set(this.columnWidths, header, width)
          })
        }
      } catch (e) {
        console.error('Failed to load column widths:', e)
      }
    },
    startEdit (row, header, rowIndex) {
      if (this.config.columns[header]?.readOnly) return
      this.editingCell = {row: rowIndex, header}
      this.editValue = row[header]
    },
    isDirtyCell (rowIndex, header) {
      return this.dirtyRows[`${rowIndex}-${header}`]
    },
    finishEdit () {
      if (!this.editingCell) return
      const {row, header} = this.editingCell

      if (this.data[row][header] !== this.editValue) {
        this.$set(this.data[row], header, this.editValue)
        this.modifiedRows.add(row)
        this.$set(this.dirtyRows, `${row}-${header}`, true)
      }
      this.editingCell = null
    },

    saveChanges () {
      const changes = Array.from(this.modifiedRows).map(i => this.data[i])
      this.$emit('save', changes)
      this.modifiedRows.clear()
      this.dirtyRows = {}
    },

    cancelEdits () {
      this.editingCell = null
      this.editValue = null
      this.dirtyRows = {}
      this.modifiedRows.clear()

      if (this.originalData) {
        const restoredData = JSON.parse(JSON.stringify(this.originalData))
        this.$emit('update:data', restoredData)
        this.$set(this, 'data', restoredData)
      }
      this.$emit('cancel')
    },
    isDirty (rowIndex) {
      return this.dirtyRows[rowIndex] === true
    },
    isEditing (rowIndex, header) {
      return this.editingCell && this.editingCell.row === rowIndex && this.editingCell.header === header
    },
    initDragScroll () {
      const container = this.$el
      let isDown = false
      let startX
      let scrollLeft

      container.addEventListener('mousedown', e => {
        if (e.target.closest('.resize-handle') || e.target.closest('.header-content')) return

        isDown = true
        container.style.cursor = 'grabbing'
        startX = e.pageX - container.offsetLeft
        scrollLeft = container.scrollLeft
      })

      container.addEventListener('mouseleave', () => {
        isDown = false
        container.style.cursor = ''
      })

      container.addEventListener('mouseup', () => {
        isDown = false
        container.style.cursor = ''
      })

      container.addEventListener('mousemove', e => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - container.offsetLeft
        const walk = (x - startX) * 2
        container.scrollLeft = scrollLeft - walk
      })
    }
  },
  mounted () {
    this.loadColumnWidths()
    this.initializeColumnWidths()
    this.detectColumnTypes()

    this.initDragScroll()
  }
}
