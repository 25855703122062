var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('inline-select', {
    attrs: {
      "id": _vm.id,
      "value": _vm.localValue,
      "label": _vm.label,
      "readonly": _vm.readonly,
      "options": _vm.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "custom-option-template": true,
      "required": true
    },
    on: {
      "changed": _vm.onChanged
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return _c('div', {}, [props.slotScope.option && props.slotScope.option.id ? _c('span', [_vm._v(" " + _vm._s(props.slotScope.option.label) + " ")]) : _vm._e()]);
      }
    }, {
      key: "option",
      fn: function fn(props) {
        return _c('div', {}, [_c('hr', {
          staticStyle: {
            "margin-top": "0px",
            "padding-top": "0px"
          }
        }), props.slotScope.option.item['GS1 UPC'] ? _c('span', [_c('b', [_vm._v(_vm._s(props.slotScope.option.label))])]) : _c('span', [_vm._v(" " + _vm._s(props.slotScope.option.label) + " ")]), props.slotScope.option.item['GS1 UPC'] ? _c('b-table-simple', {
          staticClass: "table table-striped",
          staticStyle: {
            "margin-top": "0.5em",
            "font-size": "0.9rem"
          },
          attrs: {
            "small": "",
            "bordered": "",
            "responsive": ""
          }
        }, [_c('b-tbody', [_c('tr', [_c('td', [_vm._v(" Product Type: " + _vm._s(props.slotScope.option.item['Product Type']))]), _c('td', [_vm._v(" Base: " + _vm._s(props.slotScope.option.item['Base/Finished Product']) + " ")]), _c('td', [_vm._v(" ABV: " + _vm._s(props.slotScope.option.item['ABV']))]), _c('td', [_vm._v(" UPC: " + _vm._s(props.slotScope.option.item['GS1 UPC']) + " ")])]), _c('tr', [_c('td', [_vm._v(" Bottle Size: " + _vm._s(props.slotScope.option.item['Bottle Size']) + "ML ")]), _c('td', [_vm._v(" Bottle Dimensions: " + _vm._s(props.slotScope.option.item['Bottle Height']) + "x" + _vm._s(props.slotScope.option.item['Bottle Width']) + " ")]), _c('td', [_vm._v(" Bottle Weight: " + _vm._s(props.slotScope.option.item['Bottle Weight']))]), _c('td', [_vm._v("Cases per Layer: " + _vm._s(props.slotScope.option.item['Cases per Layer']))])]), _c('tr', [_c('td', [_vm._v(" Case Size:" + _vm._s(props.slotScope.option.item['Case Size']) + " ")]), _c('td', [_vm._v(" Case Dimensions: " + _vm._s(props.slotScope.option.item['Case Height']) + " x " + _vm._s(props.slotScope.option.item['Case Dimensions']) + " ")]), _c('td', [_vm._v(" Case Weight: " + _vm._s(props.slotScope.option.item['Case Weight']) + " ")]), _c('td', [_vm._v("Layers per Pallet: " + _vm._s(props.slotScope.option.item['Layers per Pallet']))])])])], 1) : _vm._e()], 1);
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }