<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <FilteringPanelWrapper
              :model="model"
              :filters="filters"
              @get-data="onFilteringPanelGetData"
            ></FilteringPanelWrapper>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TableWrapper2
              :ref="model.name"
              :model="model"
              :parent-id="id"
              actions-mode="inline"
              :columns="tableColumns"
              :read-only="false"
              :filter-by-column="false"
              @inserted="onTableInsert"
              @updated="onTableChange"
            >
            </TableWrapper2>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10em;"> </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import models from '@/models'
import FilteringPanelWrapper from '@/components/DataView/FilteringPanelWrapper'
import TableWrapper2 from '@/components/DataView2/TableWrapper2'

console.log('models', models)
export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: 'Products2',
  components: {FilteringPanelWrapper, TableWrapper2},
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.salesAdministrative.products,
      filters: ['Product Name', 'Category'],
      tableColumns: [
        'ID',
        'Product Name',
        'Category',
        'Notes',
        'Base Product',
        'Bottle Size',
        'ABV',
        'Product Type',
        'Bottle Height',
        'Bottle Width',
        'Bottle Weight',
        'Case Size',
        'Cases per Layer',
        'Layers per Pallet',
        'Case Dimensions',
        'Case Height',
        'Case Weight',
        'Case Price',
        'Bottle Price',
        'GS1 UPC',
        'SCC Code',
        'NABCA Code',
        'TTB ID',
        'Schedule B',
        'CN Codes EU'
      ],
      data: {}
    }
  },
  created () {},
  mounted () {
    //console.log('this.model', this.model)
  },
  methods: {
    onTableInsert () {},
    onTableChange () {},
    async onFilteringPanelGetData (payload) {
      await this.$refs[this.model.name].getData(payload)
      // this.$refs[this.model.name].setOrder("Date");
    }
  },
  watch: {}
}
</script>

<style scoped>
/*
  ::v-deep .flex-row {
    width: 100%;
  }*/
::v-deep .w-short {
  width: fit-content;
}
::v-deep .w-long {
  width: fit-content;
  min-width: 3em !important;
}
::v-deep .editable-cell {
  min-width: 3em !important;
  width: fit-content;
}
::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
