import productServices from '@/services/SalesAdministrative/products.service'
import availableProductsModel from '../Warehouse/available-products.model'

export default {
  products: {
    name: 'products',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },
      {
        name: 'Product Name',
        type: 'date',
        readonly: false,

        required: true
      },

      {
        name: 'Category',
        type: 'dropdown',
        readonly: false,
        optionsService: productServices.fetchProductsCategories,
        required: true
      },
      {
        name: 'Description',
        type: 'string',
        readonly: false,
        allowEmpty: false,
        required: true
      },
      {
        name: 'Notes',
        type: 'string',
        readonly: false
      },
      {
        name: 'Base Product',
        type: 'string',
        readonly: false
      },
      {
        name: 'Bottle Size',
        type: 'string',
        readonly: false
      },
      {
        name: 'ABV',
        type: 'string',
        readonly: false
      },
      {
        name: 'Product Type',
        type: 'string',
        readonly: false
      },
      {
        name: 'Bottle Height',
        type: 'string',
        readonly: false
      },
      {
        name: 'Bottle Width',
        type: 'string',
        readonly: false
      },
      {
        name: 'Bottle Weight',
        type: 'string',
        readonly: false
      },
      {
        name: 'Case Size',
        type: 'string',
        readonly: false
      },
      {
        name: 'Cases per Layer',
        type: 'string',
        readonly: false
      },
      {
        name: 'Layers per Pallet',
        type: 'string',
        readonly: false
      },
      {
        name: 'Case Dimensions',
        type: 'string',
        readonly: false
      },
      {
        name: 'Case Height',
        type: 'string',
        readonly: false
      },
      {
        name: 'Case Weight',
        type: 'string',
        readonly: false
      },
      {
        name: 'Case Price',
        type: 'string',
        readonly: false
      },
      {
        name: 'Bottle Price',
        type: 'string',
        readonly: false
      },
      {
        name: 'GS1 UPC',
        type: 'string',
        readonly: false
      },
      {
        name: 'SCC Code',
        type: 'string',
        readonly: false
      },
      {
        name: 'NABCA Code',
        type: 'string',
        readonly: false
      },
      {
        name: 'TTB ID',
        type: 'string',
        readonly: false
      },
      {
        name: 'Schedule B',
        type: 'string',
        readonly: false
      },
      {
        name: 'CN Codes EU',
        type: 'string',
        readonly: false
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: productServices.fetchProducts,
      saveRecord: productServices.saveProducts,
      deleteRecord: productServices.deleteProduct
    },
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
}
