import {ifAuthenticated} from './helpers'
import {MODULES} from '@/shared/constants'

const Barrels = () => import('@/views/PosAndSamples/Barrels')
const BarrelSubmissionForm = () => import('@/views/PosAndSamples/SampleBarrelSubmissionForm')

const SampleRequests = () => import('@/views/PosAndSamples/SampleRequests')
const SampleRequestForm = () => import('@/views/PosAndSamples/SampleRequestForm')
const SampleStatistics = () => import('@/views/PosAndSamples/SampleStatistics')

export default {
  path: '',
  name: 'Pos and Samples',
  meta: {icon: 'icon-star'},
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/pos-and-samples/sample-requests',
      name: 'Sample requests',
      meta: {module: MODULES.Samples, icon: 'icon-list'},
      beforeEnter: ifAuthenticated,
      component: SampleRequests
    },
    {
      path: '/pos-and-samples/sample-request/:action/:id?',
      name: 'Sample request submission',
      component: SampleRequestForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Samples, hideFromMenu: true}
    },
    {
      path: '/pos-and-samples/statistics',
      name: 'Samples statistics',
      component: SampleStatistics,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Samples, icon: 'icon-list'}
    },

    {
      path: '/pos-and-samples/barrels',
      name: 'Barrels',
      component: Barrels,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Barrels, icon: 'icon-list'}
    },
    {
      path: '/pos-and-samples/barrel/:action/:id?',
      name: 'Barrel submission',
      component: BarrelSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Barrels, hideFromMenu: true}
    }
  ]
}
