<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :parent-id="orderId"
      actions-mode="inline"
      :model="model"
      :columns="columns"
      :read-only="readOnly"
      :editable-columns="editableColumns"
      :cell-classes="cellClasses"
      :custom-actions="customActions"
      @inline-dropdown-change="onInlineDropdownChange"
      @updated="onItemsTableUpdate"
      @inserted="onItemsTableInsert"
      @deleted="onItemsTableDelete"
      @loaded="onItemsTableLoad"
    ></TableWrapper>
  </div>
</template>

<script>
import TableWrapper from '@/components/DataView/TableWrapper'
import models from '@/models'
import productServices from '@/services/SalesAdministrative/products.service'

export default {
  name: 'IncomingOrderItemsTable',
  props: {
    orderId: {
      type: [String, Number],
      default: ''
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {TableWrapper},
  data: function () {
    return {
      data: [],
      model: models.warehouse.incomingOrderItems,
      columns: models.warehouse.incomingOrderItems.entities.map(e => e.name),
      editableColumns: models.warehouse.incomingOrderItems.entities.filter(e => e.readonly == false).map(e => e.name),

      cellClasses: {
        'Bottles Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Bottles Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Bottles Diff'] > 0
          }
        ],
        'Cases Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Cases Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Cases Diff'] > 0
          }
        ]
      },
      customActions: [
        /*
        {
          click: this.onItemsLookupClick,
          title: "Warehouse",
          icon: "search"
        }
        */
      ],
      dictionaries: {
        finishedProducts: [],
        products: []
      }
    }
  },
  computed: {},
  created () {
    this.initialize()
  },
  async mounted () {
    this.$api.get(`/dictionaries/finished-products`).then(response => {
      this.dictionaries.finishedProducts = response
      // console.log('dictionaries.finishedProducts', this.dictionaries.finishedProducts)
    })

    const response = await productServices.fetchProducts()

    this.dictionaries.products = response
    //console.log('dictionaries.products:', response)
  },
  methods: {
    async initialize () {},
    getData () {
      return this.$refs[this.model.name].getData()
    },
    getDataSet () {
      return this.$refs[this.model.name].getDataSet()
    },

    onItemsTableInsert (e) {
      console.log('onItemsTableInsert.e', e.uid)
      let data = this.$refs[this.model.name].getDataSet()
      //console.log('onItemsTableInsert.data', data)

      let insertedProductRecords = data.filter(
        i =>
          i['Finished Product_ID'] == e['Finished Product_ID'] &&
          i['Bottle Size_ID'] == e['Bottle Size_ID'] &&
          i['Cost per Case'] == e['Cost per Case'] &&
          i['Organic Status_ID'] == e['Organic Status_ID']
      )

      console.log('onItemsTableInsert.insertedProductRecords', insertedProductRecords)

      if (insertedProductRecords.length == 2) {
        //remove inserted
        data = data.filter(i => i.ID !== e.uid)
        //add
        insertedProductRecords[0]
      }

      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    onItemsTableDelete () {
      this.$emit('changed', this.getDataSet())
    },
    onItemsTableUpdate () {
      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    async updateComputedColumns () {
      let data = this.$refs[this.model.name].getDataSet()

      //console.log('this.dictionaries.products', this.dictionaries.products)

      for (let row of data) {
        let productId = row['Product_ID']

        let product = this.dictionaries.products.find(p => p.ID == productId)

        console.log('updateComputedColumns.product:', product)

        const bottlesPerCase = product ? product['Case Size'] : 6

        row['Line Cost'] = this.$helpers.round((row['Cost per Case'] * row['Bottles']) / bottlesPerCase, 4)

        console.log("row['Bottles'] / bottlesPerCase", row['Bottles'], bottlesPerCase)
        row['Cases'] = this.$helpers.round(row['Bottles'] / bottlesPerCase, 2)
      }

      this.$refs[this.model.name].updateDataSet(data)
    },

    onInlineDropdownChange (e) {
      console.log('onInlineDropdownChange.e', e)

      let row = e.row

      if (e.column === 'Product' && e.value?.item?.base_product_id) {
        // row['storage_location_id'] = e.value.id

        //find product
        let finishedProduct = this.dictionaries.finishedProducts.find(i => i.id === e.value.item.base_product_id)
        console.log('finishedProduct:', finishedProduct)

        console.log('row', row)

        row['Finished Product_ID'] = finishedProduct.id
        row['Finished Product'] = finishedProduct.name
        row['Bottle Size_ID'] = e.value.item['Bottle Size JSON']
          ? JSON.parse(e.value.item['Bottle Size JSON']).id
          : null
        row['Bottle Size'] = e.value.item['Bottle Size JSON'] ? JSON.parse(e.value.item['Bottle Size JSON']).size : null
        row['Case Size'] = e.value.item['Case Size JSON'] ? JSON.parse(e.value.item['Case Size JSON']).bottles : null
        row['Case Size_ID'] = e.value.item['Case Size JSON'] ? JSON.parse(e.value.item['Case Size JSON']).id : null
        row['Cases per Pallet'] = e.value.item['Cases per Layer'] * e.value.item['Layers per Pallet']
      }

      //  Vue.set(this.dataTable.dataSet, rowIndex, row)

      //this.updateComputedColumns()

      //this.$emit('changed', this.getDataSet())
    },
    onItemsTableLoad (e) {
      this.$emit('loaded', e)
      // this.updateComputedColumns()
      let items = this.$refs[this.model.name].getDataSet()

      console.log('onItemsTableLoad', e, items)

      this.$emit('loaded', items)
    }
  }
}
</script>

<style scoped>
::v-deep .table-responsive {
  overflow: visible !important;
}
::v-deep table {
  overflow: visible !important;
}
</style>
