import { ifAuthenticated } from './helpers'
import { MODULES } from '@/shared/constants'

const Items = () => import('@/views/Inventory/Items.vue')
const ItemSubmission = () => import('@/views/Inventory/ItemSubmissionForm')
const InventoryDetails = () => import('@/views/Inventory/InventoryDetailsForm')
const Orders = () => import('@/views/Inventory/Orders.vue')
const OrderSubmission = () => import('@/views/Inventory/OrderSubmissionForm')
const Purchases = () => import('@/views/Inventory/Purchases.vue')
const InventoryList = () => import('@/views/Inventory/InventoryList.vue')
const Inventory = () => import('@/views/Inventory/Inventory.vue')

export default {
  path: '',
  name: 'Purchasing and inventory',
  meta: { icon: 'icon-star' },
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/inventory/orders',
      name: 'Purchase orders',
      component: Orders,
      meta: { module: MODULES.InventoryOrders, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/inventory/order/:action/:id?',
      name: 'Order submission',
      component: OrderSubmission,
      props: true,
      meta: { module: MODULES.InventoryOrders, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/inventory/purchases',
      name: 'Purchases',
      component: Purchases,
      meta: { module: MODULES.Inventory, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },

    {
      path: '/inventory',
      name: 'Inventory',
      component: Inventory,
      meta: { module: MODULES.Inventory, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/inventory/list',
      name: 'Inventory list',
      component: InventoryList,
      meta: { module: MODULES.Inventory, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '',
      name: 'ERP settings',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      meta: { module: MODULES.Inventory, icon: 'icon-settings' },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '/inventory/items',
          name: 'Inventory items',
          component: Items,
          meta: { module: MODULES.InventoryItems, icon: 'icon-list' },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/inventory/item/:action/:id?',
          name: 'Inventory item submission',
          component: ItemSubmission,
          props: true,
          meta: { module: MODULES.InventoryItems, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/inventory/inventory-details/:lot_number',
          name: 'Inventory details',
          component: InventoryDetails,
          props: true,
          meta: { module: MODULES.InventoryItems, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        }
      ]
    }
  ]
}
