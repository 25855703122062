var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_vm.reportingAlertText ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.reportingAlertText) + " ")]) : _vm._e(), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.overview.title,
      "active": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Bottling details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.alcohol_finishing.id,
      "value": _vm.data.alcohol_finishing,
      "label": _vm.controls.alcohol_finishing.label,
      "readonly": _vm.controls.alcohol_finishing.readonly,
      "options": _vm.controls.alcohol_finishing.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.bulk_bottling.id,
      "value": _vm.data.bulk_bottling,
      "readonly": _vm.controls.bulk_bottling.readonly,
      "label": _vm.controls.bulk_bottling.label
    },
    on: {
      "changed": _vm.onBulkBottlingChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.batch_number.id,
      "value": _vm.data.batch_number,
      "label": _vm.controls.batch_number.label,
      "readonly": _vm.controls.batch_number.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.batch_number.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.total_amount.id,
      "value": _vm.data.total_amount,
      "label": _vm.controls.total_amount.label,
      "readonly": _vm.controls.total_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.total_amount.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select-product', {
    attrs: {
      "id": _vm.controls.product.id,
      "value": _vm.data.product,
      "label": _vm.controls.product.label,
      "readonly": _vm.controls.product.readonly,
      "options": _vm.controls.product.options,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.alcohol_percentage.id,
      "value": _vm.data.alcohol_percentage,
      "label": _vm.controls.alcohol_percentage.label,
      "readonly": _vm.controls.alcohol_percentage.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.alcohol_percentage.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [!_vm.data.bulk_bottling ? _c('inline-select', {
    attrs: {
      "id": _vm.controls.bottle_size.id,
      "value": _vm.data.bottle_size,
      "label": _vm.controls.bottle_size.label,
      "readonly": _vm.controls.bottle_size.readonly,
      "options": _vm.controls.bottle_size.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  }) : _vm._e(), _vm.data.bulk_bottling ? _c('inline-input', {
    attrs: {
      "id": _vm.controls.bulk_size.id,
      "value": _vm.data.bulk_size,
      "label": _vm.controls.bulk_size.label,
      "readonly": _vm.controls.bulk_size.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.bulk_size.required
    },
    on: {
      "changed": _vm.updateField
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_bottles.id,
      "value": _vm.data.amount_bottles,
      "label": _vm.data.bulk_bottling ? 'Containers' : 'Bottles',
      "readonly": _vm.controls.amount_bottles.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_bottles.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [!_vm.data.bulk_bottling ? _c('inline-input', {
    attrs: {
      "id": _vm.controls.bottles_remaining.id,
      "label": _vm.controls.bottles_remaining.label,
      "value": _vm.data.bottles_remaining,
      "readonly": _vm.controls.bottles_remaining.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.bottles_remaining.required
    },
    on: {
      "changed": _vm.updateField
    }
  }) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [!_vm.data.bulk_bottling ? _c('inline-select', {
    attrs: {
      "id": _vm.controls.case_size.id,
      "value": _vm.data.case_size,
      "label": _vm.controls.case_size.label,
      "readonly": _vm.controls.case_size.readonly,
      "options": _vm.controls.case_size.options,
      "taggable": true,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_cases.id,
      "value": _vm.data.amount_cases,
      "label": _vm.controls.amount_cases.label,
      "readonly": _vm.controls.amount_cases.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_cases.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_bottled_pg.id,
      "value": _vm.data.amount_bottled_pg,
      "label": _vm.controls.amount_bottled_pg.label,
      "readonly": _vm.controls.amount_bottled_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_bottled_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_loss_liters.id,
      "value": _vm.data.amount_loss_liters,
      "label": _vm.controls.amount_loss_liters.label,
      "readonly": _vm.controls.amount_loss_liters.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_loss_liters.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_loss_pg.id,
      "value": _vm.data.amount_loss_pg,
      "label": _vm.controls.amount_loss_pg.label,
      "readonly": _vm.controls.amount_loss_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_loss_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_bottled_wg.id,
      "value": _vm.data.amount_bottled_wg,
      "label": _vm.controls.amount_bottled_wg.label,
      "readonly": _vm.controls.amount_bottled_wg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_bottled_wg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.warehouse.id,
      "value": _vm.data.warehouse,
      "label": _vm.controls.warehouse.label,
      "readonly": _vm.controls.warehouse.readonly,
      "options": _vm.controls.warehouse.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.bottle_date.id,
      "value-single": _vm.data.bottle_date,
      "label": _vm.controls.bottle_date.label,
      "readonly": _vm.controls.bottle_date.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.bottle_date.required
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.organic_status.id,
      "value": _vm.data.organic_status,
      "label": _vm.controls.organic_status.label,
      "readonly": _vm.controls.organic_status.readonly,
      "options": _vm.controls.organic_status.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created_by.id,
      "value": _vm.data.created_by,
      "label": _vm.controls.created_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_by.id,
      "value": _vm.data.modified_by,
      "label": _vm.controls.modified_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1)], 1), _c('hr')], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Bottling'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Bottling submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Bottling submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.data.alcohol_finishing.id ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.alc_fin.title
    }
  }, [_c('alcohol-finishing-table', {
    ref: "alcohol-finishing-table",
    attrs: {
      "finished-product-number": _vm.data.alcohol_finishing.id
    },
    on: {
      "loaded": _vm.onAlcFinItemsLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.picklists.title
    }
  }, [_c('PickListsTable', {
    attrs: {
      "bottling-number": _vm.id
    },
    on: {
      "loaded": _vm.onPickListsLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.invoices.title
    }
  }, [_c('WarehouseInvoicesTable', {
    attrs: {
      "bottling-number": _vm.id
    },
    on: {
      "loaded": _vm.onWarehouseInvoicesLoad
    }
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }