var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('DetailsFormWrapper', {
    ref: "form",
    attrs: {
      "id": _vm.id,
      "model": _vm.model,
      "data": _vm.data,
      "custom-buttons": _vm.customButtons,
      "header": "Incoming order"
    },
    on: {
      "custom-click": function customClick(name) {
        _this[name]();
      },
      "loaded": _vm.onFormLoad
    }
  }, [_vm._t("content", function () {
    return [_c('b-tabs', {
      attrs: {
        "content-class": "mt-3",
        "justified": ""
      }
    }, [_c('b-tab', {
      attrs: {
        "title": "Overview",
        "active": ""
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('ID')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['ID'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'ID', $$v);
        },
        expression: "data['ID']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('PO Number')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['PO Number'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'PO Number', $$v);
        },
        expression: "data['PO Number']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Warehouse')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Warehouse'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Warehouse', $$v);
        },
        expression: "data['Warehouse']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      ref: "refDistributor",
      attrs: {
        "properties": _vm.entity('Distributor')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Distributor'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Distributor', $$v);
        },
        expression: "data['Distributor']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('PO Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['PO Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'PO Date', $$v);
        },
        expression: "data['PO Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('Est Finish Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Est Finish Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Est Finish Date', $$v);
        },
        expression: "data['Est Finish Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      ref: "refReceivedFrom",
      attrs: {
        "properties": _vm.entity('Received From')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Received From'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Received From', $$v);
        },
        expression: "data['Received From']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Currency')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Currency'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Currency', $$v);
        },
        expression: "data['Currency']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Status')
      },
      model: {
        value: _vm.data['Status'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Status', $$v);
        },
        expression: "data['Status']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      ref: "refPOConfirmed",
      attrs: {
        "properties": _vm.entity('PO Confirmed')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['PO Confirmed'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'PO Confirmed', $$v);
        },
        expression: "data['PO Confirmed']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('Confirmation Sent Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Confirmation Sent Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Confirmation Sent Date', $$v);
        },
        expression: "data['Confirmation Sent Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Pick List ID')
      },
      model: {
        value: _vm.data['Pick List ID'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Pick List ID', $$v);
        },
        expression: "data['Pick List ID']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Invoice Number')
      },
      model: {
        value: _vm.data['Invoice Number'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Invoice Number', $$v);
        },
        expression: "data['Invoice Number']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "12",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Notes')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Notes'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Notes', $$v);
        },
        expression: "data['Notes']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Created By')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Created By'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Created By', $$v);
        },
        expression: "data['Created By']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Created')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Created'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Created', $$v);
        },
        expression: "data['Created']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Modified By')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Modified By'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Modified By', $$v);
        },
        expression: "data['Modified By']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Modified')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Modified'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Modified', $$v);
        },
        expression: "data['Modified']"
      }
    })], 1)], 1), _c('hr'), _c('incoming-order-items-table', {
      ref: "incoming-order-items",
      attrs: {
        "order-id": _vm.id,
        "form-data": _vm.data,
        "read-only": _vm.isReadOnlyRecord
      },
      on: {
        "changed": _vm.onItemsChange,
        "loaded": _vm.onItemsTableLoad
      }
    }), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "sm": "6"
      }
    }, [_c('div', {
      key: "gpp-".concat(_vm.data['items'].length)
    }, _vm._l(_vm.groupedProducts, function (group, productId) {
      return _c('div', {
        key: productId,
        staticClass: "product-group mb-4"
      }, [_c('b-table-simple', {
        staticClass: "table table-striped",
        attrs: {
          "small": "",
          "bordered": "",
          "responsive": ""
        }
      }, [_c('b-thead', [_c('b-tr', {
        staticClass: "bg-light"
      }, [_c('b-th', {
        staticClass: "text-left",
        attrs: {
          "colspan": "3"
        }
      }, [_vm._v(_vm._s(group.productName) + " ( " + _vm._s(group.pallets.length) + " pallets, " + _vm._s(group.pallets.reduce(function (sum, p) {
        return sum + parseFloat(p.weight);
      }, 0).toFixed(2)) + " lbs, " + _vm._s(group.pallets.reduce(function (sum, p) {
        return sum + p.fullCases;
      }, 0)) + " cases, " + _vm._s(group.item.Bottles) + " bottles total)")])], 1)], 1), _c('b-tbody', _vm._l(group.pallets, function (pallet) {
        return _c('b-tr', {
          key: pallet.palletNumber
        }, [_c('b-td', [_vm._v(_vm._s(pallet.palletNumber))]), _c('b-td', [_vm._v(_vm._s(pallet.weight) + " lbs")]), _c('b-td', [_vm._v(" [" + _vm._s(pallet.layerInfo) + ", " + _vm._s(pallet.fullCases) + " cases" + _vm._s(pallet.extraBottles ? ' + ' + pallet.extraBottles + ' bottles' : '') + ", " + _vm._s(pallet.totalBottles) + " bottles total] ")])], 1);
      }), 1)], 1)], 1);
    }), 0)]), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Discount')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Discount'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Discount', $$v);
        },
        expression: "data['Discount']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Total')
      },
      model: {
        value: _vm.data['Total'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Total', $$v);
        },
        expression: "data['Total']"
      }
    })], 1)], 1)], 1), _vm.id ? _c('b-tab', {
      attrs: {
        "title": "Confirmation letter"
      }
    }, [_c('b-row', {
      staticClass: "justify-content-start"
    }, [_c('b-col', {
      staticClass: "text-left",
      attrs: {
        "cols": "auto"
      }
    }, [_c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "outline-dark"
      },
      on: {
        "click": _vm.downloadCL
      }
    }, [_vm.loading.save ? _c('b-spinner', {
      attrs: {
        "small": "",
        "type": "grow"
      }
    }) : _vm._e(), !_vm.loading.save ? _c('font-awesome-icon', {
      attrs: {
        "icon": "download"
      }
    }) : _vm._e(), _vm._v(" Download ")], 1)], 1), _c('b-col', {
      staticClass: "text-left",
      attrs: {
        "cols": "auto"
      }
    }, [_c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "outline-dark"
      },
      on: {
        "click": _vm.sendCL
      }
    }, [_vm.loading.send ? _c('b-spinner', {
      attrs: {
        "small": "",
        "type": "grow"
      }
    }) : _vm._e(), !_vm.loading.send ? _c('font-awesome-icon', {
      attrs: {
        "icon": "paper-plane"
      }
    }) : _vm._e(), _vm._v(" Send ")], 1)], 1), _c('b-col', {
      staticClass: "text-left d-flex align-items-center",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" Send to: ")]), _c('b-col', {
      staticClass: "text-left d-flex align-items-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('b-form-input', {
      model: {
        value: _vm.data['Send To'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Send To', $$v);
        },
        expression: "data['Send To']"
      }
    })], 1)], 1), _c('ConvirmationLetterEditor', {
      attrs: {
        "data": _vm.data
      },
      on: {
        "update:data": function updateData($event) {
          _vm.data = $event;
        }
      }
    })], 1) : _vm._e(), _vm.id ? _c('b-tab', {
      attrs: {
        "title": _vm.controls.tabs.files.title
      }
    }, [_c('files-container', {
      attrs: {
        "module-id": _vm.$route.meta.module.id,
        "entity-id": _vm.id
      },
      on: {
        "loaded": _vm.onFileContainerLoad
      }
    })], 1) : _vm._e()], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }