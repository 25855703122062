var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "ct_inventory_items_list",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "inline-update": _vm.onInlineUpdate,
      "inline-dropdown-change": _vm.onInlineDropdownChange
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItemModal(props);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          class: ['btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleUsage(props.row['HID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "tasks"
          }
        })], 1), _c('button', {
          class: ['btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleLotDetails(props.row['Lot Number']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "arrow-up"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('div', {
          staticStyle: {
            "padding": "1em"
          }
        }, [_vm._v(" Mashes related to Lot #" + _vm._s(props.row['Lot Number']) + " "), _c('mashing-table', {
          attrs: {
            "lotNumber": props.row['Lot Number'],
            "show-add-button": false
          }
        }), _vm._v(" Barrels fillings related to Lot #" + _vm._s(props.row['Lot Number']) + " "), _c('barrel-filling-table', {
          attrs: {
            "lotNumber": props.row['Lot Number'],
            "show-add-button": false
          }
        })], 1)]);
      }
    }])
  }), _c('inventory-item-modal', {
    ref: "inventoryItemModal"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }