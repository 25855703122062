import warehouse from './Warehouse/warehouse.model'
import trackTime from './TrackTime/track-time.model'
import reimbursements from './Reimbursements/mileage-rate.model'
import distributor from './Distributor/distributor.model'
import userManagement from './UserManagement/user-management.model'
import salesAdministrative from './SalesAdministrative/products.model'

export default {
  warehouse,
  userManagement,
  trackTime,
  distributor,
  reimbursements,
  salesAdministrative
}
