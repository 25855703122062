var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('DataTable', {
    attrs: {
      "data": _vm.dataTable.dataSet,
      "config": {
        columns: {
          ID: {
            readOnly: true
          },
          'Product Name': {
            minWidth: 150
          },
          'Product Name Full': {
            readOnly: true
          },
          Category: {
            minWidth: 100
          }
        }
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }