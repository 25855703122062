var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [!_vm.moduleId && !_vm.readonly ? _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [_c('b-button-group', [_c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-1",
      modifiers: {
        "collapse-1": true
      }
    }],
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "upload"
    }
  })], 1)])], 1)], 1), _c('b-collapse', {
    staticClass: "mt-3",
    attrs: {
      "id": "collapse-1"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-select', {
    ref: "module",
    attrs: {
      "id": _vm.controls.module.id,
      "loading": _vm.controls.module.isLoading,
      "value": _vm.controls.module.value,
      "label": _vm.controls.module.label,
      "readonly": false,
      "options": _vm.controls.module.options,
      "allow-empty": false,
      "mode": _vm.controls.module.mode,
      "required": true
    },
    on: {
      "changed": _vm.onModuleChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_c('inline-select', {
    ref: "entity",
    attrs: {
      "width": 40,
      "id": _vm.controls.entity.id,
      "loading": _vm.controls.entity.isLoading,
      "value": _vm.controls.entity.value,
      "label": _vm.controls.entity.label,
      "readonly": false,
      "options": _vm.controls.entity.options,
      "allow-empty": false,
      "mode": _vm.controls.entity.mode,
      "required": true
    }
  })], 1)], 1), _c('vue-dropzone', {
    ref: "dropzone",
    attrs: {
      "id": "dropzone",
      "options": _vm.dropzoneOptions
    },
    on: {
      "vdropzone-sending": _vm.vdropzoneSending,
      "vdropzone-complete": _vm.vdropzoneComplete,
      "vdropzone-file-added": _vm.vdropzoneFileAdded
    }
  }), _c('hr'), _c('b-input-group', [_c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "paste"
    },
    on: {
      "click": _vm.pasteUrl
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    },
    on: {
      "click": _vm.onSaveClick
    }
  })], 1)], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "or insert link to cloud storage"
    },
    model: {
      value: _vm.controls.urlInput,
      callback: function callback($$v) {
        _vm.$set(_vm.controls, "urlInput", $$v);
      },
      expression: "controls.urlInput"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.moduleId && !_vm.readonly ? _c('div', [_vm.showCategory ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "4"
    }
  }, [_c('inline-select', {
    ref: "category",
    attrs: {
      "id": "".concat(_vm.uid, "-").concat(_vm.controls.category.id),
      "loading": _vm.controls.category.isLoading,
      "value": _vm.controls.category.value,
      "label": _vm.controls.category.label,
      "readonly": false,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.controls.category.mode,
      "required": false
    },
    on: {
      "changed": _vm.onCategoryChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [_c('inline-date-picker', {
    ref: "expirationDate",
    attrs: {
      "id": _vm.controls.expiration_date.id,
      "value-single": _vm.controls.expiration_date.value,
      "label": _vm.controls.expiration_date.label,
      "readonly": _vm.controls.expiration_date.readonly,
      "mode": _vm.controls.entity.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    staticClass: "form-row d-flex align-items-center",
    attrs: {
      "lg": "2"
    }
  }, [_c('b-button', {
    staticClass: "mt-10",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "undo"
    }
  }), _vm._v("  Reset ")], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-overlay', {
    attrs: {
      "show": !_vm.controls.category.value.id && _vm.showCategory || !_vm.controls.expiration_date.value && _vm.showExpirationDate,
      "opacity": 0.8,
      "spinner-variant": "secondary",
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [!_vm.controls.category.value.id && _vm.showCategory ? _c('div', [_vm._v(" Please select category before uploading files ")]) : _vm._e(), !_vm.controls.expiration_date.value && _vm.showExpirationDate ? _c('div', [_vm._v(" Please select expiration date before uploading files ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 4285207024)
  }, [_vm.moduleId ? _c('vue-dropzone', {
    ref: "dropzone",
    staticClass: "nested-dropzone",
    attrs: {
      "id": "dropzone",
      "options": _vm.dropzoneOptions
    },
    on: {
      "vdropzone-sending": _vm.vdropzoneSending,
      "vdropzone-complete": _vm.vdropzoneComplete,
      "vdropzone-file-added": _vm.vdropzoneFileAdded
    }
  }) : _vm._e(), _c('hr'), _vm.showCategory ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-input-group', [_c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "paste"
    },
    on: {
      "click": _vm.pasteUrl
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    },
    on: {
      "click": _vm.saveUrl
    }
  })], 1)], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "or insert link to cloud storage"
    },
    model: {
      value: _vm.controls.urlInput,
      callback: function callback($$v) {
        _vm.$set(_vm.controls, "urlInput", $$v);
      },
      expression: "controls.urlInput"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _c('hr'), _c('span', [_c('h5', [_vm._v(" Files "), _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": this.isLoading
    }
  })], 1)]), _vm.dataTable.dataSet.length > 0 ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_files_container",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "update": _vm.onInlineTableUpdate
    },
    scopedSlots: _vm._u([{
      key: "Entity",
      fn: function fn(props) {
        return _c('div', {}, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onRowEntityClick(props.row);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row['Entity']) + " ")])], 1);
      }
    }, {
      key: "File Name",
      fn: function fn(_ref) {
        var row = _ref.row,
          update = _ref.update,
          setEditing = _ref.setEditing,
          isEditing = _ref.isEditing,
          revertValue = _ref.revertValue;
        return _c('div', {}, [!isEditing() || _vm.readonly ? _c('span', {
          on: {
            "click": function click($event) {
              return setEditing(true);
            }
          }
        }, [_vm._v(_vm._s(row['File Name']))]) : _vm._e(), !isEditing() && !row['File Name'] ? _c('span', {
          staticClass: "pl-5",
          on: {
            "click": function click($event) {
              return setEditing(true);
            }
          }
        }, [_c('b-img')], 1) : _vm._e(), isEditing() && !_vm.readonly ? _c('span', {
          staticClass: "d-flex flex-row align-items-center"
        }, [_c('b-input-group', {
          scopedSlots: _vm._u([row['fname_prefix'] ? {
            key: "prepend",
            fn: function fn() {
              return [_c('b-input-group-text', [_vm._v(_vm._s(row['fname_prefix']))])];
            },
            proxy: true
          } : null, row['fname_ext'] ? {
            key: "append",
            fn: function fn() {
              return [_c('b-input-group-text', [_vm._v(_vm._s(row['fname_ext']))])];
            },
            proxy: true
          } : null], null, true)
        }, [isEditing() ? _c('b-form-input', {
          on: {
            "input": function input($event) {
              return _vm.onColumnUpdate(row['ID'], 'File Name', row['fname_body']);
            }
          },
          model: {
            value: row['fname_body'],
            callback: function callback($$v) {
              _vm.$set(row, 'fname_body', $$v);
            },
            expression: "row['fname_body']"
          }
        }) : _vm._e()], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              if (_vm.validateCell('File Name', row['File Name'])) {
                update(row['fname_body']);
                setEditing(false);
              }
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1), _c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              revertValue();
              setEditing(false);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)], 1) : _vm._e()]);
      }
    }, {
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [props.row['is_url'] == 0 ? _c('b-button', {
          attrs: {
            "variant": "success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.downloadItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1) : _vm._e(), props.row['is_url'] == 1 ? _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.openUrl(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "external-link-alt"
          }
        })], 1) : _vm._e(), !_vm.readonly ? _c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 1)]);
      }
    }], null, false, 2160209460)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }