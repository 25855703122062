<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-vacation-requests"
              :hide-custom-filters="true"
              :hide-search-button="false"
              :show-labels="false"
              :compact="false"
              :loaded="filteringPanel.loaded"
              :filters="filteringPanel.filters"
              @change="onFilteringPanelChange"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
              @loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <table-custom
              ref="requests"
              :name="`custom_table_vacation_requests`"
              :loading="isLoading"
              :data="dataTable.dataSet"
              :options="dataTable.options"
            >
              <div slot="custom-actions" slot-scope="props">
                <div class="btn-group">
                  <button
                    class="btn btn-success btn-sm"
                    @click="confirmItem(props.row.ID)"
                    v-if="props.row.Status === 'On Review' && !isLoading"
                  >
                    <b-spinner v-if="props.row.isLoadingApprove" small type="grow" />
                    <font-awesome-icon icon="check" v-if="!props.row.isLoadingApprove" />
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="declineItem(props.row.ID)"
                    v-if="(props.row.Status === 'On Review' || showDeclineAction(props.row)) && !isLoading"
                  >
                    <b-spinner v-if="props.row.isLoadingDecline" small type="grow" />
                    <font-awesome-icon icon="ban" v-if="!props.row.isLoadingDecline" />
                  </button>
                </div>
              </div>
            </table-custom>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from '@/components/FilteringPanel'
import TableCustom from '@/components/TableCustom'

import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'Products',
  components: {
    FilteringPanel,
    TableCustom
  },
  data: function () {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: 'select',
            dataType: 'string',
            title: 'User Name',
            name: 'user_name',
            trackby: 'id',
            label: 'name',
            options: [],
            selected: {}
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Leave type',
            name: 'leave_type',
            trackby: 'id',
            label: 'name',
            options: [],

            selected: {}
          }
        ]
      },

      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            'ID',
            'User Name',
            'Full time',
            'Leave type',
            'Year',
            'Rollover (h)',
            `Hours worked`,
            `Accrued (h)`,
            `Used (h)`,
            `Not used (h)`,
            `Available (h)`,
            `Salary start`,
            `Days employed`,
            `Status`,
            'Updated'
          ],
          filterable: [
            'ID',
            'User Name',
            'Full time',
            'Leave type',
            'Year',
            'Rollover (h)',
            `Hours worked`,
            `Accrued (h)`,
            `Used (h)`,
            `Not used (h)`,
            `Available (h)`,
            `Salary start`,
            `Days employed`,
            `Status`,
            'Updated'
          ],
          formatColumns: [
            {
              name: 'Available (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Rollover (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Accrued (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Not used (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Used (h)',
              style: {
                style: 'decimal'
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {},
  methods: {
    onFilteringPanelChange (e) {
      this.filteringPanel.selected = e

      this.filterData(e)
    },
    onFilteringPanelSearch () {
      this.onFilteringPanelLoad()
    },
    onFilteringPanelReset () {},
    onFilteringPanelLoad () {
      this.getData()
    },
    viewItem () {},

    filterData: function (e) {
      this.filteringPanel.selected = e

      if (!this.rawData.length) return

      let filteredData = this.rawData

      if (this.filteringPanel.selected != undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.user_name ? i['User Name'] === this.filteringPanel.selected.user_name.name : true
        )
      }

      if (this.filteringPanel.selected != undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.leave_type
            ? i['Leave type'] === this.filteringPanel.selected.leave_type.name
            : true
        )
      }

      this.dataTable.dataSet = filteredData
    },
    async getData (e) {
      if (e) this.filteringPanel.selected = e

      this.isLoading = true

      let url = 'timetracker/leave-balances'

      let response = await this.$api.get(url)

      console.log(response)

      this.rawData = response

      this.isLoading = false

      this.dataTable.dataSet = this.rawData

      this.filteringPanel.filters.find(f => f.name === 'user_name').options = this.$helpers.getDistinctArray(
        this.rawData,
        'User Name',
        'User Name'
      )

      this.filteringPanel.filters.find(f => f.name === 'leave_type').options = this.$helpers.getDistinctArray(
        this.rawData,
        'Leave type',
        'Leave type'
      )
    }
  },
  watch: {}
}
</script>

<style></style>
