var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('FilteringPanelWrapper', {
    attrs: {
      "model": _vm.model,
      "filters": _vm.filters
    },
    on: {
      "get-data": _vm.onFilteringPanelGetData
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('TableWrapper2', {
    ref: _vm.model.name,
    attrs: {
      "model": _vm.model,
      "parent-id": _vm.id,
      "actions-mode": "inline",
      "columns": _vm.tableColumns,
      "read-only": false,
      "filter-by-column": false
    },
    on: {
      "inserted": _vm.onTableInsert,
      "updated": _vm.onTableChange
    }
  })], 1)], 1), _c('b-row', {
    staticStyle: {
      "margin-bottom": "10em"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }