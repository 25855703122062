<template>
  <b-modal ref="modal" centered scrollable size="lg" title="Receive item">
    <b-container fluid class="ma-0 pa-0" style="height: 60vh">
      <b-row v-show="!data.order_id">
        <b-col lg="12" md="12" sm="12">
          <inline-select
            :id="controls.order.id"
            :value="data.order"
            :loading="controls.order.loading"
            :label="controls.order.label"
            :readonly="controls.order.readonly"
            :options="controls.order.options"
            :allow-empty="false"
            :multiple="false"
            :mode="mode"
            @changed="updateSelect"
            :required="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.order_date.id"
            :value="data.order_date"
            :label="controls.order_date.label"
            :readonly="true"
            :required="false"
          />
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.ordered_by.id"
            :value="data.ordered_by"
            :label="controls.ordered_by.label"
            :readonly="true"
            :required="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.total_price.id"
            :value="data.total_price"
            :label="controls.total_price.label"
            :readonly="true"
            :required="false"
          />
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.total_amount.id"
            :value="data.total_amount"
            :label="controls.total_amount.label"
            :mode="mode"
            :readonly="true"
            :required="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.delivery_status.id"
            :value="data.delivery_status"
            :label="controls.delivery_status.label"
            :readonly="true"
            :required="false"
          />
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.est_arrival_date.id"
            :value="data.est_arrival_date"
            :label="controls.est_arrival_date.label"
            :readonly="true"
            :required="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12" sm="12">
          <inline-date-picker
            :id="controls.date_received.id"
            :value-single="data.date_received"
            :label="controls.date_received.label"
            :readonly="controls.date_received.readonly"
            :mode="mode"
            @changed="updateDateField"
          />
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.lot_number.id"
            :value="data.lot_number"
            :label="controls.lot_number.label"
            :mode="mode"
            :readonly="false"
            :required="true"
            @changed="inputChanged"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12" sm="12">
          <inline-select
            :id="controls.storage_location.id"
            :value="data.storage_location"
            :loading="controls.storage_location.loading"
            :label="controls.storage_location.label"
            :readonly="controls.storage_location.readonly"
            :options="controls.storage_location.options"
            :allow-empty="false"
            :multiple="false"
            :mode="mode"
            @changed="updateSelect"
            :required="true"
          />
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <inline-input
            :id="controls.amount_received.id"
            :value="data.amount_received"
            :label="controls.amount_received.label"
            :mode="mode"
            :readonly="false"
            :required="true"
            @changed="inputChanged"
          />
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer="">
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          :disabled="data.item_order_id === ''"
          @click="save()"
        >
          <font-awesome-icon icon="save" /> Save
        </b-button>

        <b-button variant="outline-dark" class="m-1" @click="close()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment'
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineDatePicker from '@/components/InlineDatePicker'
import formHelpers from '../../shared/form-helpers'
export default {
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker
  },
  data () {
    return {
      mode: this.$constants.FORM_MODE.EDIT,
      controls: {
        order: {
          id: 'select:order',
          label: 'Order',
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        order_date: {
          id: 'input:order_date',
          label: 'Order Date',
          changed: false,
          readonly: true
        },
        ordered_by: {
          id: 'input:ordered_by',
          label: 'Ordered By',
          changed: false,
          readonly: true
        },
        item_name: {
          id: 'input:item_name',
          label: 'Item Name',
          changed: false,
          readonly: false
        },
        total_price: {
          id: 'input:total_price',
          label: 'Total Price',
          changed: false,
          readonly: false
        },
        total_amount: {
          id: 'input:total_amount',
          label: 'Total Amount',
          changed: false,
          readonly: false
        },
        delivery_status: {
          id: 'input:delivery_status',
          label: 'Delivery Status',
          changed: false,
          readonly: false
        },
        est_arrival_date: {
          id: 'input:est_arrival_date',
          label: 'Est Arrival Date',
          changed: false,
          readonly: false
        },
        date_received: {
          id: 'input:date_received',
          label: 'Date Received',
          changed: false,
          readonly: false
        },
        lot_number: {
          id: 'input:lot_number',
          label: 'Lot Number',
          changed: false,
          readonly: false
        },
        amount_received: {
          id: 'input:amount_received',
          label: 'Amount Received',
          changed: false,
          readonly: false
        },
        storage_location: {
          id: 'select:storage_location',
          label: 'Storage Location',
          options: [],
          changed: false,
          readonly: false,
          loading: false
        }
      },
      data: {
        id: '',
        order: {},
        item_order_id: '',
        order_id: '',
        order_date: '',
        ordered_by: '',
        total_price: '',
        total_amount: '',
        delivery_status: '',
        est_arrival_date: '',
        date_received: '',
        amount_received: '',
        storage_location: {},
        lot_number: '',
        amount_adjusted_loss: 0,
        status: ''
      },
      activeOrders: []
    }
  },
  methods: {
    show (payload) {
      this.data = {
        order: {},
        item_order_id: payload.item_order_id ? payload.item_order_id : '',
        //181224 item_order_id: '',
        item_id: payload.item_id,
        order_id: payload.order_id,
        order_date: '',
        ordered_by: '',
        total_price: '',
        total_amount: '',
        delivery_status: '',
        est_arrival_date: '',
        date_received: moment.utc().format('YYYY-MM-DD'),
        amount_received: '',
        storage_location: {},
        lot_number: '',
        amount_adjusted_loss: 0,
        status: 'Full'
      }

      this.loadDictionaries()

      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    save () {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return false
      }
      console.log('save', this.data)
      this.$emit('save', this.data)
    },
    close () {
      this.$emit('close')
    },
    loadDictionaries () {
      let self = this

      const storages = () => {
        self.$api
          .get(`dictionaries/inventory/storage-location`)
          .then(response => {
            self.controls.storage_location.options = response.map(u => ({
              id: u.id,
              label: u.name
            }))
          })
      }
      const orders = () => {
        if (!this.data.item_id) return

        let self = this

        self.$api
          .get(`inventory/item/${this.data.item_id}/orders/active`)
          .then(response => {
            self.activeOrders = response

            console.log('activeOrders', response)

            self.controls.order.options = response.map(u => ({
              id: u['Order Number'],
              label:
                u['Item Name'] +
                ' [Order #' +
                u['Order Number'] +
                ' on ' +
                u['Order Date'] +
                ', Price ' +
                u['Total Price'] +
                ', Amount ' +
                u['Total Amount'] +
                ']'
            }))

            if (self.data.order_id) {
              self.updateSelect('order', { id: self.data.order_id })
            }
          })
      }

      Promise.all([storages(), orders()])
    },
    inputChanged (field, value) {
      this.data[field] = value
    },
    updateSelect (field, value) {
      console.log('updateSelect', field, value)
      if (field === 'order') {
        let order = this.activeOrders.find(
          i =>
            i['Order Number'] === value.id &&
            //181224 add condition if item_order_id passed in case when several items in one order to  make sure received amount will be added to the correct item
            (this.data.item_order_id
              ? i['Item Order ID'] === this.data.item_order_id
              : true)
        )
        this.data.item_order_id = order['Item Order ID']
        this.data.order_id = order['Order Number']
        this.data.order_date = order['Order Date']
        this.data.ordered_by = order['Ordered By']
        this.data.total_price = order['Total Price']
        this.data.total_amount = order['Total Amount']
        this.data.delivery_status = order['Delivery Status']
        this.data.est_arrival_date = order['Est Arrival Date']
      } else {
        this.data[field] = value
      }
    },
    updateDateField () {}
  }
}
</script>

<style></style>
