var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-container"
  }, [_c('div', {
    staticClass: "controls-wrapper"
  }, [_vm.data.length ? _c('div', {
    staticClass: "action-buttons"
  }, [_c('button', {
    staticClass: "btn btn-outline-dark save-btn",
    attrs: {
      "disabled": !_vm.modifiedRows.size
    },
    on: {
      "click": _vm.saveChanges
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_vm._v("💾")]), _vm._v(" Save ")]), _c('button', {
    staticClass: "btn btn-outline-dark cancel-btn",
    attrs: {
      "disabled": !_vm.modifiedRows.size
    },
    on: {
      "click": _vm.cancelEdits
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_vm._v("✖")]), _vm._v(" Cancel ")])]) : _vm._e()]), _c('table', {
    staticClass: "table table-sm table-hover table-bordered table-striped"
  }, [_c('thead', {
    staticClass: "thead-light"
  }, [_c('tr', _vm._l(_vm.headers, function (header) {
    var _vm$config$columns$he;
    return _c('th', {
      key: header,
      staticClass: "text-nowrap",
      style: {
        width: _vm.columnWidths[header] || 'auto',
        backgroundColor: _vm.selectedHeader === header ? 'rgba(25, 118, 210, 0.04)' : ''
      },
      attrs: {
        "scope": "col",
        "data-readonly": (_vm$config$columns$he = _vm.config.columns[header]) === null || _vm$config$columns$he === void 0 ? void 0 : _vm$config$columns$he.readOnly
      }
    }, [_c('div', {
      staticClass: "header-content",
      on: {
        "click": function click($event) {
          return _vm.selectHeader(header);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getColumnTitle(header)) + " ")]), _c('div', {
      directives: [{
        name: "drag-resize",
        rawName: "v-drag-resize",
        value: header,
        expression: "header"
      }],
      staticClass: "resize-handle"
    })]);
  }), 0), _c('tr', _vm._l(_vm.headers, function (header) {
    return _c('th', {
      key: "filter-".concat(header),
      staticClass: "th-filter-input"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.filters[header],
        expression: "filters[header]"
      }],
      staticClass: "filter-input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.filters[header]
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.filters, header, $event.target.value);
        }, _vm.handleFilter]
      }
    })]);
  }), 0)]), _c('tbody', _vm._l(_vm.sortedData, function (row, rowIndex) {
    return _c('tr', {
      key: rowIndex
    }, _vm._l(_vm.headers, function (header) {
      var _vm$config$columns$he2;
      return _c('td', {
        key: header,
        class: {
          'edit-cell': _vm.isEditing(rowIndex, header),
          'edited-cell': _vm.isDirtyCell(rowIndex, header)
        },
        attrs: {
          "data-readonly": (_vm$config$columns$he2 = _vm.config.columns[header]) === null || _vm$config$columns$he2 === void 0 ? void 0 : _vm$config$columns$he2.readOnly
        },
        on: {
          "click": function click($event) {
            return _vm.startEdit(row, header, rowIndex);
          }
        }
      }, [_vm.isEditing(rowIndex, header) ? _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.editValue,
          expression: "editValue"
        }, {
          name: "focus",
          rawName: "v-focus"
        }],
        staticClass: "edit-input",
        domProps: {
          "value": _vm.editValue
        },
        on: {
          "blur": _vm.finishEdit,
          "keyup": [function ($event) {
            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
            return _vm.finishEdit.apply(null, arguments);
          }, function ($event) {
            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
            return _vm.cancelEdits.apply(null, arguments);
          }],
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.editValue = $event.target.value;
          }
        }
      }) : _c('span', {
        class: {
          'edited-value': _vm.isDirtyCell(rowIndex, header)
        }
      }, [_vm._v(" " + _vm._s(row[header]) + " ")])]);
    }), 0);
  }), 0)]), !_vm.data.length ? _c('p', {
    staticClass: "text-center text-muted my-3"
  }, [_vm._v(" No data available ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }